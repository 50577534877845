import {React} from 'react'; 
import Header from "../../../components/Header";
// import SubCategoryComponent from "./SubCategoryComponent";
import { Link } from "react-router-dom";
import Footer from '../../../DashboardCustomer/Components/Vendorcomponent/Footer';
import '../../../assets/css/style.css'

const MobilePhones = () => {
  return (
    // <SubCategoryComponent productHeader={"Mobile Phones"} categoryId={3} />
    <div>
      <Header />
    {/* <SubCategoryComponent productHeader={"Mobile Phones"} categoryId={3} /> */}

      <div className="w-100 bg-sky-100 pb-8">
        <h2 className="c_title text-gray-600 body-font py-4 mx-4">Mobile Phones</h2>
       <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
            Original used iPhone 11 unlocked iphone second hand mobile phone for iOS Apple iPhone11
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
            $199.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/11-Wholesale-Used-phone11-Smart-Phone_1601085007669.html?spm=a2700.galleryofferlist.normal_offer.d_title.2c8a6574dzwu5V'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          iPhone 11 | "128 GB ROM, 15.49 cm (6.1 inch) Liquid Retina HD Display, 12MP + 12MP | 12MP Front Camera A13 Bionic Chip Processor"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $595.80 
          </p>
          <Link to={'https://www.flipkart.com/apple-iphone-11-white-64-gb-includes-earpods-power-adapter/p/itm2644c3764fc54?pid=MOBFKCTSHAWGGFHM&lid=LSTMOBFKCTSHAWGGFHMNDVENT&marketplace=FLIPKART&q=iphone+11&store=tyy%2F4io&srno=s_1_1&otracker=search&otracker1=search&fm=organic&iid=794d0a9f-0ace-4914-ba81-110075ef11c0.MOBFKCTSHAWGGFHM.SEARCH&ppt=hp&ppn=homepage&ssid=haxq2csxj40000001715342661265&qH=f6cdfdaa9f3c23f3'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 15 128GB Factory Unlocked AT&T T-Mobile Verizon Excellent Condition
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $699.95
          </p>
          <Link to={'https://www.ebay.com/itm/296110312917?epid=20065001328&itmmeta=01HXMTMVG6DKDXBV45VZ4VN256&hash=item44f18ccdd5:g:I1sAAOSw56NlfGAi&itmprp=enc%3AAQAJAAAA4Dgi6U4efiXb73XjvpFOX%2FCgdq8Qag9nHP0QrgJq9xxfjwonsI%2B4lGgdSEsxj18mNIfuO7unkSuJRkzzm5uUynXfqulU%2FDWOqIhDc8pBoqMVpMYLidIQWYzTVg%2B0ztjaXuRRGgWzhTydWrneL3L%2FHsYqkldQGE4vHDJpnrmEAUApNVBWUW4HsR%2BOoAJ06w522ERhz28BSsCLcfaO20gpL5PtDPpUfMVtn7Gadumb3BmoWTTf86ZWs0iToF4GiEKgYmjgxwnecUcoUUmM85Mg9BFEPNyTLGv0vKoRTnT6XxMo%7Ctkp%3ABk9SR6C405rtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy Z Flip 5G Factory Unlocked New Android Cell Phone | US Version Smartphone | 256GB Storage | Folding Glass Technology| Long-Lasting Mobile Battery | Mystic Gray (SM-F707UZAAXAA)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $799  
          </p>
          <Link to={'https://www.amazon.com/Samsung-Galaxy-Factory-Unlocked-Version/dp/B08DP4DWKB?th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Wholesale Original Iphone 11 12 13 pro max Unlock Used Smart Phone
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $216.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Wholesale-Original-Iphone-11-12-13_1601009109774.html?spm=a2700.galleryofferlist.normal_offer.d_title.2c8a6574dzwu5V'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 11 Pro 64G- Unlocked-Good condition 5.8-inch screen-A13 processor
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $136.69 
          </p>
          <Link to={'https://www.ebay.com/itm/315355865890?itmmeta=01HXMS7TF49AVDCZHBY83TH7ZK&hash=item496caca322:g:eMcAAOSwbLFmPdQe&itmprp=enc%3AAQAJAAAA4PQOSflm9MPjf5MBQ3mWWXJEjRSEU%2FaVH0jJxXpHDoTHN357oK%2F5SyqMFYIYmq4bNpIUtNtv5xi97dIPUQ7jixJyH%2BwI8yRNgBurdnmE%2FUMmLy%2F4uJVtnAN3UdvK1Pu4nsfw55P2sTfgal3DS6cizvaf7VTgMZZbkgzauOwqhQcA82XLwJA4e03zfKtSYFPiiETdizFFZtEoQ1TDpqYTxzCC4XXcjQ3UbBjDh58kHtdL3DUXDK5xnVtWsAYomFGg6i78NlaU6k%2FNuBH3LavYdbP5mZI9AdeRUyaLGG%2B6eZA3%7Ctkp%3ABFBM7qefme1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Pre-Owned for iPhone 12 256GB 5G 6G RAM 12MP Refurbished for iPhone 11 Pro Max 512GB for iPhone XS 256GB Compatible GSM LTE
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $150.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Pre-Owned-for-iPhone-12-256GB_1601075048123.html?spm=a2700.galleryofferlist.normal_offer.d_title.36605a0eA8Whna'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 12 128G-Unlocked GSM/CDMA-6.1-inch screen-Good condition
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $149.99
          </p>
          <Link to={'https://www.ebay.com/itm/315355900093?itmmeta=01HXMSH2TY79F1NX81FT6YM1HP&hash=item496cad28bd:g:MqsAAOSwKvVmPdlO&itmprp=enc%3AAQAJAAAA4IPqHpjSp4v0mNDfHhHimZb1pqBBGNDDNy%2B6ASP7Q1u%2BSdVNVZPQ%2BMluaPm%2FGROON2UkTVYhss40hukgOBjQPANYCLEXMbwyjsPqnp1utLMcJ3Rg9VjrLHR24PMhCJRV%2BBhfDe8TeyPAj4oIstLejd%2BsMWGuATw6hTIhhF%2B9McQH%2FSlwDdplhrBoTm0IdzdTCx%2FHecnxcuNlqBhOoA0XVmcJM2TBxNGNMR%2BepGHDWLckEus0sXe8hEtAAKO4b5p4Dw4ORTrclBRtesF5P9VYhiL56Tq38jKIy8pH5RrcnMMf%7Ctkp%3ABFBM6K3Eme1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 15, 256GB, Pink - Unlocked (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $838.99 
           </p>
          <Link to={'https://www.amazon.com/Apple-iPhone-15-256GB-Pink/dp/B0CMPM1N33/ref=sr_1_7?dib=eyJ2IjoiMSJ9.wJ8Mt4Zq_EfSfUeiMUN4yp1o8eBPxvsjPDLunxcmJxru-4VOo7C7Wu6Ng3sua7wtRJ0a3EA2p-rx3LBMqXRlVmg0D_2k7PDLj9hdf4hlaRG-Az_pfJZg9xdg2Zj3HPgi1p5H23eCJ_awUKlAtzcLs0BvzS9TPPqyMfpMnqkkNs-11GchOmDMsy6G28jOgPcOWIrsIm1UqGloWQPFNRMXcZ8IEo7vrs2EFHkGTNJWQ4U6rd6zyOwDRzg4W9YNyDDqe9Kf8VlGYB5mu1MzHB7ZKIC-I0cBc376Hen7yGtApTA.xou4nhgvkAYQFnSEntoI4dLFDjneleGejF8zbdj_BQs&dib_tag=se&keywords=iphone+15&qid=1715372262&s=electronics&sr=1-7'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 13, 128GB, Pink - Unlocked (Renewed Premium)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $474.75 </p>
          <Link to={'https://www.amazon.com/iPhone-13-128GB-Pink-Unlocked/dp/B0BGQS8YV4/ref=sr_1_3?crid=3I2KFOMDQ2CXK&dib=eyJ2IjoiMSJ9.zJa_f2OBaWSo5Wx62dD8gK7Gynvkmk9SnKa2Ns69sx3jyXcDtyqJNmgQg5VbbP-FVEfMN96MuTHGQoYOF1NTy4mxSFHs49nq23n7L__FWO4Z9KYMvNf_VxSwSLqpabb42Af4adjr-PVBeLZVG4aTiQC8zWMlS0gJlC18RA_y8OTrCe5HvsUkWmhH4r0vYY0Jwg7MMc4hZddnHsM4JS0jZB8ZOVV_MWzaakIBj6Skgso.ebdr4SM9_M3OMYS4_2Pss4nLklKH2jXkbQ3e38JOT6Y&dib_tag=se&keywords=iphone+13&qid=1715371910&sprefix=iphone+1%2Caps%2C336&sr=8-3'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Used iphones for IPhone X Xr Xs Xsmax 11 12 Pro max 13pro cheap phones unlocked Iphone second hand
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $202.00

          </p>
          <Link to={'https://www.alibaba.com/product-detail/Used-iphones-for-IPhone-X-Xr_1601020287707.html?spm=a2700.galleryofferlist.normal_offer.d_title.36605a0eA8Whna'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 13 Pro 128GB Unlocked AT&T T-Mobile Verizon Very Good Condition
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $474.95
          </p>
          <Link to={'https://www.ebay.com/itm/295217227352?epid=26049290840&itmmeta=01HXMSM2XJFK707WRAHW669GHE&hash=item44bc516658:g:33UAAOSwfAFlDb7l&itmprp=enc%3AAQAJAAAAwE%2B1mKn9ru0iOGRpHNEaQA%2F5wfcvjSueDe%2BTaHMKMPu4cUbgRdIMNkgZuWv8B62PWKeqYGcwrFjwSM8p3yg3O7U9vbbzMIrVUncr38uoo3awp9b7NLWt2J3VnAF8HoAjKZ63XGBSjz2iJ1CvwP8rl6ntj%2Bu39urlQH5e0aIWPuYu5A1K%2FNB5cm3VqvxmQFACEt78ahAq8O64qR6eyzKmNFsz5eIh2hUO4M%2FrtcEOBLdONbawCXx%2FoEs8xzakfAdTtQ%3D%3D%7Ctkp%3ABk9SR5Cv0JntYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 12, 256GB, Green - Verizon (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $342.03 
          </p>
          <Link to={'https://www.amazon.com/Apple-iPhone-12-256GB-Green/dp/B08PP865QM/ref=sr_1_12?crid=1CU45NVFJK8VM&dib=eyJ2IjoiMSJ9.fui9Oy4IKHEy2mAg1xjeh44x6myHmm7bdG_wVAnPOkyaRaw6HtghVO6n443alTtIx6ojk8v45ASiM1pD-GEOXrDmzzzBk8SX7OmvrPu48ZTMNquPUPQlFycnG8jEPSJ9CHFoaoTp7Z0DuAqrOZxmyq-5qli50j856X3zH-YAN1ENgbOaWAKepCOWpYCyIuZCe7YXnnQxmDeItYyV58ws5LRjb6XOFvrImyOk6iCaTrA.GHBz9kR6fHHfYsyhLRjcswX6z_rD1kbb_4mphZ70ZrU&dib_tag=se&keywords=iphone+12&qid=1715371631&sprefix=iphone+1%2Caps%2C464&sr=8-12'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 13 mini - 256GB - Blue (Unlocked)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $415.52
          </p>
          <Link to={'https://www.ebay.com/itm/395390881651?epid=19049287661&itmmeta=01HXMSM2XHGAM8R6HR782SZHY1&hash=item5c0f220f73:g:XXcAAOSwOvlmOvZa&itmprp=enc%3AAQAJAAAA4Kr%2FfH9OzB6PUEEQl925GGRKBblXjDuG2rQuUd%2BbwfA0lvAP%2F9LFD49Ky%2BaAqvpa9SA1W3t6YA88IzCOlvhLG8nk8pQYnn07E%2Fo2V0vwlet90e9TzvfWkMZjhKBrGYD5%2B2x6huffj%2FjRa75RCAgC6LkZ88l6xZtLLzMHEzGJqOLq7rqNfcTNz2tLgZhdSqxVqjFKyWcfKvQMOPTaXMaecYjz3X3cJmXqLtuSQGRYK6mdp169UCNiIyqkWDbWDs6jqj7DGogrTwNFuqRdThtvRN1W8vhb4lT33vwrH9VNsv%2BL%7Ctkp%3ABFBMjq_Qme1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone XR, US Version, 128GB, Yellow - Unlocked (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $190.56 
          </p>
          <Link to={'https://www.amazon.com/Apple-iPhone-Fully-Unlocked-128/dp/B07P83C2RD/ref=sr_1_9?crid=2SJ8RR2WXMRAM&dib=eyJ2IjoiMSJ9.V_XdEPc7Xe2XzCATYNV1efiqEt-JLhNWfCJEjSGFvxRwmzwDItMhDPrEhq06cZy3HrAYEhDA7zJAu-ciOtyG92MCAgilctstyFf6XUB3pkNOzgPACRrugZLYpmOxkhdmN_jFHZFZ31KNu8m2bxjzGjtJWB6wD0obF00IhcQJ5Y4d0PavwR2bqc71KDSsarO1WaF3qB-XG7C6aowg8rz6qOvjjC1EamNgHSfaOrAm87k.diUJHYHF_P_WbS6fnOknAuSFcflgCzrOq7tVQ4y5uYg&dib_tag=se&keywords=iphone%2B11&qid=1715370294&sprefix=iphone%2B%2Caps%2C371&sr=8-9&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Phone 15 6.1"-512GB-Apple A16-iOS 17.x (latest) smartphone Global Edition dual SIM cheap price 5G</h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,148.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Phone-15-6-1-512GB-Apple_1601004615600.html?spm=a2700.galleryofferlist.normal_offer.d_title.7b3182f9sTNnL8'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NEW SAMSUNG GALAXY S21 5G SM-G991U1 UNLOCKED 128GB 256GB ALL COLORS
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $243.19
          </p>
          <Link to={'https://www.ebay.com/itm/186427184157?epid=28043687656&itmmeta=01HXMVFHVY6R89X659VACFSPF1&hash=item2b67ed141d:g:8nMAAOSwDYFmNPUC&itmprp=enc%3AAQAJAAAA4K47vD2GCT9uNOoo0G07f0tL8s%2FZbc9lkUWAPWhXhrFpk9DFCHvmoQ77%2F9FkGOKdeH8ANnGsPHPS%2FNCgn%2B%2BOwTFTDSbDt0x2%2FcFP1wjmzZ4G151mjsdn0SUXkBhJNSBSZeXgoXGQIG8qsITqP0yWwgDgabqb5ziGRs0dJoTfJ%2FwSQlWkVrbntFcVdj1V0nYiCU1%2Ba%2BTKDzg%2F5VsFmROePU1lOErNNcGB35F5h3%2Fp7OgV2X2iijQaulMIILExFFZZkyKjorMdxEi66at8PdpEUkVNqu20Avd%2FRQiwR%2BPbPyuA%7Ctkp%3ABFBMqJ6-m-1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 11, 64GB, Black - Unlocked (Renewed Premium)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $295.00  
          </p>
          <Link to={'https://www.amazon.com/Apple-iPhone-11-64GB-Black/dp/B08BHTDLJR/ref=sr_1_8?crid=2SJ8RR2WXMRAM&dib=eyJ2IjoiMSJ9.V_XdEPc7Xe2XzCATYNV1efiqEt-JLhNWfCJEjSGFvxRwmzwDItMhDPrEhq06cZy3HrAYEhDA7zJAu-ciOtyG92MCAgilctstyFf6XUB3pkNOzgPACRrugZLYpmOxkhdmN_jFHZFZ31KNu8m2bxjzGjtJWB6wD0obF00IhcQJ5Y4d0PavwR2bqc71KDSsarO1WaF3qB-XG7C6aowg8rz6qOvjjC1EamNgHSfaOrAm87k.diUJHYHF_P_WbS6fnOknAuSFcflgCzrOq7tVQ4y5uYg&dib_tag=se&keywords=iphone%2B11&qid=1715370294&sprefix=iphone%2B%2Caps%2C371&sr=8-8&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Samsung Galaxy S22 Ultra SM-S908B/DS - 256GB - Green (Unlocked)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $312.69
          </p>
          <Link to={'https://www.ebay.com/itm/305560377780?epid=27052277350&itmmeta=01HXMVS5XZX52E0YH1MJZB92P2&hash=item4724d159b4:g:FxsAAOSwUsNmPz7e&itmprp=enc%3AAQAJAAAA4AtE1zQJ3AgUaybNzpBraJfFdO8T2mApkLXps%2Bb8TvYyMiqXRHl1kE52z126bOw0%2BjvObLtogEwxIj%2Bl7D1r%2BhpgDKhGCgAGUNQzL20xJtitIuaGtDyfpq5BBIm357G1q%2BQUUVKcG3pOQXScmR0p0yfCWURkYftVGUVoII%2BxEEbXzW%2F7CIaMmt3D8TaHT37Kqe%2BXpRLML0COVfup1LzQo8SCidfrgqPsX%2BgFqM--LA6QQnhXKUkCOnPzPdTwC%2FUaWOdexjz2TIYUpTD7N%2B3P4pFHc2%2FAYl4SxkdG3H1AWzdS%7Ctkp%3ABFBMpt_km-1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy S23 5G Factory Unlocked 256GB - Phantom Black (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $548.95 
          </p>
          <Link to={'https://www.amazon.com/SAMSUNG-Galaxy-S23-Factory-Unlocked/dp/B0C79RKMYB/ref=dp_fod_sccl_3/134-8250736-6423343?pd_rd_w=QVb4O&content-id=amzn1.sym.550e945f-c48e-4794-aff0-cc9017996f0a&pf_rd_p=550e945f-c48e-4794-aff0-cc9017996f0a&pf_rd_r=YPS8TJVBY6W89R6ETWRK&pd_rd_wg=bnfsn&pd_rd_r=08406ce9-eb0b-444d-b7e1-30c6170e3e86&pd_rd_i=B0C79RKMYB&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Unlocked Used Second Hand Mobile Phone for iphone 15 pro max prix 2023
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,400.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Unlocked-Used-Second-Hand-Mobile-Phone_1601020530181.html?spm=a2700.galleryofferlist.normal_offer.d_title.7b3182f9sTNnL8'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy S23 FE AI Phone, 256GB Unlocked
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $509.99
          </p>
          <Link to={'https://www.ebay.com/itm/395398453619?epid=0&itmmeta=01HXMVZAMNAW7N16VVRC30FV62&hash=item5c0f959973:g:H1IAAOSwjrRmP33X&itmprp=enc%3AAQAJAAAA4IdONGtNb7IqfHl4nlLGs6j4JGKvnpwUm%2FFr7CUYYn7O7kvsMk8pYNlhLoxJ41oC0hs6ccuwCvwBa%2BEGfwJVynNgYa6%2FPs45F5Kb%2BcUK9ubnrnYpWII4ygjB6fl7bna8h9XXJrknWf1EeaBhRLS73%2Bz9sg5pDfobZRSvvlmOZz2xMxuhNIwRC2k5CgL905%2Ffi%2F%2B5VoED%2BotOH4JNsdto8rBR5687XO4%2Boy36%2Fa19woPtyZp0V%2BC%2Bo9dbTuccMse%2BmVJcOiD7w8b%2FMoQY0VbriDU6Z7eJqa%2BiSE9rmPQZGdyE%7Ctkp%3ABFBMzKr9m-1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy S24 Ultra Cell Phone, 256GB AI Smartphone, Unlocked Android, 50MP Zoom Camera, Long Battery Life, S Pen, US Version, 2024, Titanium Gray
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,299.99    
          </p>
          <Link to={'https://www.amazon.com/gp/aw/d/B0CMDL3H3P/?_encoding=UTF8&pd_rd_plhdr=t&aaxitk=b1eeb2ae2d3d8ed5071f309027d80b93&hsa_cr_id=0&qid=1715373816&sr=1-1-9e67e56a-6f64-441f-a281-df67fc737124&ref_=sbx_be_s_sparkle_lsi4d_asin_0_img&pd_rd_w=CHG3o&content-id=amzn1.sym.417820b0-80f2-4084-adb3-fb612550f30b%3Aamzn1.sym.417820b0-80f2-4084-adb3-fb612550f30b&pf_rd_p=417820b0-80f2-4084-adb3-fb612550f30b&pf_rd_r=GK528Q9EV4600Q18FWD2&pd_rd_wg=wGl7s&pd_rd_r=501b14ec-dec2-4beb-a3b3-1c8a4e8fe73f'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Hot sale smartphone unlocked original For Samsung Galaxy S21ultra 5G 128gb 256gb 1sim 2sim Android mobile phone
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $392.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Hot-sale-smartphone-unlocked-original-For_1601102469761.html?spm=a2700.galleryofferlist.normal_offer.d_title.1e8492a4vV4t6D'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Google Pixel 8 GKWS6 - 128GB - Obsidian (Verizon)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $389.98
          </p>
          <Link to={'https://www.ebay.com/itm/156001642181?epid=7063142257&itmmeta=01HXMWGPWA0F6QSYZ3C824BTD9&hash=item24526c26c5:g:2SwAAOSw9xxlnrZv&itmprp=enc%3AAQAJAAAA4Alqhr2A8CUp1ZqOcliscwnS%2FdD9QJcNpI2%2Byk4eI0pN9dYwJPY9%2BLK80u5jjY6iuL5blk3lIb70b%2BpZzUGczIVxnBc4vLeKfvfwlv5NbgfajQBWDFmXuM0nk30dJcItYDe%2F2%2BYXGg3cQdQwNeIrhbJQ7XCGltKm%2BJ2TAVz2zVqueew%2F55T5jlzEM2oF%2BW01cqYbu4dGpfWKSUqoHlGwUFDYVd0w6Qgt0zKIiu1qWLTbc0ghPE8OnqrrLPftoz5PF2JlmV7d2oamXkWCfm57vqX9xUNjQNm9M3IrmZA7tIDo%7Ctkp%3ABFBMvu7CnO1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          OnePlus 11 5G | 16GB RAM+256GB | Dual-SIM | Eternal Green | US Factory Unlocked Android Smartphone | 5000 mAh battery | 80W Fast charging | Hasselblad Camera | 120Hz Fluid Display | 4nm Processor
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $577.67   
          </p>
          <Link to={'https://www.amazon.com/OnePlus-Dual-SIM-Smartphone-Hasselblad-Processor/dp/B0BNWQYGZZ/ref=sr_1_4?crid=2IV62SCL728E5&dib=eyJ2IjoiMSJ9.gN9MkgCUsj9TV7HkmhTqMPkOPQx709V2wJVIxDMQU66kLGEGhqB70HSVtV8nRsXnJHc8Qr6pX2N6IjTwirNmG2VLxoLHp6YEDZD0UnwJ9AiqHX-hEybzM0HIMlnzB2xj6gFa9RS51ZSM-Bht0YZ-Lq3XbW2RIhk99io7i2wn_Xt_RkR7h_FJWDONI5d3xTCjVd3PjVQY0xyeitqWioVsn7gt1LnUY85ELRdD_HCQ_8c.FMhB_VeXCBpi0XfMA-xZy4E_tFPwsI6tR-TuFm5nFs0&dib_tag=se&keywords=oneplus%2B11&qid=1715374522&sprefix=oneplus%2B12%2Caps%2C364&sr=8-4&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>






        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Wholesale Original Mobile Phone 8+128GB For Samsung Galaxy S21 Android 5G second-hand Smartphone
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $235.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Wholesale-Original-Mobile-Phone-8-128GB_1601049505954.html?spm=a2700.galleryofferlist.normal_offer.d_title.1e8492a4vV4t6D'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          OnePlus 11 5G 256GB Fully Unlocked Dual Sim Eternal Green OPEN BOX New in Box
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $580.00
          </p>
          <Link to={'https://www.ebay.com/itm/285763256751?epid=15059607528&itmmeta=01HXMXJCTZ11C9CM3VPR1G0VED&hash=item4288d141af:g:D84AAOSw06hl-axG&itmprp=enc%3AAQAJAAAA4BJI2SAzgFA9K%2BrWy0ugnph41OvAhlFIh7KSr1yKTQlceZeTLhY5YmqrfiE6QuuPnAwl0FPxURhXn5aFm7pOggnCA2ZEmTxerGVdMcPejKcntId1FSRCAP5023hND1iXlf8jrEDyB60%2B187%2FaiE73n8pg9PmOIlNrHTOGyHNqv117O2u9LrPT8LMDxw94D%2F%2BpPLwvgK6SB0UapjmN2D6dBDnzgh8oS1QhZfiZm0SE%2F4O3hUG4HYynI6nPDW6mSgQM4%2BE1Szg92wr42gtMKjSYrIZpgAO266H3acW6QDtS3Md%7Ctkp%3ABk9SR9zNyZ3tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Google Pixel 7 5G 128GB 8GB RAM 24-Hour Battery Factory Unlocked for GSM Carriers Global Version - Obsidian (Renewed)
           </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $299.98  
          </p>
          <Link to={'https://www.amazon.com/Google-24-Hour-Battery-Unlocked-Carriers/dp/B0BPMHBGK1/ref=sr_1_10?crid=2R2YWM6XUK92X&dib=eyJ2IjoiMSJ9.Vp7LpuFYdzB-VX5c0qyXrDZBsJ5aDrE9iaRKr5lGjBJ3h4ej-BzigwCfSybkm9OSbmtPjhyTJPIymxBcTDHYMrLaEf8HArtdPPXJtqjzI6I5JAYUUPxpnxZ1R5kmRHeJ02-SlUW4-XzTljHg3GDMZjfhqhByLi7aKMRVugOKZPA62saDPRfcAXAhS9_opgoLTUJZlY7Dk5HZL0p-gsNzPVv_E22-qI0wyuKhaEeQBj8.JyPK6QWcOARq1oli03cg6NelUIxGXlnMI3gOS-Vanaw&dib_tag=se&keywords=google+pixel+7&qid=1715374862&sprefix=google+pixel%2Caps%2C602&sr=8-10'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Android Phone Galaxy S22 Ultra 16GB+1TB Qualcomm 5G Dual SIM Dual Standby Unlocked Smartphone S22 ultra
</h2>
          <p className=" text-gray-500 mt-1 p_price">
          $240.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Android-Phone-Galaxy-S22-Ultra-16GB_1601086215537.html?spm=a2700.galleryofferlist.normal_offer.d_title.15b5a76fcrONVc'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Huawei P60 Art - 512 GB - (Unlocked) (Dual SIM) 4G phone Used
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $799.00
          </p>
          <Link to={'https://www.ebay.com/itm/386987369541?itmmeta=01HXMYJ5N09Z4K782JJHTJQDFS&hash=item5a1a3ea445:g:uTMAAOSwhxNmOiMS&itmprp=enc%3AAQAJAAAA4AOiLPpTy8dQHQvSf8Dusf0z%2FSGzjOgbhuQqIROqGGIguhmrc%2B87VzRnuNW6HHoNYXHiDXry6LJXVkFoW%2Bz8EbCYN8kzonObp6uA4NydUNlJWajt5GlB6YM2%2Fsx9QnmznEbasrY4iitXrPEZEYFd8os23agRPl2saFPGJpy5mrH7DOVEffaDOSNL1zC%2F1cX66YQ0MWRJo4llH0Gqu0klBuXHZlz7XCIgh5agGtDszhP0ixO1mhBDyDKdcw6QMekQW%2F9e6MQkYCGMrdCEUrBaT6hPP28mgTpWrEMU7PW%2FuLLe%7Ctkp%3ABFBMztrInu1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          2022 new Original unlocked used Galaxy S22 Ultra 5G mobile phone for samsung
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $350.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/2022-new-Original-unlocked-used-Galaxy_1601048812385.html?spm=a2700.galleryofferlist.normal_offer.d_title.15b5a76fcrONVc'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Xiaomi 14 Ultra 5G 16GB+512GB BLACK Dual SIM Unlocked Android Cell phone
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,355.00
          </p>
          <Link to={'https://www.ebay.com/itm/226045016280?itmmeta=01HXMYWANX83TCZ8FRSKJZ8535&hash=item34a15538d8:g:TMAAAOSwoLhl8p-0&itmprp=enc%3AAQAJAAAAwNNueImg7yHpDYjoFKC131mcR76OZHQy2yH3NoBNW1vHtieuwe3cKHKNSql5jluzB6eoMHzicXp%2FusT2tos1KkKJrRDlQSn%2BhWQMqro4bSAdzaB3E6YSLgn6tgd9Gh6HKsbsQoJ9XqYGyC%2BLTHP6pU8bEPK0dLZm6pltWTcqAAvx99ZSRcrOiHNLrIXkPYloVyK9FKFsRVh0bsoYnGrlr%2FCK%2B4KNHn5opeCpZ7g5%2FkqFTW3Q8OxkeQxc6c%2FN7TFPKQ%3D%3D%7Ctkp%3ABk9SR7Kr8Z7tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HUAWEI P60 Pro Dual SIM 8GB + 256GB Global Model MNA-LX9 Factory Unlocked (Pearl)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,190.00 
          </p>
          <Link to={'hhttps://www.amazon.com/HUAWEI-P60-Pro-MNA-LX9-Unlocked/dp/B0C1GZL24G?th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          New Original phone S22 Ultra Smart Android Phone 6.8 Inch Qualcomm Dual Sim Dual Standby Unlocked Smartphone Cellphones
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $245.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/New-Original-phone-S22-Ultra-Smart_1600978776346.html?spm=a2700.galleryofferlist.normal_offer.d_title.15b5a76fcrONVc'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Oppo Find X7 5G 6.78"LTPO AMOLED 256 512GB 50MP Dimensity 9300 5000mAh By FedEx
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $827.65
          </p>
          <Link to={'https://www.ebay.com/itm/176167740750?itmmeta=01HXMZ89JNN73HRKEM6KQ4BNVM&hash=item29046a654e:g:6DAAAOSwUnFlnQgp&itmprp=enc%3AAQAJAAAAwDqLJ7OslWJCVy8OBTWHSH7Jiw0XHVxl0SkXVfBwqQOzYjm6d9lYHUH2JUt7TleqgzZOrsijcYrw8s9TmJvhGNzTmhTJlvWdkZALzmF9xGvd%2B7MU0jSDjFK94mjYxQ7xOFDXtWh32bGmkms6ZH0B4qcUaeHEgC%2B8vHuSiAvy07BFP2kogSfVFtNG8H5CysZfCnKqncF08HW0MDAcb4vC2pwYK25X1R0uUFvTwFvV48V6dRLRBalWbTnccdoQhwPMPQ%3D%3D%7Ctkp%3ABk9SR8CZoZ_tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Xiaomi 14 5G + 4G LTE (512GB + 12GB) (UK Plug) Global ROM Unlocked Worldwide(Tmobile Mint Tello & Global) 50MP Triple Pro Leica Camera 6.36" 144Mhz + (Bundle Fast 51w Car Charger) (Jade Green)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $999.99  
          </p>
          <Link to={'https://www.amazon.com/Xiaomi-Unlocked-Worldwide-Tmobile-Charger/dp/B0CXMSHH7H'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Wholesale Original Iphone 11 12 13 pro max Unlock Used Smart Phone
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $216.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Wholesale-Original-Iphone-11-12-13_1601009109774.html?spm=a2700.galleryofferlist.normal_offer.d_title.2c8a6574dzwu5V'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          VIVO X90 5G 6.78"AMOLED DualSIM 50MP Dimensity 9200 4810mAh By FedEx
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $738.88
          </p>
          <Link to={'https://www.ebay.com/itm/334641140966?itmmeta=01HXMZRS8RAS70WPPTKMMJD7Z8&hash=item4dea2a94e6:g:90cAAOSwb95jgIOn&itmprp=enc%3AAQAJAAAA4AcJ95eJIgU%2Fk%2F09h8Nz61OGfjd1QeVWRv4Ln%2BdSXL2dA9aX2xwELLbf0Yj2hgtYeiiCST5dajI6XKZXhlHLS4x08t1fP1utgL4Uyas4iayD%2FriYDGOFlsdDiIGjOzJtS4HP3WMLPDMQ9TlybtI9%2FJF2pQDWt9SkTg6DY7dvjM5PSgp8zpYWASkG9iYV8kGP3gBNL8Yi6T4fJMAGpWFGjtqXyo1Em8GuT5Z6yiQ3%2B6cH42nQIemcZAi4vdo5OxT4Vs7wzAj4XmDC388f3krUW4vuI8spNWukOdWmCnAZE5Zg%7Ctkp%3ABk9SR7yU45_tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          vivo X90 Pro 5G Smartphone, 12+256GB, HQ China Version Origin Product, Full Google Service, 6.78" 120Hz FHD+ AMOLED Screen, 50MP ZEISS Triple Camera, 4870mAh Battery, 120W Fast Charge, Black
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,099.99 
          </p>
          <Link to={'https://www.amazon.com/Smartphone-Version-Product-Service-4870mAh/dp/B0CF2MQVFL/ref=sr_1_1?crid=W8ZZUJA5W0IK&dib=eyJ2IjoiMSJ9.Inh1kY0q2HZKFoA3eF6HPByanZl9qwDBGR5rFGwyKNQmAZ_jiwnONGsqWuGxvW--hLlDOclxzJQUgodd16zRTYz0yfnSEJuX8QQ6zJeFbvheuFuZkE8sr8TNhy2DZLZCiKHLfzCEfvkPOl-WpE7qhpurGHCgU65meMyHPlBkAJTQbCg1ZvowYa5wEUe5vayNZnhKJBx-5Aj0BDWc2ECiXzMHa0050-25nmUGGEKr-AM.aIo8vG8vu2c4Yi0f6bNdCEk09kD-Gwm7f_PsfSJc15Q&dib_tag=se&keywords=Vivo+x90&qid=1715375929&sprefix=vivo+x90%2Caps%2C340&sr=8-1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>





        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Pre-Owned for iPhone 12 256GB 5G 6G RAM 12MP Refurbished for iPhone 11 Pro Max 512GB for iPhone XS 256GB Compatible GSM LTE
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $150.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Pre-Owned-for-iPhone-12-256GB_1601075048123.html?spm=a2700.galleryofferlist.normal_offer.d_title.36605a0eA8Whna'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy Z Flip 5G Factory Unlocked New Android Cell Phone | US Version Smartphone | 256GB Storage | Folding Glass Technology| Long-Lasting Mobile Battery | Mystic Gray (SM-F707UZAAXAA)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $799 
          </p>
          <Link to={'https://www.amazon.com/Samsung-Galaxy-Factory-Unlocked-Version/dp/B08DP4DWKB?th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Used iphones for IPhone X Xr Xs Xsmax 11 12 Pro max 13pro cheap phones unlocked Iphone second hand
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $202.00

          </p>
          <Link to={'https://www.alibaba.com/product-detail/Used-iphones-for-IPhone-X-Xr_1601020287707.html?spm=a2700.galleryofferlist.normal_offer.d_title.36605a0eA8Whna'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Samsung Galaxy Z Flip SM-F700F/DS Dual-SIM 256GB (GSM Only | No CDMA) Factory Unlocked Android 4G/LTE Smartphone - International Version (Mirror Black)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          "$445.00
          </p>
          <Link to={'https://www.amazon.com/Samsung-SM-F700F-Dual-SIM-Unlocked-Smartphone/dp/B084VVVBMB'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        </div>

        <Footer/>
    </div>    
  );
};

export default MobilePhones;