import React from "react";
import { Link } from "react-router-dom";
import aboutSectionImg from "../assets/img/aboutSectionImg.png";
import aboutSectionImg2 from "../assets/img/aboutSectionImg2.png";

const AboutDetails = () => {
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
            <img
              alt="feature"
              className="object-cover object-center h-full w-full"
              src={aboutSectionImg}
            />
          </div>
          <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
            <div className="flex flex-col mb-10 lg:items-start items-center">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  MacBooks
                </h2>
                <p className="leading-relaxed text-base">MacBooks, designed by Apple, are premium laptops known for their sleek design, powerful performance, and seamless integration with the Apple ecosystem. Featuring high-resolution Retina displays, robust processors, and long battery life, they cater to both professionals and everyday users. Running on macOS, MacBooks offer a user-friendly interface, advanced security, and durability, making them a top choice for reliability and efficiency.</p>
                <Link
                  to={"#"}
                  className="mt-3 text-indigo-500 inline-flex items-center"
                >
                  Learn More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="flex flex-col mb-10 lg:items-start items-center">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <circle cx={6} cy={6} r={3} />
                  <circle cx={6} cy={18} r={3} />
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12" />
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Apple Watch
                </h2>
                <p className="leading-relaxed text-base">
                The Apple Watch is a versatile smartwatch that combines health tracking, communication, and fitness features in a stylish design. It offers various functionalities such as heart rate monitoring, activity tracking, and notifications, all accessible from the wrist. With seamless integration with the Apple ecosystem, the Apple Watch enhances user convenience and connectivity in daily life.
                </p>
                <Link
                  to={"#"}
                  className="mt-3 text-indigo-500 inline-flex items-center"
                >
                  Learn More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="flex flex-col mb-10 lg:items-start items-center">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  EarPods
                </h2>
                <p className="leading-relaxed text-base">
                EarPods, designed by Apple, are wired earphones known for their ergonomic design and high-quality audio output. They feature a built-in remote and microphone, allowing users to control music playback and take calls with ease.
                </p>
                <Link
                  to={"#"}
                  className="mt-3 text-indigo-500 inline-flex items-center"
                >
                  Learn More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font bg-sky-100">
        <div className="container px-5 py-24 mx-auto flex flex-wrap justify-between items-center">
          <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2  lg:text-left text-center">
            <div className="flex flex-col mb-10 lg:items-start items-center">
              <div className="flex-grow">
                <h2 className="text-gray-900 text-4xl title-font font-bold mb-3">
                  Mobile Phone Repairing
                </h2>
                <p className="leading-relaxed text-base">
                  Mobile phone repairing is a crucial service in today's tech-driven world, addressing a wide range of issues from cracked screens and battery replacements to software malfunctions and water damage. Skilled technicians use specialized tools and techniques to diagnose and fix these problems, ensuring that devices are restored to optimal functionality. With the increasing reliance on smartphones for daily activities, efficient and reliable repair services are in high demand, providing a cost-effective alternative to purchasing new devices. Quality repair services not only extend the lifespan of mobile phones but also contribute to reducing electronic waste, promoting a more sustainable approach to technology usage.
                </p>
                <Link
                  to={"#"}
                  className="mt-3 text-indigo-500 inline-flex items-center"
                >
                  Learn More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden text-end">
            <img
              alt="feature"
              className="object-cover object-center  w-3/4 mx-auto"
              src={aboutSectionImg2}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutDetails;
