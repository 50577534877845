import React from "react";
import { Link } from "react-router-dom";
import { axiosFetcher } from "../../utils/axiosFetcher";
import { toast } from "react-toastify";
import { ProductCard } from "../../components/RecommendationSlider";
import '../../assets/css/style.css'
import {MobilePhones} from './SubCategory/MobilePhones'
// export const shuffleArray = (array) => {
//   for (let i = array?.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));

//     [array[i], array[j]] = [array[j], array[i]];
//   }
//   return array;
// };

// const categoryData =[
//   {id:'1', categoryHeader:'MobilePhone', platform: 'Amazon',pname:'Iphone', price:'$1300', links:'https://www.google.com/maps'},
//   {id:'1', categoryHeader:'MobilePhone', platform: 'Amazon',pname:'Iphone', price:'$1300', links:''},
//   {id:'1', categoryHeader:'MobilePhone', platform: 'Amazon',pname:'Iphone', price:'$1300', links:''},
//   {id:'1', categoryHeader:'MobilePhone', platform: 'Amazon',pname:'Iphone', price:'$1300', links:''},
//   {id:'1', categoryHeader:'MobilePhone', platform: 'Amazon',pname:'Iphone', price:'$1300', links:''},
//   {id:'1', categoryHeader:'MobilePhone', platform: 'Amazon',pname:'Iphone', price:'$1300', links:''},
// ];


// const CategoryComponent = (props) => {
//   const loginID = sessionStorage.getItem("loginID");
//   const shuffledProducts = shuffleArray(props?.categoryData);

  // const handleViewParticularProduct = async (url, id) => {
  //   const payload = {
  //     productId: id,
  //     userId: loginID,
  //   };

  //   try {
  //     await axiosFetcher.post("/UserVisitedProduct/insert", payload);
  //     // console.log(res);
  //     window.open(url, "_blank");
  //   } catch (error) {
  //     // console.log(error);
  //     toast.error("An error occurred");
  //   }
  // };

  // return (
  //   <div className="flex flex-col gap-4 w-full">
  //     <div className="mobilePhoneHeading flex flex-row items-center justify-between">
  //       <div className="w-1/2">
  //         <h2 className="text-sky-950 text-xl title-font font-bold">
  //           Mobile Phones
  //         </h2>
  //       </div>
        // <div className="w-1/2 text-end">
        //   <Link
        //     className=" text-indigo-500 inline-flex items-center text-xl"
        //   >
        //     View More
        //     <svg
        //       fill="none"
        //       stroke="currentColor"
        //       strokeLinecap="round"
        //       strokeLinejoin="round"
        //       strokeWidth={2}
        //       className="w-4 h-4 ml-2"
        //       viewBox="0 0 24 24"
        //     >
        //       <path d="M5 12h14M12 5l7 7-7 7" />
        //     </svg>
        //   </Link>
        // </div>
  //     </div>

//       <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-full">
//         {/* {shuffledProducts?.slice(0, 4)?.map((products, id) => ( */}
//           <ProductCard
//             // key={id}
//             className={props.categoryHeader === "Mobile Phone" ? "flex flex-col items-start justify-center gap-3 hover:bg-[#B2ff66]" :
//               props.categoryHeader === "Graphic Card" ? "flex flex-col items-start justify-center gap-3 hover:bg-[#99ffff]" :
//                 props.categoryHeader === "Laptops" ? "flex flex-col items-start justify-center gap-3 hover:bg-[#ff99cc]" :
//                   props.categoryHeader === "Smart Watch" ? "flex flex-col items-start justify-center gap-3 hover:bg-[#ffb2c6]" :
//                     props.categoryHeader === "GPU" ? "flex flex-col items-start justify-center gap-3 hover:bg-[#a0a0a0]"
//                       : "flex flex-col items-start justify-center gap-3"}
//           >
//             <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold">
//               Ali Baba
//             </h3>
//             <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap">
//             Original used iPhone 11 unlocked iphone second hand mobile phone for iOS Apple iPhone11
//             </h2>
//             <p className="mt-1">
//             $99.00
//             </p>
//             {/* {loginID && ( */}
//               <button
//                 type="button"
//                 onClick={() =>
//                   // handleViewParticularProduct(product?.link, product?.id)
//                   {"https://www.alibaba.com/product-detail/11-Wholesale-Used-phone11-Smart-Phone_1601085007669.html?spm=a2700.galleryofferlist.normal_offer.d_title.2c8a6574dzwu5V"}
//                 }
//                 className="text-blue-600 hover:underline"
//               >
//                 View Here
//               </button>
//             {/* )} */}
//           </ProductCard>
//         {/* ))} */}
//       </div>
//     </div>
//   );
// };

// export default CategoryComponent;



const CategoryComponent = () => {
  return (

    // <div className="flex flex-col gap-4 w-full">
    //   <div className="mobilePhoneHeading flex flex-row items-center justify-between">
    //     <div className="w-1/2">
    //       <h2 className="text-sky-950 text-xl title-font font-bold">
    //         Mobile Phones
    //       </h2>
    //     </div>
    //     <div className="w-1/2 text-end">
    //       <Link
    //         className=" text-indigo-500 inline-flex items-center text-xl"
    //       >
    //         View More
    //         <svg
    //           fill="none"
    //           stroke="currentColor"
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           strokeWidth={2}
    //           className="w-4 h-4 ml-2"
    //           viewBox="0 0 24 24"
    //         >
    //           <path d="M5 12h14M12 5l7 7-7 7" />
    //         </svg>
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className='c_main'>
      {/* Mobile */}
      <div className="c_header">
        <h2 className="c_title text-gray-600 body-font py-4 mx-4">Mobile Phones</h2>
        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
            Original used iPhone 11 unlocked iphone second hand mobile phone for iOS Apple iPhone11
          </h2>
          <p className="mt-1 p_price">
            $199.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/11-Wholesale-Used-phone11-Smart-Phone_1601085007669.html?spm=a2700.galleryofferlist.normal_offer.d_title.2c8a6574dzwu5V'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          iPhone 11 | "128 GB ROM, 15.49 cm (6.1 inch) Liquid Retina HD Display, 12MP + 12MP | 12MP Front Camera A13 Bionic Chip Processor"
          </h2>
          <p className="mt-1 p_price">
          $595.80 
          </p>
          <Link to={'https://www.flipkart.com/apple-iphone-11-white-64-gb-includes-earpods-power-adapter/p/itm2644c3764fc54?pid=MOBFKCTSHAWGGFHM&lid=LSTMOBFKCTSHAWGGFHMNDVENT&marketplace=FLIPKART&q=iphone+11&store=tyy%2F4io&srno=s_1_1&otracker=search&otracker1=search&fm=organic&iid=794d0a9f-0ace-4914-ba81-110075ef11c0.MOBFKCTSHAWGGFHM.SEARCH&ppt=hp&ppn=homepage&ssid=haxq2csxj40000001715342661265&qH=f6cdfdaa9f3c23f3'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple iPhone 15 128GB Factory Unlocked AT&T T-Mobile Verizon Excellent Condition
          </h2>
          <p className="mt-1 p_price">
          $699.95
          </p>
          <Link to={'https://www.ebay.com/itm/296110312917?epid=20065001328&itmmeta=01HXMTMVG6DKDXBV45VZ4VN256&hash=item44f18ccdd5:g:I1sAAOSw56NlfGAi&itmprp=enc%3AAQAJAAAA4Dgi6U4efiXb73XjvpFOX%2FCgdq8Qag9nHP0QrgJq9xxfjwonsI%2B4lGgdSEsxj18mNIfuO7unkSuJRkzzm5uUynXfqulU%2FDWOqIhDc8pBoqMVpMYLidIQWYzTVg%2B0ztjaXuRRGgWzhTydWrneL3L%2FHsYqkldQGE4vHDJpnrmEAUApNVBWUW4HsR%2BOoAJ06w522ERhz28BSsCLcfaO20gpL5PtDPpUfMVtn7Gadumb3BmoWTTf86ZWs0iToF4GiEKgYmjgxwnecUcoUUmM85Mg9BFEPNyTLGv0vKoRTnT6XxMo%7Ctkp%3ABk9SR6C405rtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy Z Flip 5G Factory Unlocked New Android Cell Phone | US Version Smartphone | 256GB Storage | Folding Glass Technology| Long-Lasting Mobile Battery | Mystic Gray (SM-F707UZAAXAA)
          </h2>
          <p className="mt-1 p_price">
          $799  
          </p>
          <Link to={'https://www.amazon.com/Samsung-Galaxy-Factory-Unlocked-Version/dp/B08DP4DWKB?th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>
        <div className="w-full text-center">
          <Link
          to={"/Category/mobile"}
            className=" text-indigo-500 inline-flex items-center text-l"
          >
            View More
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </Link>
        </div>
      </div>
      
      {/* Laptop */}
      <div className="c_header">
        <h2 className="c_title text-gray-600 body-font py-4 mx-4">Laptop</h2>
        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple MacBook Air Mly33ll/a 2022 - MS3-30w - M2 8GB 13.6" 256GB SSD - Very Good
          </h2>
          <p className="mt-1 p_price">
          $735.99
          </p>
          <Link to={'https://www.ebay.com/itm/145715527105?itmmeta=01HXPRP1MVPWWNP2ACA1BD5BYG&hash=item21ed527dc1:g:sSwAAOSw1kpmFZ0p&itmprp=enc%3AAQAJAAAA4J9MuZa%2FX7h59xeXz5Da3SyjvnTZd0pvFnVGOARIYwK7PmpIh0SUlgsddRpdCp2rKKpOZMgLwTMzKYSXZiE1iqIA0OeM%2F6y%2Fokd7Np0TFVorUvi1XQt57jKdf1YuYvLPnSiXdLTTlFp66kjJIuJKeNUpqOoiCwNgrECUAvG1u1S3bztIRKSJpM8Ml%2B8MkLwzUyQSDYj9pIaSYpHD0C3WRO6%2B7%2BebONVJ0t2sjlgNjAHzh3rPsez1dDEaMBs7W77tClkwU6awPGj7NXFZJ7w08Wk3zUvNBuNv8M2XS51GuwUM%7Ctkp%3ABFBM1JrY2O1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
            HP - Envy x360 2-in-1 15.6" Touch-Screen Laptop - AMD Ryzen 5-8GB Memory - 256GB SSD - Nightfall Black
          </h2>
          <p className="mt-1 p_price">
          $492.50 
          </p>
          <Link to={'https://www.amazon.com/HP-2-1-Touch-Screen-Nightfall/dp/B0B5T546VZ/ref=sr_1_3?crid=CFD9NT0H9R6X&dib=eyJ2IjoiMSJ9.sQvnW2Pn8xN5XHbjEFH6Hb7rti9EUzLVEjXyjBXCN0-OzNYHufsCrShUU5I-OMYNNXG54aS_DvNt3q_UsGvLpg-jln5xWVc4YaH9AbprJPRHaA20Ng1hLg1vgZgoQar5RhUvQUNkqa5kKZZNCy3Jfh8qPmyO_coZoBgeGoC1dXgwAkCNzqhM6mDLQGojNm_rITSUedvkgSsIUQcyu7gBk0WgoWYkXqiDtvv6ACVSlX7KgVKcZBzTcopWqZJ__AoJxgDKXlMDfoeBogzfcAaHcyvxwtRkx2lS5NVYEt4OgYo.BDvpycu16iWHoQpREEP5yDswZ-r6ITfccKVPP66k8hU&dib_tag=se&keywords=hp%2Benvy%2Bx360&qid=1715447075&s=electronics&sprefix=hp%2Benvy%2Bx%2Celectronics%2C3343&sr=1-3&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          DELL XPS 13 | "Thin and Light Laptop 13.3 inch Full HD LED Backlit Display Finger Print Sensor"
          </h2>
          <p className="mt-1 p_price">
          $1,220.82 
          </p>
          <Link to={'https://www.flipkart.com/dell-xps-intel-core-i5-10th-gen-1035g1-8-gb-512-gb-ssd-windows-10-home-9300-thin-light-laptop/p/itmbdb9070446946?pid=COMFTC4YBSK8WZQC&lid=LSTCOMFTC4YBSK8WZQCPSGZIJ&marketplace=FLIPKART&q=DELL+XPS+13&store=6bo%2Fb5g&srno=s_2_27&otracker=search&otracker1=search&fm=Search&iid=d9a46559-055f-452f-945f-1c965b0417ba.COMFTC4YBSK8WZQC.SEARCH&ppt=sp&ppn=sp&qH=9ae262a3c6a55af2'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Al Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Hot Selling Macbook Air M2 Education Version 13.6 Inch Screen Portable Laptop Macbook Netbook For Apple
          </h2>
          <p className="mt-1 p_price">
          $999.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Hot-Selling-Macbook-Air-M2-Education_1601042145111.html?spm=a2700.galleryofferlist.normal_offer.d_title.6c2370c42gKvOh'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="w-full text-center">
          <Link
          to={'/Category/laptops'}
            className=" text-indigo-500 inline-flex items-center text-l"
          >
            View More
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </Link>
        </div>
      </div>

      {/* Graphic Card */}
      <div className="c_header">
        <h2 className="c_title text-gray-600 body-font py-4 mx-4">Graphic Cards</h2>
        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          GTX 3060 3070 3060TI Graphic Card GPU RTX 3060 3070 for laptop desktop
          </h2>
          <p className="mt-1 p_price">
          $469.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/GTX-3060-3070-3060TI-Graphic-Card_1600705995957.html?spm=a2700.galleryofferlist.wending_right.7.3b641b32VUybLM'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA GeForce RTX 3090 Founders Edition Graphics Card (Renewed)
          </h2>
          <p className="mt-1 p_price">
          $939.99 
          </p>
          <Link to={'https://www.amazon.com/NVIDIA-GeForce-Founders-Graphics-Renewed/dp/B09GWB753L/ref=sr_1_2?crid=9TC2S6X2UG34&dib=eyJ2IjoiMSJ9.uMwgKjWhEZ7YIqXU27fi2VQg_FopfOufQjwXwvrE9WZXXV4zg_KhVumUrA17WTLixkwU-Raj7qkrwVK1bVkY8T4d-K9CnnTAqIpY1IlOUQQnUM44JX4Yw-ruCqbcyPvSk4TaH2Hid18omRPLtAdcRcczU0ziLJP6vknPshSLtXxOYUxSMlKbk5OO0n9xhNgbNwKbyiY71Jx79Apl76FQUDEH_Xb7poDx_s36G5kM2yg.yfcXknoM5Y_jkTzV1hFL-gdZQjH6VzxBUOagboMt1HQ&dib_tag=se&keywords=nvidia+gtx+3090&qid=1715378287&sprefix=nvidia+gtx+30%2Caps%2C463&sr=8-2'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          PNY NVIDIA GeForce RTX 4070 Ti OC XLR8 VERTO 12GB GDDR6 Graphics Card GFX NEW
          </h2>
          <p className="mt-1 p_price">
          $774.21
          </p>
          <Link to={'https://www.ebay.com/itm/116087097041?epid=16057036650&itmmeta=01HXPH1NHB508RBDTSDERKF8Q6&hash=item1b075486d1:g:WbAAAOSwB9Zl6fQ2&itmprp=enc%3AAQAJAAAAwNglbQZEwVEfYX%2B8aVv0QnfYpyKsYmG5SHtnfkfkNAHYv1I5JnGKqMNC7NI1Pyg9WyIcCixB4bWB2%2BSnXWrbX5S6RD9%2FMkkW8V655UBM%2Fp%2FEt3A3KXmdsOMVHlR%2F05cye6SiDb%2ByoJIaaKcUIk6vIcYieAkOy7%2BxTQj%2FygnsnMl3Bin8Syj4t4wZeDyiElhtWa3cFEZbcANJq%2BE%2BkDer%2Fw0zYKz83NlgWl%2Bgjg17dzNaGIbPl5TlVwlKBocEiYhZXA%3D%3D%7Ctkp%3ABk9SR_LYhtHtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA RTX 3070 "1755 MHzClock Speed Chipset: NVIDIA BUS Standard: PCI Express® Gen 4 Graphics Engine: GeForce RTX 3070 Memory Interface 256 bit"

          </h2>
          <p className="mt-1 p_price">
          $862.79
          </p>
          <Link to={'https://www.flipkart.com/msi-nvidia-geforce-rtx-3070-ti-ventus-3x-8g-oc-8-gb-gddr6x-graphics-card/p/itmfec5b66ae4c75?pid=GRCGDEFZGBB2ZNHU&lid=LSTGRCGDEFZGBB2ZNHU54I6YQ&marketplace=FLIPKART&q=Nvidia+RTX+3070&store=search.flipkart.com&srno=s_1_4&otracker=search&otracker1=search&fm=Search&iid=2ffca4e3-c4bb-42d2-8017-92c42a3fee5d.GRCGDEFZGBB2ZNHU.SEARCH&ppt=sp&ppn=sp&ssid=pvokiilp280000001715362970491&qH=880e30f68ee2fcec'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>
        <div className="w-full text-center">
          <Link
          to={'/Category/graphic-card'}
            className=" text-indigo-500 inline-flex items-center text-l"
          >
            View More
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </Link>
        </div>

      </div>

      {/* Smart Watch */}
      <div className="c_header">
        <h2 className="c_title text-gray-600 body-font py-4 mx-4">Smart Watch</h2>
        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Ultra [GPS + Cellular 49mm] Titanium Case with Midnight Ocean Band, One Size (Renewed)
          </h2>
          <p className="mt-1 p_price">
          $488.95 
          </p>
          <Link to={'https://www.amazon.com/Apple-Watch-Ultra-Cellular-Titanium/dp/B0BX4XG53Z/ref=sr_1_4?crid=6HO2Z9CCSD5A&dib=eyJ2IjoiMSJ9.QGvzsKxvR8ISwyKKGGhJM8uWM9IK3XISE5ysXr35C4s51tt93Qc5ONWnaAZa8wJlV-tqreevWve8qqW_Oxj74jxgZ9Qs-lpr4qRTi-6XwLOIwpILYkDLerfTM6ph7zP9ze1wmET3EoSAizZd_f3WMgh2jmB7bJdjV4TmK0WFS35r9hIc1eHix50jxFilmKcmoY1LABRz-7RTxpVnE2Gky0XOQHscPgn4NIuXn48DFW4oFu2PKIH1E-2g8KJCdCEOz1HB90EP2YRgZSvs1j-b1mTH6xUpZ_0liKtNcxZV44o.nf7Y6PwvjzrUact8O3QvdysWwl2Ic6YhqcxYtZyCCNw&dib_tag=se&keywords=apple+watch+ultra&qid=1715525475&s=electronics&sprefix=Apple+watch+ultra%2Celectronics%2C374&sr=1-4'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Ultra 49mm Titanium Case with black trail band
          </h2>
          <p className="mt-1 p_prices">
          $600.00
          </p>
          <Link to={'https://www.ebay.com/itm/235542690354?epid=16064146039&itmmeta=01HXPX8PRKVNPTH6C9QH4CT0Z2&hash=item36d7703a32:g:~pgAAOSwWxRl4Xx0&itmprp=enc%3AAQAJAAAA4O99m5YuDJxmMlVXIZmJ45lz0caA8dfnNo2emjEsr6Z0%2FWzEsMpuGoG%2F5oImA07giSD2KcQyvFUQzOUMJfUONBig2ldZEMvY%2FmE1C%2FTvceS8y2or4whFiwW%2F9AnAOvyQxw2RFnO2pY%2FnUEDpmUJK5HMySeE23zB9xPcXkutlJivjeHvjP2dIETynqeQGOkJ0OMy4Y3NrQBVIZCejhZ4UPHTLNC%2FQNCszOgHN7VyaL0M55CEm3x%2BEVce1hRJ0aVVkKbm1WIQlVXmSMkcS0Y8n2gUSVyuvIacvrlxLd6iXXwV%2B%7Ctkp%3ABFBMvuyi3e1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Redmi Watch 4 Smartwatch 1.97'' AMOLED Display Support Bluetooth Voice Call Ultra Long 18 Days Battery Life For Xiaomi
          </h2>
          <p className="mt-1 p_price">
          $89.98 
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Redmi-Watch-4-Smartwatch-1-97_1601000858822.html?spm=a2700.galleryofferlist.normal_offer.d_title.40764102yOATJW&selectedCarrierCode=SEMI_MANAGED_PREMIUM@@PREMIUM'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 font-bold pt-1 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Watch 5 
          </h2>
          <p className="mt-1 p_price">
          $599.99 
          </p>
          <Link to={'https://www.flipkart.com/samsung-watch-5-pro45mmsuper-amoled-displaylte-callingwith-advanced-gps-tracking/p/itm1f47f53dd9db7?pid=SMWGH7W4EACXKDHA&lid=LSTSMWGH7W4EACXKDHA2FDEM4&marketplace=FLIPKART&q=samsung+watch++5&store=ajy%2Fbuh&srno=s_1_4&otracker=search&otracker1=search&fm=Search&iid=b5bbaae3-4391-4992-8cdd-cab917c161d5.SMWGH7W4EACXKDHA.SEARCH&ppt=sp&ppn=sp&ssid=vio9rlonsg0000001715434427539&qH=b880262059834b91'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>
        <div className="w-full text-center">
          <Link
          to={'/Category/smart-watch'}
            className=" text-indigo-500 inline-flex items-center text-l mb-12"
          >
            View More
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </Link>
        </div>
      </div>
    </div>
    );
};

export default CategoryComponent;