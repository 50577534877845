import React from "react";
import { CardMedia } from "@mui/material";
import { useFetcher } from "../../utils/axiosFetcher";
import Loader from "../../utils/Loader";
import CategoryComponent from "./CategoryComponent";
import Header from "../../components/Header";
// import Footer from "../../components/Footer";

import CategoryBanner from "../../assets/img/categoryBannerImg.png";
import Footer from "../../DashboardCustomer/Components/Vendorcomponent/Footer";
// import MobilePhones from "./SubCategory/MobilePhones";

const Category = () => {
  // const loginID = sessionStorage.getItem("loginID");

  // const { data: products, isLoading: productsLoading } =
  //   useFetcher("/Product/get-all?pageSize=100");

  // const allProducts = products?.model?.data;

  // const graphicCard = allProducts?.filter(
  //   (products) => products?.category?.name?.toLowerCase() === "graphic card"
  // );
  // const laptop = allProducts?.filter(
  //   (products) => products?.category?.name?.toLowerCase() === "laptop"
  // );
  // const mobilePhones = allProducts?.filter(
  //   (products) => products?.category?.name?.toLowerCase() === "mobile"
  // );
  // const smartWatch = allProducts?.filter(
  //   (products) => products?.category?.name?.toLowerCase() === "smart watch"
  // );
  // const gpu = allProducts?.filter(
  //   (products) => products?.category?.name?.toLowerCase() === "gpu"
  // );

  return (
    <>
      <Header />

      <section className="flex flex-col gap-4 md:gap-8 text-gray-600 bg-sky-100 body-font">
        <div className="lg:container mx-auto flex lg:flex-row flex-col items-center p-4 bg-gradient-to-r from-slate-500 to-gray-900">
          <div className="flex-[0.7] flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl md:text-6xl text-3xl mb-4 font-bold text-white uppercase ">
              Product Categories
            </h1>
            <p className="mb-8 leading-relaxed text-lg text-white">
              View Various Categories in our store
            </p>
          </div>

          {/* <div className="flex-1 w-full max-w-full md:max-w-[30rem]">
            <CardMedia src={CategoryBanner} component={"img"} alt="laptop" />
          </div> */}
        </div>

        
        {/* )} */}
        <CategoryComponent/>
    
      </section>
      <Footer/>
    </>
  );
};

export default Category;
