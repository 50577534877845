// import SubCategoryComponent from "./SubCategoryComponent";
import {React} from 'react'; 
import Header from "../../../components/Header";
// import SubCategoryComponent from "./SubCategoryComponent";
import { Link } from "react-router-dom";
import Footer from '../../../DashboardCustomer/Components/Vendorcomponent/Footer';
import '../../../assets/css/style.css';

const GraphicCard = () => {
  return(
    <div>
      <Header />
    {/* <SubCategoryComponent productHeader={"Mobile Phones"} categoryId={3} /> */}

      <div className="w-100 bg-sky-100 pb-8">
        <h2 className="c_title text-gray-600 body-font py-4 mx-4">Graphic Cards</h2>
       <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          GTX 3060 3070 3060TI Graphic Card GPU RTX 3060 3070 for laptop desktop
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $469.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/GTX-3060-3070-3060TI-Graphic-Card_1600705995957.html?spm=a2700.galleryofferlist.wending_right.7.3b641b32VUybLM'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA RTX 3070 "1755 MHzClock Speed Chipset: NVIDIA BUS Standard: PCI Express® Gen 4 Graphics Engine: GeForce RTX 3070 Memory Interface 256 bit"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $796.98 
          </p>
          <Link to={'https://www.flipkart.com/msi-nvidia-geforce-rtx-3070-ventus-3x-plus-8g-oc-lhr-8gb-gddr6-256-bit-8-gb-graphics-card/p/itm2287c05ab978c?pid=GRCGEH5FC8TRXJPQ&lid=LSTGRCGEH5FC8TRXJPQGAO3GI&marketplace=FLIPKART&q=Nvidia+RTX+3070&store=search.flipkart.com&srno=s_1_7&otracker=search&otracker1=search&fm=Search&iid=2ffca4e3-c4bb-42d2-8017-92c42a3fee5d.GRCGEH5FC8TRXJPQ.SEARCH&ppt=sp&ppn=sp&ssid=pvokiilp280000001715362970491&qH=880e30f68ee2fcec'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Gigabyte GeForce RTX 3070 Gaming OC 8G Graphics Card, 3X WINDFORCE Fans, 8GB
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $172.50</p>
          <Link to={'https://www.ebay.com/itm/395394416840?itmmeta=01HXMQ17WDBNEC2ZEJM055W43R&hash=item5c0f5800c8:g:o8EAAOSwl5pmPP9a&itmprp=enc%3AAQAJAAAA4Dx4bOe1Cq2lazBs7cX%2B%2B5byO1n2EpeDgWo9L5loBYaGubjV0rIaB2ipmgbQqhAJiWyN2kgbm6RDKDmafQxDuZ1uwXJAajXI0h0m%2FG39vJkGnHbPyLQ4u6kIobaROWFUxO%2FpOgfMLvooweHfwZD6%2B38vGlK9zQj4rcxcnI2hs4RfmAS%2BEic6rpFxWWDcccM7BKj7vo5gz5i8AeSiZslNYrG5d0dxuZ%2BF7zdg5yTGo7gogscz7pL1EnNKdeeElGA0gXwnJ60Tvy21R18vbpWQf4Zu0ZrOsatBacOeMa9vkXQ%2B%7Ctkp%3ABFBMsv6El-1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA GeForce RTX 3070 8GB GDDR6 PCI Express 4.0 Graphics Card - Dark Platinum and Black (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $379.99 
          </p>
          <Link to={'https://www.amazon.com/NVIDIA-GeForce-GDDR6-Express-Graphics/dp/B0B44P3XHP/ref=sr_1_13?crid=2VKFYYKU9GHR4&dib=eyJ2IjoiMSJ9.ZfUrwbLQlTjCdY2LUP-Qj-W-6aNwQ2P0CYFGfqoUshdHwYNbUQJZp37v1h1ot0vToOjhfj2veR3kTdWsV23_0jyCduB4NchCfhVJ7waKGRZk1l3OJRuBmSj32yqyKlG8AtpbiXs0b2h3KRWrfanXoVF3Pg84R11sUvEgNURfErCmTjsy9-cSJoqv_tcINye5Jy9tp7tNYeLKMmWcEcqd8Wq_5RAAnMLNsdcf5f3XD84.113KDDsluyMKsOINkYiViRkymviuqQrGndcicmOilVA&dib_tag=se&keywords=NVIDIA+rtx+3070+12gb&qid=1715377288&sprefix=nvidiartx+3070+12gb%2Caps%2C430&sr=8-13'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA RTX 3070 "1755 MHzClock Speed Chipset: NVIDIA BUS Standard: PCI Express® Gen 4 Graphics Engine: GeForce RTX 3070 Memory Interface 256 bit"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $862.79 
          </p>
          <Link to={'https://www.flipkart.com/msi-nvidia-geforce-rtx-3070-ti-ventus-3x-8g-oc-8-gb-gddr6x-graphics-card/p/itmfec5b66ae4c75?pid=GRCGDEFZGBB2ZNHU&lid=LSTGRCGDEFZGBB2ZNHU54I6YQ&marketplace=FLIPKART&q=Nvidia+RTX+3070&store=search.flipkart.com&srno=s_1_4&otracker=search&otracker1=search&fm=Search&iid=2ffca4e3-c4bb-42d2-8017-92c42a3fee5d.GRCGDEFZGBB2ZNHU.SEARCH&ppt=sp&ppn=sp&ssid=pvokiilp280000001715362970491&qH=880e30f68ee2fcec'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Rtx Gaming Graphics Card rtx 3070ti Graphics Cards 3070Ti 8Gb Gddr6x Memory 256bit
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $280.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Rtx-Gaming-Graphics-Card-Rtx-3070ti_1600515645968.html?spm=a2700.7735675.0.0.40f16kON6kONs9&s=p'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA GeForce RTX 3070 Founders Edition 8GB GDDR6 Graphics Card - Dark Platinum/Black
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $450.00
          </p>
          <Link to={'https://www.ebay.com/itm/355704642991?epid=15041887013&itmmeta=01HXMQ17WDFWYT4QEP02GX7E7V&hash=item52d1a61daf:g:6f4AAOSwWHZkgkGz&itmprp=enc%3AAQAJAAAAwKjbez4hYtewwvUWRjhzy6bEcGUydEMTnCw1Y2ujWzgkijHOup0BgfLFFsxDmEoiHyDTz5gTAQmid5SKVWe%2BjrSU3fNjcdncT7vEBvRPm2uJV%2BloCkF6SZrUWxlwOxDKzEffSZIXhtys4H10u07tg9xPH2%2F8oq0vay8YyZbWDWgonS73pOJS3DpVgdb%2F3Tw14UCQGKqqAdZSUNa%2F2HSy4h603QVmcw5mrQqQtgTjdhmGDYXc1FJrDRe6SL0iULcWyQ%3D%3D%7Ctkp%3ABk9SR7L-hJftYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Used Graphics card RTX 3070 OC 8G Gaming X TRIO Graphics card with 8GB GDDR6 14 Gbps Memory rtx3070 video card 3060 3080 3090
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $380.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Used-Graphics-Card-RTX-3070-OC_1600211607160.html?spm=a2700.7735675.0.0.40f16kON6kONs9&s=p'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA RTX 2060 "1665 MHzClock Speed Chipset: NVIDIA BUS Standard: PCI-E 3.0 X16 Graphics Engine: GEFORCE RTX 2060 Memory Interface 256 bit"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $539.99 </p>
          <Link to={'https://www.flipkart.com/inno3d-nvidia-geforce-rtx-2060-super-twin-x2-oc-8-gb-gddr6-graphics-card/p/itm52537437f3499?pid=GRCFH4HNCHVXZWFT&lid=LSTGRCFH4HNCHVXZWFTSHTWRR&marketplace=FLIPKART&q=Nvidia+RTX+2060&store=search.flipkart.com&srno=s_1_2&otracker=search&otracker1=search&fm=Search&iid=e93bd88d-9eb7-476a-be82-7d866c5ff2c8.GRCFH4HNCHVXZWFT.SEARCH&ppt=sp&ppn=sp&ssid=n1mit49m800000001715363070469&qH=055c30e6815d043b'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          GIGABYTE GeForce RTX 3070 GAMING OC rev. 2.0 8GB GDDR6 Graphics Card...
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $301.39
          </p>
          <Link to={'https://www.ebay.com/itm/156191958664?itmmeta=01HXMQ17WE798AJ2Z9MZVESNRE&hash=item245dc42688:g:q3wAAOSw5HpmNDFP&itmprp=enc%3AAQAJAAAAwPHfRj5OsD8qvOlIUQa5%2BCUIj9u0JViH5iifxyY%2FctYMrLR8bQEk2FiXw83fDzVfUay%2F7fAK3DIUXwcK0v1yBSnTCmTCnjUEzxUfzdRX4an0ZPmUTyAEQzZmM6LEuf4VkRYH6ctZ5Lz7Na4041CyTdx7tn7YAPvml56TliAIOxUTy41UpiEpG7zWMfgP4yoHfMK56Pql%2Btm5QJuuLdeTSyKFFNac5gmDZ%2BLrxhzQGz5rGKcPvxZxSVs8h9B7Tbxu0Q%3D%3D%7Ctkp%3ABk9SR7L-hJftYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA - GeForce RTX 3070 Ti 8GB GDDR6X PCI Express 4.0 Graphics Card - Dark Platinum and Black
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $599.99 
           </p>
          <Link to={'https://www.amazon.com/NVIDIA-GeForce-Express-Graphics-Platinum/dp/B097PZT7J3/ref=sr_1_12_mod_primary_new?crid=2VKFYYKU9GHR4&dib=eyJ2IjoiMSJ9.ZfUrwbLQlTjCdY2LUP-Qj-W-6aNwQ2P0CYFGfqoUshdHwYNbUQJZp37v1h1ot0vToOjhfj2veR3kTdWsV23_0jyCduB4NchCfhVJ7waKGRZk1l3OJRuBmSj32yqyKlG8AtpbiXs0b2h3KRWrfanXoVF3Pg84R11sUvEgNURfErCmTjsy9-cSJoqv_tcINye5Jy9tp7tNYeLKMmWcEcqd8Wq_5RAAnMLNsdcf5f3XD84.113KDDsluyMKsOINkYiViRkymviuqQrGndcicmOilVA&dib_tag=se&keywords=NVIDIA+rtx+3070+12gb&qid=1715377288&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=nvidiartx+3070+12gb%2Caps%2C430&sr=8-12'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA GeForce RTX 3070 8GB GDDR6 PCI Express 4.0 Graphics Card - Dark Platinum and Black
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $615.00 
          </p>
          <Link to={'https://amazon.com/NVIDIA-GeForce-RTX-3070-Graphics/dp/B08MYCN952/ref=sr_1_8?crid=2VKFYYKU9GHR4&dib=eyJ2IjoiMSJ9.ZfUrwbLQlTjCdY2LUP-Qj-W-6aNwQ2P0CYFGfqoUshdHwYNbUQJZp37v1h1ot0vToOjhfj2veR3kTdWsV23_0jyCduB4NchCfhVJ7waKGRZk1l3OJRuBmSj32yqyKlG8AtpbiXs0b2h3KRWrfanXoVF3Pg84R11sUvEgNURfErCmTjsy9-cSJoqv_tcINye5Jy9tp7tNYeLKMmWcEcqd8Wq_5RAAnMLNsdcf5f3XD84.113KDDsluyMKsOINkYiViRkymviuqQrGndcicmOilVA&dib_tag=se&keywords=NVIDIA+rtx+3070+12gb&qid=1715377288&sprefix=nvidiartx+3070+12gb%2Caps%2C430&sr=8-8'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          In Stock RTX 2060 GTX 1660 Ti Graphics Card gtx 1660 super GDDR6 6GB 192bit GPU Gaming Video Card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $201.20
          </p>
          <Link to={'https://www.alibaba.com/product-detail/In-Stock-RTX-2060-GTX-1660_1600664873625.html?spm=a2700.7735675.0.0.2defauz8auz8BO&s=p'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA RTX 2060 "1680 MHzClock Speed Chipset: NVIDIA BUS Standard: PCI-E 3.0 x 16 Graphics Engine: GeForce RTX™ 2060 Memory Interface 192 bit"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $654.00 
          </p>
          <Link to={'https://www.flipkart.com/gigabyte-nvidia-geforce-rtx-2060-d6-6g-6-gb-gddr6-graphics-card/p/itma19b1d215a207?pid=GRCGHADGGAA7SERA&lid=LSTGRCGHADGGAA7SERASAZ6R1&marketplace=FLIPKART&q=Nvidia+RTX+2060&store=search.flipkart.com&srno=s_1_3&otracker=search&otracker1=search&fm=Search&iid=e93bd88d-9eb7-476a-be82-7d866c5ff2c8.GRCGHADGGAA7SERA.SEARCH&ppt=sp&ppn=sp&ssid=n1mit49m800000001715363070469&qH=055c30e6815d043b'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Used MSI GeForce RTX 2060 Ventus 12GB Graphics Card GP/OC Ed.
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $127.50
          </p>
          <Link to={'https://www.ebay.com/itm/196383712791?itmmeta=01HXMQ5WFC922Q35MKQQEZEQJ4&hash=item2db961a617:g:Yb4AAOSwap1mFrQM&itmprp=enc%3AAQAJAAAA4Hwjpfc1TF4%2F6Ys6QqPrPPv%2Bvtuwagh7X5NNgSd1%2FX05IS%2FJibru0O4p%2Be25uJFm%2BMDmA0ECBnfSICWyYupZjc1bhOjILQojEoR45ys1DznhjPpVyQVi6s%2FQSwywBPiaE9z7gsc%2Fzau3hTXsrImi5Q7XtufV2KtXnfgLTPJNP8aJpT1CEJ5ZYf3GBA1s%2B1mVogBF6PpK%2F33UjCKPEZzDklPOFi4mwKX7lZukacloTRH8ypm4bArwRi39DZ9PWPOdZAAym0n4V0xLtuQQn5PJYrGnRYsG0oaydW9k4fK2cl%2BX%7Ctkp%3ABFBM8MeXl-1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          MSI Gaming GeForce RTX 2060 126GB GDRR6 192-bit HDMI/DP 1695 MHz Boost Clock Ray Tracing Turing Architecture VR Ready Graphics Card (RTX 2060 Ventus 6G OC)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $297.50 
          </p>
          <Link to={'https://www.amazon.com/MSI-RTX-2060-Architecture-OC/dp/B09S58J945/ref=sr_1_18?crid=24T5XDCVQN46H&dib=eyJ2IjoiMSJ9.teFP1ILRZi5WdvIaSYBc4gu4-iZDWe7hD2WyFywJTK1CVF3BHd_PoGWXnX64jlj0_bVTLsAjywi1uA5XURuYzg.itq1zaCRF7uFgCbaRESjoSwiEeGoH3oNg4YtAbK7PdQ&dib_tag=se&keywords=NVIDIA+rtx+2060&qid=1715377555&sprefix=nvidia+rtx+20%2Caps%2C433&sr=8-18&xpid=_1A96teVpmPIs'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Used MSI GeForce RTX 2060 8GB Super Ventus GDDR6 Graphics Card 
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $142.50
          </p>
          <Link to={'https://www.ebay.com/itm/196382126099?itmmeta=01HXMQ5WFCYH3P7MZP9WC37Y1K&hash=item2db9497013:g:I5oAAOSwwHRmEYx5&itmprp=enc%3AAQAJAAAA4JbMA2kG5OXEIkzuNlSRotWPhZbEFcH3BHaLvtMYCdNlEcdCMiM5nrdljh%2F8C%2FqdpKUeaneuMb55MQp1SvsGXR%2BmdwhvwHzAW0vrFMI%2Bh0tmLDnSu9TtnrY5oaOsCUiPaKp4LVIO%2Fh9zMzK21mh8FWYEdS%2FWkjUNJ5sjzA7BPtQ%2FVdl5gK071ZgTvp2Ic6iDyW94BH8ymzbASXk4fiun1%2Bv1WfWqCsymN9D0rBVQg3rUa8WIm%2BjrppXqh%2ByZAbLm7GILfPsjU3bstVmUSnFjl%2FgHwIRiaXrqOI2f60cshEdP%7Ctkp%3ABFBM8MeXl-1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          MSI Computer Video Graphic Cards GeForce GTX 1050 TI GAMING X 4G, 4GB
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $247.25 
          </p>
          <Link to={'https://www.amazon.com/MSI-GeForce-GTX-1050-TI/dp/B01MA62JSZ/ref=sr_1_9?crid=25Y8P14OK3SC5&dib=eyJ2IjoiMSJ9.OoIsK7OhHnLhCKhSgC5ilXvjxEP4SaGXrZsgzYaQen6SEPyYwfi7c1X3XGjbVU7Y1nYINpvZVCd4oQ1IzidFu8P2oLDsiloppIq8OoLBvVlE9xMiJ6OyA41j62Wr2tApW_W7D8faStc68fjwOq8T-2O9tom8t7rrkN3E2amDWadhnAGqLk6iEu-ok2gMY7POJ_bFZvUCzrU_SdbcHEk5RcYNBQGeDLD7ta-J5hYJGu4.OW5FtbHdOAi6rEW4Y2jmJkUhYXnMo0VsuPWCzoYuTkk&dib_tag=se&keywords=nvidia%2Bgtx%2B1050&qid=1715378045&sprefix=nvidia%2Brtx%2B1%2Caps%2C337&sr=8-9&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA RTX 1050 1455 MHzClock Speed Chipset: NVIDIA BUS Standard: PCI Express x16 3.0 Graphics Engine: Geforce RTX Memory Interface 128 bit
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $182.92 
          </p>
          <Link to={'https://www.flipkart.com/msi-nvidia-geforce-gtx-1050-ti-4gt-ocv1-4-gb-gddr5-graphics-card/p/itmf26a80f267931?pid=GRCFHJWEXPBT7YQK&lid=LSTGRCFHJWEXPBT7YQKH8UG7K&marketplace=FLIPKART&q=Nvidia+GTX+1050&store=search.flipkart.com&srno=s_1_4&otracker=search&otracker1=search&fm=Search&iid=6fea276d-b431-4973-adae-d97e51c8dcdc.GRCFHJWEXPBT7YQK.SEARCH&ppt=sp&ppn=sp&ssid=8iak81sezk0000001715363184372&qH=f6b78a77ed4af29c'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          RTX 2060 GTX1060 GTX1660S 1660Ti 3gb/5gb/6gb GDDR5 GDDR6 PC Gaming Graphic card Video card</h2>
          <p className=" text-gray-500 mt-1 p_price">
          $201.20
          </p>
          <Link to={'https://www.alibaba.com/product-detail/RTX-2060-GTX1060-GTX1660S-1660Ti-3gb_1600664557984.html?spm=a2700.7735675.0.0.2defauz8auz8BO&s=p'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          ZOTAC GAMING GeForce RTX 3080 Amp Holo 10GB GDDR6X Graphics Card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $414.41
          </p>
          <Link to={'https://www.ebay.com/itm/156202920340?epid=5041892846&itmmeta=01HXPG5A9SZKV2VSAM4A2N0TEH&hash=item245e6b6994:g:1-8AAOSwQHlmNDC5&itmprp=enc%3AAQAJAAAA4HLzMIJWly6xqyl%2B6316hsBOsjf3plYjAbP%2BHNzKSPwdbWM5R1sJuna%2BBr3fn8DZz7fLuTjY5s4lFfdWHUj9Kj7r%2F38wVGbndPzqmMTSlpWSk2dxjEg1Q3IZKZk67yn%2FJ%2BKumyZhp5pa1r1giq9tXeRm6DsyeKB2z8uQtIRqyghbGR9fc7XjWs5xVN01KY4gT0ddxBfmw%2BzphkX1QxxEOAC1OOsfcc0TFwI0OjCcq4zt%2BiYklNllArv%2BoFOAW42lIvjJgpjW4K3dH%2FlLCMrjpuf1N3awbISyzavvYSDzSO1%2B%7Ctkp%3ABk9SR5KlldDtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Nvidia GeForce RTX 3090 Founders Edition Graphics Card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,324.99 
          </p>
          <Link to={'https://www.amazon.com/NVIDIA-RTX-3090-Founders-Graphics/dp/B08HR6ZBYJ/ref=sr_1_1?crid=9TC2S6X2UG34&dib=eyJ2IjoiMSJ9.uMwgKjWhEZ7YIqXU27fi2VQg_FopfOufQjwXwvrE9WZXXV4zg_KhVumUrA17WTLixkwU-Raj7qkrwVK1bVkY8T4d-K9CnnTAqIpY1IlOUQQnUM44JX4Yw-ruCqbcyPvSk4TaH2Hid18omRPLtAdcRcczU0ziLJP6vknPshSLtXxOYUxSMlKbk5OO0n9xhNgbNwKbyiY71Jx79Apl76FQUDEH_Xb7poDx_s36G5kM2yg.yfcXknoM5Y_jkTzV1hFL-gdZQjH6VzxBUOagboMt1HQ&dib_tag=se&keywords=nvidia+gtx+3090&qid=1715378287&sprefix=nvidia+gtx+30%2Caps%2C463&sr=8-1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Used HP NVIDIA GeForce RTX 3080 10GB GDDR6X Video Graphics Card Pulled From Omen
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $399.99
          </p>
          <Link to={'https://www.ebay.com/itm/256500103169?epid=11052944906&itmmeta=01HXPG5A9SW96A4WTHECT6AEDN&hash=item3bb898f801:g:La8AAOSwRFVmNbcQ&itmprp=enc%3AAQAJAAAA4DZOnO7Gi0xkFLlpbpgPgDxalBXXzB6XiIg0yE5m%2FgQrDfjCl%2BXDfpQ9ayK1L730sOGnRSTW1Nqj%2FBvAT28M2lr%2FWhA%2FiGdftzHVUFWwBVoq05BUFrEWWbQLo3HczmMq1vsrdmbDJvIlYxyG40ZhZ2i2aO7XCrdyxUIp1xWnZEubdHRzA7tvLh5O3uyY3nqcYr9WGIvj57OOpXmHir%2BIR9BijIAHAaG8YjczkVB4JVycte506UEFqWv2CX1l3%2BSXxwEPsQ72DF2pCmRW7PFh0tSC9j7z6jBBpkMAqBDjeuDy%7Ctkp%3ABk9SR5KlldDtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA GeForce RTX 3090 Founders Edition Graphics Card (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $939.99 
          </p>
          <Link to={'https://www.amazon.com/NVIDIA-GeForce-Founders-Graphics-Renewed/dp/B09GWB753L/ref=sr_1_2?crid=9TC2S6X2UG34&dib=eyJ2IjoiMSJ9.uMwgKjWhEZ7YIqXU27fi2VQg_FopfOufQjwXwvrE9WZXXV4zg_KhVumUrA17WTLixkwU-Raj7qkrwVK1bVkY8T4d-K9CnnTAqIpY1IlOUQQnUM44JX4Yw-ruCqbcyPvSk4TaH2Hid18omRPLtAdcRcczU0ziLJP6vknPshSLtXxOYUxSMlKbk5OO0n9xhNgbNwKbyiY71Jx79Apl76FQUDEH_Xb7poDx_s36G5kM2yg.yfcXknoM5Y_jkTzV1hFL-gdZQjH6VzxBUOagboMt1HQ&dib_tag=se&keywords=nvidia+gtx+3090&qid=1715378287&sprefix=nvidia+gtx+30%2Caps%2C463&sr=8-2'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA RTX 3080 "1695 MHzClock Speed Chipset: NVIDIA BUS Standard: PCI Express 4.0 16x Graphics Engine: GeForce RTX 3080 Ti Memory Interface 384 bit"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,073.95 
          </p>
          <Link to={'https://www.flipkart.com/zotac-nvidia-rtx-3080-ti-trinity-oc-12-gb-gddr6x-graphics-card/p/itm3aae82c6f91a9?pid=GRCG9BJYB6XKTDDR&lid=LSTGRCG9BJYB6XKTDDRLGC1AV&marketplace=FLIPKART&q=Nvidia+RTX+3080&store=search.flipkart.com&srno=s_1_6&otracker=search&otracker1=search&fm=Search&iid=8c489ad6-2dd7-4c6e-9939-1bd972151be9.GRCG9BJYB6XKTDDR.SEARCH&ppt=sp&ppn=sp&ssid=fk8zztvw740000001715362791973&qH=b78bf2238d48212e'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Winnfox rtx 2060 GTX1060 1660s 3gb 5gb 6gb GDDR5 GDDR6 50w desktop computer gaming graphic card video card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $201.20
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Winnfox-Rtx-2060-GTX1060-1660s-3gb_1600620686325.html?spm=a2700.7735675.0.0.2defauz8auz8BO&s=p'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          GIGABYTE Nvidia RTX 3090 Gaming OC 24GB Graphics Card 
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $594.11
          </p>
          <Link to={'https://www.ebay.com/itm/335385854192?epid=3040744678&itmmeta=01HXPGBAPP152H2RE8B7F5RN76&hash=item4e168e00f0:g:FpcAAOSwM7VmOine&itmprp=enc%3AAQAJAAAA4LeSksYBW91hI5TTvsRSV3H45LdjJmvFqetVWFbUwWpr1C4ooF2B0J5RR9b5IhUS8VXJtUEhrnAcP6Z2noBHf5VYZJxRd99ikxstqI07XqXXZaYR6ZTAPYi5wsUGszuxO1skDOKsiLYWhB%2F3p98Nzh8BObhkwpOSlJ7VOZVQLU88iwogkDPq5nwV0scN8H09RUqeDrWjDzmmMehN%2Ff1v2%2BsmJpl9M6bPHkfn6%2BaPAdHK%2BRWzruDfl4SiSgOvBcbwu3SWO0kG855BcxiJse5VowcYO%2FK%2FoiM51WOf5gnttD1L%7Ctkp%3ABk9SR8arrdDtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          PowerColor Fighter AMD Radeon RX 6500 XT Gaming Graphics Card with 4GB GDDR6 Memory
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $199.99 
          </p>
          <Link to={'https://www.amazon.com/PowerColor-Fighter-Radeon-Gaming-Graphics/dp/B09PSN3Y63/ref=sr_1_2?crid=2S6CQYT0ZVBHF&dib=eyJ2IjoiMSJ9.Hv88sApTj_3zTwy4RlHylGuPykwkhvzlea4jTRnrRlUQ1hlqGtB08MoVsNl-fHJZXo6kZUsBGmtas8Y-RwjadRTPKH-fzKhQ8DHsrnlEaQWMLH4GMXIKqnJI4M1V7FmJYl7lJUzstW1xCOSAlhZ71cOyQkLgyEwsSHAdfGnNK3y7eHvcOVPNKs6JDDYcTe29NxdqxKcELrWyht_b8GF8Rrqfwtu4zN4GmUxpzn8aDzbFMhCCzyHXu_FMiKs0TzTlv1A393REWmu4rmU3JpdWQpFBmpvCVJHKFC5jDit32J8.-oYfTn7-WuKNsHPrYBOCKpZFuom5PcgYiaWSTBCUyKk&dib_tag=se&keywords=AMD+Radeon+rx+6500&qid=1715378969&s=electronics&sprefix=amd+radeon+rx+6500%2Celectronics%2C336&sr=1-2'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          GPU Video Graphics Card GeForce RTX 3070 3080 3090 ASUS MSI GIGABYTE 8GB GDDR6 For Gaming PC
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $336.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/GPU-Video-Graphics-Card-GeForce-RTX_1600276871399.html'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          PNY NVIDIA GeForce RTX 4070 Ti OC XLR8 VERTO 12GB GDDR6 Graphics Card GFX NEW
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $774.21
          </p>
          <Link to={'https://www.ebay.com/itm/116087097041?epid=16057036650&itmmeta=01HXPH1NHB508RBDTSDERKF8Q6&hash=item1b075486d1:g:WbAAAOSwB9Zl6fQ2&itmprp=enc%3AAQAJAAAAwNglbQZEwVEfYX%2B8aVv0QnfYpyKsYmG5SHtnfkfkNAHYv1I5JnGKqMNC7NI1Pyg9WyIcCixB4bWB2%2BSnXWrbX5S6RD9%2FMkkW8V655UBM%2Fp%2FEt3A3KXmdsOMVHlR%2F05cye6SiDb%2ByoJIaaKcUIk6vIcYieAkOy7%2BxTQj%2FygnsnMl3Bin8Syj4t4wZeDyiElhtWa3cFEZbcANJq%2BE%2BkDer%2Fw0zYKz83NlgWl%2Bgjg17dzNaGIbPl5TlVwlKBocEiYhZXA%3D%3D%7Ctkp%3ABk9SR_LYhtHtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          MSI Gaming AMD Radeon RX 6500 XT 64-bit 4GB GDDR6 DP/HDMI PCIe 4 Torx Twin Fans FreeSync DirectX 12 VR Ready OC Graphics Card (RX 6500 XT MECH 2X 4G OC)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $189.99 
          </p>
          <Link to={'https://www.amazon.com/MSI-Radeon-6500-Gaming-Graphics/dp/B09R1RVN3R/ref=sr_1_3?crid=2S6CQYT0ZVBHF&dib=eyJ2IjoiMSJ9.Hv88sApTj_3zTwy4RlHylGuPykwkhvzlea4jTRnrRlUQ1hlqGtB08MoVsNl-fHJZXo6kZUsBGmtas8Y-RwjadRTPKH-fzKhQ8DHsrnlEaQWMLH4GMXIKqnJI4M1V7FmJYl7lJUzstW1xCOSAlhZ71cOyQkLgyEwsSHAdfGnNK3y7eHvcOVPNKs6JDDYcTe29NxdqxKcELrWyht_b8GF8Rrqfwtu4zN4GmUxpzn8aDzbFMhCCzyHXu_FMiKs0TzTlv1A393REWmu4rmU3JpdWQpFBmpvCVJHKFC5jDit32J8.-oYfTn7-WuKNsHPrYBOCKpZFuom5PcgYiaWSTBCUyKk&dib_tag=se&keywords=AMD%2BRadeon%2Brx%2B6500&qid=1715378969&s=electronics&sprefix=amd%2Bradeon%2Brx%2B6500%2Celectronics%2C336&sr=1-3&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA RTX 3090 "1725 MHzClock Speed Chipset: NVIDIA BUS Standard: PCI Express Gen 4 Graphics Engine: GeForce RTX 3090 VENTUS 3X 24G OC Memory Interface 384 bit"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $2,766.41 
          </p>
          <Link to={'https://www.flipkart.com/msi-nvidia-rtx-3090-ventus-24-gb-gddr6x-graphics-card/p/itmcdb76f1d1ef6f?pid=GRCGCCN3ZGB73GJ3&lid=LSTGRCGCCN3ZGB73GJ3ZO6ZWC&marketplace=FLIPKART&q=Nvidia+RTX+3090&store=search.flipkart.com&srno=s_1_4&otracker=search&otracker1=search&fm=Search&iid=5dc4ea0e-752e-4b91-9756-95e36b542b30.GRCGCCN3ZGB73GJ3.SEARCH&ppt=sp&ppn=sp&ssid=ae7bhbis3k0000001715362687806&qH=fdc8f77794ab211e'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Video Memory Type Rtx3080ti 12gb Gaming Graphics Card Brand New Gpu 3070 3080 3090 Video Card Game Graphics Rtx 3080 Ti
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $632.63
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Video-Memory-Type-Rtx3080ti-12gb-Gaming_1601004687416.html'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NEW NVIDIA GeForce RTX 4070 Super Founders Edition 12GB GDDR6X Graphics Card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $695.00
          </p>
          <Link to={'https://www.ebay.com/itm/204635082086?itmmeta=01HXPH1NHBFXWM4C7906RFX0SD&hash=item2fa5338d66:g:eVsAAOSwumllt~Tm&itmprp=enc%3AAQAJAAAA4Ccw%2FOK3nCjF54Pxb62WXy2jTlvCiFAD6H3Bv2dIRfFjwnURArq3Bei5Z0975utvGnJltAzMKIcIHmgNTQoIY0Gw206tnSwIj8B28N10UWsEdcTp%2FlVv8igaG1egGXHliepUEh62m55YwBPq%2Fbs3bjUrJOTMR4OSbc61%2Fwww9gjeUBlqUt0eDc6uC4f2kR7PmfRmGMsdbmHGAlEe%2B1yceg0ekLxkOcxUCS3TmdzUyTRuhaDTj9mmaiK0zlhi45gxbOEy%2F%2BQ4wYDjfz8E2cS5ExEcYbatfD0sz2KkDersnCTE%7Ctkp%3ABFBM8tiG0e1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          ASUS TUF Gaming AMD Radeon RX 6500 XT OC Edition Graphics Card (AMD RDNA 2, PCIe 4.0, 4GB GDDR6, HDMI 2.1, DisplayPort 1.4a, Dual Ball Fan Bearings, All-Aluminum Shroud, GPU Tweak II)
           </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $209.99 
          </p>
          <Link to={'https://www.amazon.com/ASUS-Graphics-DisplayPort-Bearings-All-Aluminum/dp/B09QF3PCT7/ref=sr_1_4?crid=2S6CQYT0ZVBHF&dib=eyJ2IjoiMSJ9.Hv88sApTj_3zTwy4RlHylGuPykwkhvzlea4jTRnrRlUQ1hlqGtB08MoVsNl-fHJZXo6kZUsBGmtas8Y-RwjadRTPKH-fzKhQ8DHsrnlEaQWMLH4GMXIKqnJI4M1V7FmJYl7lJUzstW1xCOSAlhZ71cOyQkLgyEwsSHAdfGnNK3y7eHvcOVPNKs6JDDYcTe29NxdqxKcELrWyht_b8GF8Rrqfwtu4zN4GmUxpzn8aDzbFMhCCzyHXu_FMiKs0TzTlv1A393REWmu4rmU3JpdWQpFBmpvCVJHKFC5jDit32J8.-oYfTn7-WuKNsHPrYBOCKpZFuom5PcgYiaWSTBCUyKk&dib_tag=se&keywords=AMD+Radeon+rx+6500&qid=1715378969&s=electronics&sprefix=amd+radeon+rx+6500%2Celectronics%2C336&sr=1-4'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Used MSI Video Card GeForce RTX 4070 4070 Ti Gaming X Trio Series 12GB Graphics Card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $759.00
          </p>
          <Link to={'https://germany.alibaba.com/product-detail/Used-MSI-Video-Card-GeForce-RTX_1601033060311.html'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          XFX Speedster SWFT 210 AMD Radeon RX 6600 Core Gaming 8GB GDDR6 Graphics Card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $160.00
          </p>
          <Link to={'https://www.ebay.com/itm/256506592931?epid=20052103746&itmmeta=01HXPJK898YPX6MZZBB2J98JJ4&hash=item3bb8fbfea3:g:YgQAAOSw7VxmPPcU&itmprp=enc%3AAQAJAAAAwC8j9VcoLq6LZJ03HFY3CZbOknqm%2F6FX4WQFY22nml0IZ%2FAhkFD7bKDqWdIT4gGfGPpinn0%2FcFEj6RRmtLPQY2E3Cs3qJpMBbS075edc0wTIVYORBdEXY96joN4v18Pe3foUU%2BkYBgXzAWJec48YaGSxnllaGsUFFRPWcZ7PKYwlvJQMfUtjUcspTS5CcKAYEKMi2k01YGAGpUoxMVTWZwhVNZ4dVrrDjfEbb%2FegBv%2BCCXYJKgq9zYUqcJuqPlI3aA%3D%3D%7Ctkp%3ABk9SR-yEzdLtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          NVIDIA RTX 4070 "2475 MHzClock Speed Chipset: NVIDIA BUS Standard: PCI Express 4.0 16x Graphics Engine: GEFORCE RTX 4070 Memory Interface 192 bit
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $786.15 
          </p>
          <Link to={'https://www.flipkart.com/zotac-nvidia-geforce-rtx-4070-trinity-12-gb-gddr6x-graphics-card/p/itmaced0687380da?pid=GRCGRAAYNBKGJ6GJ&lid=LSTGRCGRAAYNBKGJ6GJGHR6WE&marketplace=FLIPKART&q=Nvidia+RTX+4070&store=search.flipkart.com&srno=s_1_9&otracker=search&otracker1=search&fm=Search&iid=010611fc-cece-498a-87e9-56cdd709a762.GRCGRAAYNBKGJ6GJ.SEARCH&ppt=sp&ppn=sp&ssid=b4rvlqqg1s0000001715362719577&qH=b2101f39b41f6d44'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          msi rtx 4070 VENTUS 2X 12Gb gaming graphics card rtx4070 12 gb nvidia geforce rtx 4060 ti vga computer 4080 4090 video rtx 4070
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $799.00
          </p>
          <Link to={'https://germany.alibaba.com/product-detail/msi-rtx-4070-VENTUS-2X-12Gb_1601008725430.html'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          ASUS Dual Radeon RX 6600 XT OC 8GB GDDR6 Video Card (90YV0GN1-M0NA00)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $152.50
          </p>
          <Link to={'https://www.ebay.com/itm/386982871445?epid=13049608141&itmmeta=01HXPJK898823S1ASAYG6XV2SJ&hash=item5a19fa0195:g:DicAAOSwQPZmN7zQ&itmprp=enc%3AAQAJAAAAwCEqPdpjYvbcm%2FxZpW%2FAlIE6QBDtbbAXSYH1yl%2Bgv0R6w5IJay%2FBKHM7M2LxkG05GD7wVdfRC5Hf%2F669Mrzce60R3kV%2FNDPe1ZgNjjgmYDTYMtbdYtTtXEtSkgkpj7kA3k9sTH09q8uMqWayTTzpk4qW8efIOiGiZ7nxDvrzLlY7XT%2BulMTzMp3rbWscQisyfPT1ilxk4SS10AlnRimFbz8zsghMNr2GhHlYGocwokXfwesCCk4nDUNVWeV2e6Bndg%3D%3D%7Ctkp%3ABk9SR-yEzdLtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          XFX Speedster QICK319 AMD Radeon RX 6800 Black Gaming Graphics Card with 16GB GDDR6 HDMI 3xDP RX-68XLALBD9
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $484.90 
          </p>
          <Link to={'https://www.amazon.com/XFX-Speedster-QICK319-Graphics-RX-68XLALBD9/dp/B08THKRCSR/ref=sr_1_5?crid=J6DRYIMVMJLD&dib=eyJ2IjoiMSJ9.U7H8lLyQ22zS8Dl_73Maewhiyf8eBROcHnDkqwwHhz-SsagyQ1dcWE2tjO-xpDIUnuLGYJ5lx4KvgZSmGcX-fwgrzcMGu-6T4XAQpNkewc-plKyXGx0N0HjVTitgelGCeuwSAuHtTbX8OwX85pcD3ONPEiED3_sVK-DhfuDWzBEOPVqVGVG8oXd5fd7fz6qurgJ7QWMcdFclkeGIBITMHiz1ihs9NeICK7oStBNFf9trIK1YnR2a7JZJG89gYR4hW9biBSFGCnlUtpSOyLadXX-jkeqk2s7uMG_P35hj5lQ.Tp631n_s3DXTW_tMNXmox9vO_0FXEzHotychYAujTg8&dib_tag=se&keywords=AMD+Radeon+rx+6800&qid=1715379359&s=electronics&sprefix=amd+radeon+rx+65%2Celectronics%2C732&sr=1-5'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Spot original quality computer graphics card RX 6600 6700 6800 6900XT game graphics card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $498.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Spot-original-quality-computer-graphics-card_1601018224744.html'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          AMD Radeon RX 6500 "2685 MHzClock Speed Chipset: AMD Radeon BUS Standard: PCIe 4.0 Graphics Engine: RX 6500 XT Memory Interface 64 bit"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $312.84 
          </p>
          <Link to={'https://www.flipkart.com/amd-radeon-sapphire-pulse-rx-6500-xt-gaming-graphics-card-4gb-gddr6-rdna-2-4-gb-gddr6/p/itm19820d8ba65cc?pid=GRCGCG63FNSCDGSD&lid=LSTGRCGCG63FNSCDGSDOFQVXJ&marketplace=FLIPKART&q=AMD+Radeon+RX+6500+XT&store=search.flipkart.com&srno=s_1_7&otracker=search&otracker1=search&fm=Search&iid=17266097-6223-4a35-a6e8-438df631681a.GRCGCG63FNSCDGSD.SEARCH&ppt=sp&ppn=sp&ssid=3z2k9kj8og0000001715363629389&qH=6486d77ec4515c8f'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          PowerColor Red Dragon AMD Radeon RX 6800 XT 3DHR OC 16GB GDDR6 Graphic Card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $380.00
          </p>
          <Link to={'https://www.ebay.com/itm/276459927757?epid=7042953939&itmmeta=01HXPHTMCF3XMVAXTGSJJ137E1&hash=item405e4bb8cd:g:FjIAAOSwJklmPMHf&itmprp=enc%3AAQAJAAAAwCT6ZErfdIcO0XhyeRMFse81zAnaNkq99u8QcVXRHTFNoCtLuAM%2FPhTyL1AyMFgmKnwX3kQpD3d2ugRVOr7JywPmgoeM4mDzRu%2BeLz45JiK8yMvVPMzFVH1e2Z7s4Y9omEes9tkYVdIXU9%2B9Fvf%2BmG%2Biy5sePv1%2FE1fnqAoMDGqELndgifaF2mrKvDtQJSLBcWlXeQeAkaJ6AkigPkk4q2VZudp%2BnPVsid5wzl28RZ5D8vWAxtqzkEenLKOccWdbUQ%3D%3D%7Ctkp%3ABk9SR7TG6tHtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Sapphire 11305-02-20G Pulse AMD Radeon RX 6800 PCIe 4.0 Gaming Graphics Card with 16GB GDDR6 Pack of 1,Black</h2>
          <p className=" text-gray-500 mt-1 p_price">
          $394.99 
          </p>
          <Link to={'https://www.amazon.com/Sapphire-11305-02-20G-Radeon-Gaming-Graphics/dp/B08NXYBVDB/ref=sr_1_6?crid=J6DRYIMVMJLD&dib=eyJ2IjoiMSJ9.U7H8lLyQ22zS8Dl_73Maewhiyf8eBROcHnDkqwwHhz-SsagyQ1dcWE2tjO-xpDIUnuLGYJ5lx4KvgZSmGcX-fwgrzcMGu-6T4XAQpNkewc-plKyXGx0N0HjVTitgelGCeuwSAuHtTbX8OwX85pcD3ONPEiED3_sVK-DhfuDWzBEOPVqVGVG8oXd5fd7fz6qurgJ7QWMcdFclkeGIBITMHiz1ihs9NeICK7oStBNFf9trIK1YnR2a7JZJG89gYR4hW9biBSFGCnlUtpSOyLadXX-jkeqk2s7uMG_P35hj5lQ.Tp631n_s3DXTW_tMNXmox9vO_0FXEzHotychYAujTg8&dib_tag=se&keywords=AMD%2BRadeon%2Brx%2B6800&qid=1715379359&s=electronics&sprefix=amd%2Bradeon%2Brx%2B65%2Celectronics%2C732&sr=1-6&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          RX 6900 XT 8GB GDDR 6 Second hand GPU RX 6900 6700 6800 Gaming Graphics Card
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $203.40
          </p>
          <Link to={'https://www.alibaba.com/product-detail/RX-6900-XT-8GB-GDDR-6_1600441929429.html'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          AORUS Radeon RX 6800 XT Master 16G Graphics Card GV-R68XTAORUS M-16GD Working
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $275.00
          </p>
          <Link to={'https://www.ebay.com/itm/116171499228?epid=22042949329&itmmeta=01HXPHTMCF3TP8ZXDBMD1PHKRJ&hash=item1b0c5c66dc:g:63AAAOSw3d5mH0Nv&itmprp=enc%3AAQAJAAAAwE2HWcVjhIB0fnXz%2FXfnA7Sg7unRTIKWEfDKLfEofrVQJEtphxRuwSQd1fI3E9QuZ7ba%2B5Ie8kSo5j8rEO520uYckGXvF3A3aIOEQenkpzaJ1OUyN0TbHd7QLC27UXQLc4Ev7MOsSaGLG9zy5JE8MIqg2P5QWZiwGOT1T4becqsnGTysQv1rxtaG4%2Bum9t8%2B1RepMZrhlEUFhI%2F0Yb7e%2FivPxeX1ZoappEGhLneLdIObYO0auRwTOJ54sRgaB8gPNg%3D%3D%7Ctkp%3ABk9SR7TG6tHtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SHOWKINGS Radeon RX 580 8GB Graphics Card, 256Bit 2048SP GDDR5 AMD Video Card for Pc Gaming, DP HDMI DVI-Output, PCI Express 3.0 with Dual Fan for Office and Gaming
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $105.99 
          </p>
          <Link to={'https://www.amazon.com/SHOWKINGS-RX-580-Graphics-DVI-Output/dp/B0BLZHHFMB/ref=sr_1_1_sspa?crid=16R5CCGP1XFZX&dib=eyJ2IjoiMSJ9.yNRes7GIPFgKDcq7hxgDTZt7jqErdn6-Ydas4lyXPrklgZyriMKFTF01txcCv0Dd4RBQb3Hflos7v0jiqWDDuWmakbNvacuznUnI1nAkQ6Lb88QV5Ada1OLvpHHUXpYubBQZLdJ1qu1zH1uUER87jM91OqchKZ_mqtptaRS32fcr65LmmMfRLNw9qjhh3BjWEBwhEnap-1rlUpToiQlHVpqOR4cym-SJ365Oo6OKXnEUdvT--kgTsuE-kWAtd-ii5mJOmNDK5LzHQqpPNiA8aT0hC8UyPiWPXJx-N-hL4DI.Yk7E4VO6UJ9OE6NvsxtgvugeYcOGSV0JlPpEIwrBQe8&dib_tag=se&keywords=AMD%2BRadeon%2Brx%2B580&qid=1715379607&s=electronics&sprefix=amd%2Bradeon%2Brx%2B58%2Celectronics%2C642&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>





        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Radeon RX 6600 Video Card with WINDFORCE 3X Cooling 6600 xt GPU Graphics Cards for gaming pc
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $223.00
          </p>
          <Link to={'https://germany.alibaba.com/product-detail/Radeon-RX-6600-Video-Card-with_1600452144265.html?spm=a27de.search_countrysitede.normal_offer.d_title.53ef6c0dXB5BlQ'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          maxsun AMD Radeon RX 580 8GB 2048SP GDDR5 Computer Video Graphics Card GPU for PC Gaming 256-Bit DirectX 12 DVI, HDMI, DisplayPort Multi Monitors Extend (Black)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $116.99 
          </p>
          <Link to={'https://www.amazon.com/maxsun-RX-580-2048SP-DisplayPort/dp/B0BY1GHRDV/ref=sr_1_2_sspa?crid=16R5CCGP1XFZX&dib=eyJ2IjoiMSJ9.yNRes7GIPFgKDcq7hxgDTZt7jqErdn6-Ydas4lyXPrklgZyriMKFTF01txcCv0Dd4RBQb3Hflos7v0jiqWDDuWmakbNvacuznUnI1nAkQ6Lb88QV5Ada1OLvpHHUXpYubBQZLdJ1qu1zH1uUER87jM91OqchKZ_mqtptaRS32fcr65LmmMfRLNw9qjhh3BjWEBwhEnap-1rlUpToiQlHVpqOR4cym-SJ365Oo6OKXnEUdvT--kgTsuE-kWAtd-ii5mJOmNDK5LzHQqpPNiA8aT0hC8UyPiWPXJx-N-hL4DI.Yk7E4VO6UJ9OE6NvsxtgvugeYcOGSV0JlPpEIwrBQe8&dib_tag=se&keywords=AMD%2BRadeon%2Brx%2B580&qid=1715379607&s=electronics&sprefix=amd%2Bradeon%2Brx%2B58%2Celectronics%2C642&sr=1-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          AMD Radeon RX 6800 " 16000 MHzClock Speed Chipset: AMD Radeon BUS Standard: PCI Express 4.0 x16 Graphics Engine: Radeon RX 6800 XT Memory Interface 256 bit"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,399.98 
          </p>
          <Link to={'https://www.flipkart.com/gigabyte-amd-radeon-gv-r68xtgaming-oc-16gd-16-gb-gddr6-graphics-card/p/itm298dce70402c6?pid=GRCGC4H4EA2JWRMZ&lid=LSTGRCGC4H4EA2JWRMZXJQERE&marketplace=FLIPKART&q=AMD+Radeon+RX+6800&store=search.flipkart.com&srno=s_1_4&otracker=search&otracker1=search&fm=Search&iid=bc85d574-f8d8-4876-af41-bbd0efce4ff5.GRCGC4H4EA2JWRMZ.SEARCH&ppt=sp&ppn=sp&ssid=tvozw6qmu80000001715363319487&qH=39757ae0b61533e7'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          rx6600 PULSE RX 6600 GPU 8GB 128 bit DDR6 14 Gbps Video Card Graphic Card Also sell Rx580 rx6800xt
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $229.00 
          </p>
          <Link to={'https://www.alibaba.com/product-detail/rx6600-PULSE-RX-6600-GPU-8GB_1600440841224.html'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          ZER-LON AMD Radeon RX 580 Graphic Cards, 8GB 256Bit 2048SP GDDR5 1284/7000MHz, DP HDMI-Output, PCI Express 3.0 with Dual Fan for Office and PC Gaming
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $99.99 
          </p>
          <Link to={'https://www.amazon.com/ZER-LON-Graphic-7000MHz-DVI-Output-Express/dp/B0BNTF6N18/ref=sr_1_5?crid=16R5CCGP1XFZX&dib=eyJ2IjoiMSJ9.yNRes7GIPFgKDcq7hxgDTZt7jqErdn6-Ydas4lyXPrklgZyriMKFTF01txcCv0Dd4RBQb3Hflos7v0jiqWDDuWmakbNvacuznUnI1nAkQ6Lb88QV5Ada1OLvpHHUXpYubBQZLdJ1qu1zH1uUER87jM91OqchKZ_mqtptaRS32fcr65LmmMfRLNw9qjhh3BjWEBwhEnap-1rlUpToiQlHVpqOR4cym-SJ365Oo6OKXnEUdvT--kgTsuE-kWAtd-ii5mJOmNDK5LzHQqpPNiA8aT0hC8UyPiWPXJx-N-hL4DI.Yk7E4VO6UJ9OE6NvsxtgvugeYcOGSV0JlPpEIwrBQe8&dib_tag=se&keywords=AMD+Radeon+rx+580&qid=1715379607&s=electronics&sprefix=amd+radeon+rx+58%2Celectronics%2C642&sr=1-5'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          AMD Radeon RX 582 "1386 MHzClock Speed Chipset: AMD/ATI BUS Standard: AGP Graphics Engine: Radeon Memory Interface 256 bit"
</h2>
          <p className=" text-gray-500 mt-1 p_price">
          $533.33 
          </p>
          <Link to={'https://www.flipkart.com/ogheo-amd-ati-rx580-8-gb-gddr5-graphics-card/p/itm660e35ea1e6f6?pid=GRCGAT69GHEHMQ3Y&lid=LSTGRCGAT69GHEHMQ3YKAVDKE&marketplace=FLIPKART&q=AMD+Radeon+RX+580&store=search.flipkart.com&srno=s_1_2&otracker=search&otracker1=search&fm=Search&iid=271ad4e3-a764-4436-9ae0-0ddc468d1a0c.GRCGAT69GHEHMQ3Y.SEARCH&ppt=sp&ppn=sp&ssid=2hfqs99ths0000001715365234447&qH=74e2b1660a81ff2d'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        </div>

        <Footer/>
    </div>    
  ); 
  // <SubCategoryComponent productHeader={"Graphic Card"} categoryId={1} />;
};

export default GraphicCard;
