import Header from "../../../components/Header";
import Footer from "../../../DashboardCustomer/Components/Vendorcomponent/Footer";
import SubCategoryComponent from "./SubCategoryComponent";
import { Link } from "react-router-dom";

const SmartWatch = () => {
  return(
    <div>
      <Header/>
    {/* <SubCategoryComponent productHeader={"Mobile Phones"} categoryId={3} /> */}

      <div className="w-100 bg-sky-100 pb-8">
        <h2 className="c_title text-gray-600 body-font py-4 mx-4">Smart Watch</h2>
       <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Second-hand 2023 latest 100% original series 9 intelligent lock-free waterproof watches 41mm 45mm.
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $315.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Second-hand-2023-latest-100-original_1601013872482.html?spm=a2700.galleryofferlist.normal_offer.d_title.5ea82e07S6l0K6'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 6" attend calls and reply to messages using the GPS model with the latest inbuilt sensor you can measure your level of blood oxygen With an in-built ECG, you can track your heart movements 5GHz Wi-Fi and U1 Ultra Wideband chip Apple Watch Series 6 - 44 mm With Call Function
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $527.88 
          </p>
          <Link to={'https://www.flipkart.com/apple-watch-series-6-gps/p/itm3a6f18a8e0326?pid=SMWFVNKG4FHZXPZN&lid=LSTSMWFVNKG4FHZXPZNRBUFDH&marketplace=FLIPKART&q=apple+watch+series+6&store=ajy%2Fbuh&srno=s_1_3&otracker=search&otracker1=search&fm=Search&iid=b4b7a66e-0dd2-4e18-bdee-1427698641e6.SMWFVNKG4FHZXPZN.SEARCH&ppt=sp&ppn=sp&ssid=4jux5orm9c0000001715433921524&qH=b4ac3f371ff9d2af'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Ultra 49mm Titanium Case with black trail band
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $600.00
          </p>
          <Link to={'https://www.ebay.com/itm/235542690354?epid=16064146039&itmmeta=01HXPX8PRKVNPTH6C9QH4CT0Z2&hash=item36d7703a32:g:~pgAAOSwWxRl4Xx0&itmprp=enc%3AAQAJAAAA4O99m5YuDJxmMlVXIZmJ45lz0caA8dfnNo2emjEsr6Z0%2FWzEsMpuGoG%2F5oImA07giSD2KcQyvFUQzOUMJfUONBig2ldZEMvY%2FmE1C%2FTvceS8y2or4whFiwW%2F9AnAOvyQxw2RFnO2pY%2FnUEDpmUJK5HMySeE23zB9xPcXkutlJivjeHvjP2dIETynqeQGOkJ0OMy4Y3NrQBVIZCejhZ4UPHTLNC%2FQNCszOgHN7VyaL0M55CEm3x%2BEVce1hRJ0aVVkKbm1WIQlVXmSMkcS0Y8n2gUSVyuvIacvrlxLd6iXXwV%2B%7Ctkp%3ABFBMvuyi3e1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Ultra [GPS + Cellular 49mm] Titanium Case with Midnight Ocean Band, One Size (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $488.95 
          </p>
          <Link to={'https://www.amazon.com/Apple-Watch-Ultra-Cellular-Titanium/dp/B0BX4XG53Z/ref=sr_1_4?crid=6HO2Z9CCSD5A&dib=eyJ2IjoiMSJ9.QGvzsKxvR8ISwyKKGGhJM8uWM9IK3XISE5ysXr35C4s51tt93Qc5ONWnaAZa8wJlV-tqreevWve8qqW_Oxj74jxgZ9Qs-lpr4qRTi-6XwLOIwpILYkDLerfTM6ph7zP9ze1wmET3EoSAizZd_f3WMgh2jmB7bJdjV4TmK0WFS35r9hIc1eHix50jxFilmKcmoY1LABRz-7RTxpVnE2Gky0XOQHscPgn4NIuXn48DFW4oFu2PKIH1E-2g8KJCdCEOz1HB90EP2YRgZSvs1j-b1mTH6xUpZ_0liKtNcxZV44o.nf7Y6PwvjzrUact8O3QvdysWwl2Ic6YhqcxYtZyCCNw&dib_tag=se&keywords=apple+watch+ultra&qid=1715525475&s=electronics&sprefix=Apple+watch+ultra%2Celectronics%2C374&sr=1-4'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          for apple watch Series 6 100% Original just Touch Screen Display Digitizer Assembly apple watch For apple watch Series 6 lcd
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $129.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/for-apple-watch-Series-6-100_1600114953766.html?spm=a2700.galleryofferlist.normal_offer.d_title.5b5645c3FPJR40'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Ultra GPS & Cellular 49mm Titanium Trail Alpine Loop Ocean Excellent
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $459.99
          </p>
          <Link to={'https://www.ebay.com/itm/165868496837?itmmeta=01HXPX8PRKD8JNH0H0M7XA4ZE9&hash=item269e8867c5:g:-DEAAOSwmgVjtb~E&itmprp=enc%3AAQAJAAAA4MVHaPRu38iN3JixlJPdk60Co95IR0GUNeMRCQNFITDQUZMPhStIzvwvT4yxXtc4DTYyfSbRCES1l6NPZ8MF8%2FN0nO%2BLcoUmPMYsUHI6kvJMabbMBpOznaF%2FoZf6OhjzNh6v1QGvHVyKFWtIJI4548W6D07%2Bpfo9ZCHOyU8LPmq3Mu7dm65E9OWn0u035O3Im2KdY4COyYuMktqXeaAX%2Fq1HhcmRvTqp5phA5EH%2B5mVrRYmdxEEw9FuvJT5r1TB9R4ciU6XWJDN1zaDdCGv77Yo9gRjVmzRdDl6EPj5ypfxB%7Ctkp%3ABFBMwOyi3e1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 6 attend calls and reply to messages using the GPS model with the latest inbuilt sensor you can measure your level of blood oxygen With an in-built ECG, you can track your heart 5GHz Wi-Fi and U1 Ultra Wideband chip use fitness app on your iphone to see your daily activity trends Apple Watch Series 6 - 40 mm With Call Function
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $490.38 
          </p>
          <Link to={'https://www.flipkart.com/apple-watch-series-6-gps/p/itm31906982c099a?pid=SMWFVNKGGCFZSJTM&lid=LSTSMWFVNKGGCFZSJTM3RKD5E&marketplace=FLIPKART&q=apple+watch+series+6&store=ajy%2Fbuh&srno=s_1_4&otracker=search&otracker1=search&fm=Search&iid=b4b7a66e-0dd2-4e18-bdee-1427698641e6.SMWFVNKGGCFZSJTM.SEARCH&ppt=sp&ppn=sp&qH=b4ac3f371ff9d2af'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Xiaomi Redmi Watch 4 Smart Watch 1.97'' Amoled Display Support Bluetooth Voice Call Ultra Long 18 Days Battery Life For Xiaomi
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $70.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Xiaomi-Redmi-Watch-4-Smart-Watch_1601024899533.html?spm=a2700.galleryofferlist.normal_offer.d_title.40764102yOATJW'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Original Xiaomi Redmi Watch 4 1.97'' Bluetooth Smartwatch Health Monitor NFC
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $98.78
          </p>
          <Link to={'https://www.ebay.com/itm/395281166059?itmmeta=01HXPZ0RNSWPEMACM6MAX1FXDZ&hash=item5c0897eeeb:g:zGUAAOSw0yVlZ~tM&itmprp=enc%3AAQAJAAAA4NhpAMjVcsWBh8nbG2cYPr27JeydCSikrJMDCoDfBbNKI7OiLrT7hYiCHDI65xIArKOB3Yv7x19T4phd6y4qWc9lpehBOmGmMdPDvRt%2FAwnHME57Le%2FOgu%2B0t5Fde5Bhlq1MKwTys1Q0Z112ke3ZmznZk72AKfSKetnbxA%2F%2BZSaL8muHqKO538qrQySehcPuX9aC1orkKD2Re0%2FvCrArNJbY0IzfcZfzJPZtJM6dSctltsCestOBJUqtpmJYwNS%2FwuUye%2FfKW9eqN7btlC5VCK0qMRyuLREya3H1PNkxmxsW%7Ctkp%3ABFBMjIuD3-1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Ultra [GPS + Cellular 49mm] Titanium Case with Midnight Ocean Band, One Size (Renewed Premium)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $662.69 
           </p>
          <Link to={'https://amazon.com/Apple-Watch-Ultra-Cellular-Titanium/dp/B0C7PJTHVM/ref=sr_1_8?crid=6HO2Z9CCSD5A&dib=eyJ2IjoiMSJ9.QGvzsKxvR8ISwyKKGGhJM8uWM9IK3XISE5ysXr35C4s51tt93Qc5ONWnaAZa8wJlV-tqreevWve8qqW_Oxj74jxgZ9Qs-lpr4qRTi-6XwLOIwpILYkDLerfTM6ph7zP9ze1wmET3EoSAizZd_f3WMgh2jmB7bJdjV4TmK0WFS35r9hIc1eHix50jxFilmKcmoY1LABRz-7RTxpVnE2Gky0XOQHscPgn4NIuXn48DFW4oFu2PKIH1E-2g8KJCdCEOz1HB90EP2YRgZSvs1j-b1mTH6xUpZ_0liKtNcxZV44o.nf7Y6PwvjzrUact8O3QvdysWwl2Ic6YhqcxYtZyCCNw&dib_tag=se&keywords=apple+watch+ultra&qid=1715525475&s=electronics&sprefix=Apple+watch+ultra%2Celectronics%2C374&sr=1-8'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy Watch 6 44mm Bluetooth Smartwatch, Fitness Tracker, Personalized HR Zones, Advanced Sleep Coaching, Heart Monitor, BIA Sensor for Health Wellness Insights, Big Screen, US Version Silver
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $259.99 
          </p>
          <Link to={'https://www.amazon.com/SAMSUNG-Bluetooth-Smartwatch-Personalized-Advanced/dp/B0C7929YMK/ref=sr_1_1_sspa?crid=14DRMZ7NFN48Y&dib=eyJ2IjoiMSJ9.ZrG3yP_5zSMMyZxLDprzwYEPmlmAFSdOfwMWCUoi8ZC7jAYB8sF5Aic8lFqqb3Zh8WN33P3tcqVlJGPKDD0Y7K_SiTKoKPpqUhbAku1bLUK2I9WURRKhnYpxXG2NLPyCo3CcOqtI-ZrmpVAGu4Th7SY9c1ydH0xl_7YlFw__mSf5qkOogmj0yzKtc9RNoP06tTFWxB6NaTbOSfUTUR4BsWNwR8fZtrNTfFc1V9U60oLG1LqLlAprnnh-M0o3FhQxkiDFUbCLrmVa_jBxtxHpJPLVjwE2F8YbbkDVn8dGH8Y.eFUzC89MDXjSVcr7K1IiKRG7g_zRPedm0mAypI6LdjE&dib_tag=se&keywords=samsung%2Bwatch%2B6&qid=1715526315&s=electronics&sprefix=samsng%2Bwatch%2B6%2Celectronics%2C347&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 8 attend calls and reply to messages using the GPS model with the latest inbuilt sensor you can measure your level of blood oxygen With an in-built ECG, you can track your heart 5GHz Wi-Fi and U1 Ultra Wideband chip use fitness app on your iphone to see your daily activity trends Apple Watch Series 6 - 40 mm With Call Function
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $503.98 
          </p>
          <Link to={'https://www.flipkart.com/apple-watch-series-8-41mm-gps-ecg-app-temperature-sensor-ipx6-fall-crash-detection/p/itma616c5834b7bf?pid=SMWGHWZ2HDZUGX3J&lid=LSTSMWGHWZ2HDZUGX3J6MNKU6&marketplace=FLIPKART&q=apple+watch+series+8&store=ajy%2Fbuh&srno=s_1_5&otracker=search&otracker1=search&fm=Search&iid=2d719d9c-1caf-4c49-b21b-8232005a411a.SMWGHWZ2HDZUGX3J.SEARCH&ppt=sp&ppn=sp&qH=3e85ce42c20292f9'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Global Version Xiaomi Redmi Watch 4 Metal Middle Frame 1.97" AMOLED Display Heart Rate Sleep Analysis GNSS Phone Call </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $64.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Global-Version-Xiaomi-Redmi-Watch-4_1601111503857.html?spm=a2700.galleryofferlist.normal_offer.d_title.40764102CdoT0x'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Xiaomi Redmi Watch 4 1.97'' AMOLED Smartwatch Health GLOBAL VERSION By FedEx
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $139.79
          </p>
          <Link to={'https://www.ebay.com/itm/176281443313?itmmeta=01HXPZ0RNT068CRX9C6SC18B1E&hash=item290b315bf1:g:mP8AAOSw95Nl7suL&itmprp=enc%3AAQAJAAAA8L01RRYNAC7vdvP8xj8D%2FgjPbkvLL9DiAaV0oXNW%2BgFx6hPFoQwkNCGIoa4rX8KPMQ0LgH2YUof2xg9l6hveEl%2BwU0sEvK0XUbretUDNxb2vYMle3KZ48mb1nJQOOTRgV7MiFtNDOfUZ0wqrNsB6gKYxDMG29Lgfh9sSJUEOvfzBn4JsWWhoPKmFi9PnV9ndT6dUmdRTtmeXBVimZ9iIi7FjpwIxRh5MESGSUatR0KlNiMxxYxSOQw1qqQ4YRSPWQiNRr9pFL6DXiAoXStWDQjuoWHRIZ4GgxDdV9K9ER%2Fls3KfA69obeQHjKzmaKP8%2BEA%3D%3D%7Ctkp%3ABk9SR46Lg9_tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 9 [GPS + Cellular 41mm] Smartwatch with Starlight Aluminum Case with Starlight Sport Band S/M. Fitness Tracker, ECG Apps, Always-On Retina Display
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $399.00 
          </p>
          <Link to={'https://www.amazon.com/Apple-Cellular-Smartwatch-Starlight-Aluminum/dp/B0CSVB8K94/ref=sr_1_3?crid=2L9YFCGIH4ESB&dib=eyJ2IjoiMSJ9.czS167Lr6sRu4RbGPSio3SWWrp8N1t6jT3fG485-IPcAo1Cny8m6TQ3BNurnZ6l3RXGmNkPvnApsKiUQqNgGqtlrCv96UH6wvC2zahtWoy7KhtNXToUngnX3LxQE8j2CwbI14d5HS-FnWdX3DgacsQ3NEEl87-HTkiZaStZW0J9y5lFPCPWB8n2Jp4jMt01cVJXCb7yQX6LJ0TGv_mH-KEYyo47uJ_zPSd7ienkaTbUR5nO8naYmSL881Cidykt4zItCXWY9UuVkOeXKFDN-q62iqs1yIbSBDXvdXqQfjbM.1WlgoMKluACZs9ohPxgb3uRi1qTbo86QvkqGZOBE1lc&dib_tag=se&keywords=apple+series+9&qid=1715525753&s=electronics&sprefix=apple+ser%2Celectronics%2C1908&sr=1-3'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Samsung Galaxy Watch 6 44mm Graphite SM-R940 - Bluetooth - Wifi - GPS - RRP £319
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $212.50
          </p>
          <Link to={'https://www.ebay.com/itm/335332976651?epid=19061982568&itmmeta=01HXPY9DBRCRGFXX2R114CQ0TK&hash=item4e1367280b:g:HzYAAOSwUFxmDrqE&itmprp=enc%3AAQAJAAAA4Irs46z8v%2FwpMP5VW07r0CG99byxi7C84QTyxt3haHXA0Ai0OAMRi5CQQhqwNHFauLeC7D%2B40ZRmU2MazYnJ1EJ%2B%2F7ir0a5167%2BgQbZIIS8e6ZkZGpUItqTILuHIc4V5%2FX5ECx74YaayPsVrorc2Lw9EYy3SGgPCLqNAb380CUYRSm8n0TdEA%2F7RfI9ztYxjJD4qdS94%2Fk2SzpaogyFGKXceUMWOzv2xzP2PtWEIdfUbgFNxXTM31JQhbWDO9134knkJMpRMd3BckORLjUZoyIKzV1DLpGtIaspW4wH8Vf%2BF%7Ctkp%3ABFBMhtal3u1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy Watch 6 44mm Aluminum Bluetooth Smartwatch | Latin American Version | Sapphire Crystal | IP68 -
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $185.99 
          </p>
          <Link to={'https://www.amazon.com/Aluminum-Smartwatch-Advanced-Coaching-Bluetooth/dp/B0CCW65ZZ1/ref=sr_1_8?crid=14DRMZ7NFN48Y&dib=eyJ2IjoiMSJ9.ZrG3yP_5zSMMyZxLDprzwYEPmlmAFSdOfwMWCUoi8ZC7jAYB8sF5Aic8lFqqb3Zh8WN33P3tcqVlJGPKDD0Y7K_SiTKoKPpqUhbAku1bLUK2I9WURRKhnYpxXG2NLPyCo3CcOqtI-ZrmpVAGu4Th7SY9c1ydH0xl_7YlFw__mSf5qkOogmj0yzKtc9RNoP06tTFWxB6NaTbOSfUTUR4BsWNwR8fZtrNTfFc1V9U60oLG1LqLlAprnnh-M0o3FhQxkiDFUbCLrmVa_jBxtxHpJPLVjwE2F8YbbkDVn8dGH8Y.eFUzC89MDXjSVcr7K1IiKRG7g_zRPedm0mAypI6LdjE&dib_tag=se&keywords=samsung+watch+6&qid=1715526315&s=electronics&sprefix=samsng+watch+6%2Celectronics%2C347&sr=1-8'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Xiaomi redmi Smart Watch 3 2024 GPS waterproof Scale Fitness Tracking bluetooth phone call Chinese smartwatch for note 12 13 pro
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $58.99
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Xiaomi-redmi-Smart-Watch-3-2024_1601031135268.html?spm=a2700.galleryofferlist.normal_offer.d_title.54a4679d6pEEpa'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 9 "Powerful sensors, Advanced health features. With Call Function Touchscreen Fitness & Outdoor Battery Runtime: Upto 18 hrs"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $502.88 
          </p>
          <Link to={'https://www.flipkart.com/apple-watch-series-9-gps-41mm-silver-aluminium-case-storm-blue-sport-band-s-m/p/itm262478e77a2c5?pid=SMWGTC2YXA4ZRZHR&lid=LSTSMWGTC2YXA4ZRZHR6RXFBX&marketplace=FLIPKART&q=apple+watch+series+9&store=ajy%2Fbuh&srno=s_1_3&otracker=AS_QueryStore_OrganicAutoSuggest_1_12_na_na_ps&otracker1=AS_QueryStore_OrganicAutoSuggest_1_12_na_na_ps&fm=search-autosuggest&iid=eb3e53ae-57a2-4921-8860-77807669e5eb.SMWGTC2YXA4ZRZHR.SEARCH&ppt=sp&ppn=sp&ssid=r1ozi3csw00000001715433745672&qH=1cf7dc020470829b'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 9 - GPS + GSM Cellular 41mm Smart Watch - Excellent
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $279.99
          </p>
          <Link to={'https://www.ebay.com/itm/186156628205?itmmeta=01HXPXJNZNDV21D0A2Z7AR2YBE&hash=item2b57ccb8ed:g:kzIAAOSw8RdlS9iD&itmprp=enc%3AAQAJAAAA4BYM0%2BWKmpZVodAczkfFeWK2WK5VCD3JhpaqHU5eblPcMQEAhl9FoTkwUW1syjQA5D%2FBN7383zfSshHp9x1JcwnRArKplbe74%2FR7P8wRtVq%2Fp4qE%2Fygxz1vYYCImihi1ZXyPSBAVt5%2BFJos95fF5gymkTobjRKLkZGe0gMWysNi75G2G%2FNpCplEn1N7SxBI6lqpCqwOPBGW9iJgkm4KRcXdujTD1Y11usEj%2BY8OtE4XXZJBGga06aDJmBLIdaLtiVZrkN4QZIgfIM4J7uW29HQfNngKeyPQWUo4DD%2Bm4L84E%7Ctkp%3ABFBMgODK3e1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 8 [GPS + Cellular, 45mm] - Midnight Aluminum Case with Midnight Sport Band, M/L (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $246.99 
          </p>
          <Link to={'https://www.amazon.com/Apple-Watch-GPS-Cellular-45mm/dp/B0BNFGKSNF/ref=sr_1_1?crid=1XI6FB0O7CG1U&dib=eyJ2IjoiMSJ9.iM2-qTeJyk3j6w-LSfepRrMAyoFBQwQm7xkvtyMuICvFeipslciRwlqzYDTn--VC10PF2MXPshGOxX8nnZJHUJdK0c524hDNFtgsFX_4mk5agFm8CnDc9u0EHYQ0A0hGEI-CLoPM3_kwnkv_hZ9OkY9QudrbM89SaJBl6MbMuQPEx97c_zCFcmXBnUT5LUx6DF-7yQNrDVgoyzG4vpa3XnHVdM8n4K-NyFKzb7EtQfqBWho4Bb2Df1WmIW8wCUWnHIJK8Hnfo9DcXQblvg-TC9wM5zx16XGu61crIGhzsDg.OkQDy4a4W7N2fpMdHLc9VU8QdgPnbSnva7Ud9PmlZeY&dib_tag=se&keywords=apple%2Bseries%2B8&qid=1715525933&s=electronics&sprefix=apple%2Bwatch%2Bseries%2B8%2Celectronics%2C330&sr=1-1&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Second Hand Wholesale Used Watch Series 8 41mm 45mm S7 S8 Gps Cellular For Apple Watch Cheap Price Unlocked Original Like New
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $90.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Second-Hand-Wholesale-Used-Watch-Series_1601014897093.html?spm=a2700.galleryofferlist.normal_offer.d_title.20316cc8cfg2ft'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Xiaomi Redmi Watch 4 Global Version Redmi 1.97-inch Extra Large Display
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $125.24
          </p>
          <Link to={'https://www.ebay.com/itm/145743960967?itmmeta=01HXPZ0RNTYZWQSC8E9PKK8J4Q&hash=item21ef045b87:g:bLsAAOSwNJxmKmZG&itmprp=enc%3AAQAJAAAA0BCv28fmkMgJWNeUkIu%2Bikd0sviHYDTZeO%2FUfZ8yrlMeSIf3kvkgSemMJyhG4OM%2FHSVYqgkZm7887z34zvGZO8hzm6rH45L5ESMVMdcUz8gR18ThLVV8eIJC9JyV%2FQInwQBGADMakAeicVnnNecMYoRBaxyq0hNQ9%2B1rL4LCepLMhbTS1hWQa7uW6B3pWQaqHr6tQgdsR2q%2FiyldgOtmORExxFmLfktDAOPzQd69L1NI0r%2BvZWZC69ozFbOIMN6QNQM78e4tQ4vSd2kGY2qFjjo%3D%7Ctkp%3ABk9SR46Lg9_tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy Watch 4 Bluetooth & GPS Smartwatch, 40mm - Black
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $102.90 
          </p>
          <Link to={'https://www.amazon.com/SAMSUNG-Smartwatch-Bluetooth-International-Version/dp/B09D36L36T/ref=sr_1_9?dib=eyJ2IjoiMSJ9.1ldL8YqlH4_0HIO3r0B0VLXsRYw4ZfEqyawWMGvbMC8BajumukyCCHOBt2YrxwJC_lT4CV7h-A9Zbmjohq9Ljgs56WuNWRcFb3m2kJIO7IbyKvu-h9L-lcMwfjWQNesdT0y1x4vFNH5QgnYbAVgAJ3TREwy7kgOw6gd7NgxiXasSGoB0zjmC7U8Ou4BxMI4Xt2IX_LPF2hEalzUn2YmWlPWHCBfTLQdW1twU9N9ehELxWwu9vQy8UJ62Kzv_8wHhxjT0bqGdNvtK6mi9ua9jaKbgrKRhJI_6qVHF87LsvjA.2WlrZGbICuulPFlnCrcGICybhiWHaevCx7leZteEMnE&dib_tag=se&keywords=samsung+watch+4&qid=1715526538&s=electronics&sr=1-9'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 9 "Powerful sensors, Advanced health features. With Call Function Touchscreen Fitness & Outdoor Battery Runtime: Upto 18 hrs"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $658.80 
          </p>
          <Link to={'https://www.flipkart.com/apple-watch-series-9-gps-cellular-45mm-starlight-case-sport-loop/p/itmf9aedd859a89c?pid=SMWGTC25HEZ8DY7C&lid=LSTSMWGTC25HEZ8DY7C7ZWOE6&marketplace=FLIPKART&q=apple+watch+series+9&store=ajy%2Fbuh&srno=s_1_6&otracker=AS_QueryStore_OrganicAutoSuggest_1_12_na_na_ps&otracker1=AS_QueryStore_OrganicAutoSuggest_1_12_na_na_ps&fm=search-autosuggest&iid=eb3e53ae-57a2-4921-8860-77807669e5eb.SMWGTC25HEZ8DY7C.SEARCH&ppt=sp&ppn=sp&qH=1cf7dc020470829b'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Second-hand 24 latest 100% original watches series 9 intelligent lock-free waterproof watches 41mm 45mm.
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $45.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Second-hand-24-latest-100-original_1601107109939.html?spm=a2700.galleryofferlist.normal_offer.d_title.5ea82e07S6l0K6'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Samsung Galaxy Watch 6 SM-R930 40mm Aluminium Case - Graphite UK
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $150.08
          </p>
          <Link to={'https://www.ebay.com/itm/266806658445?epid=28061987567&itmmeta=01HXPY9DBRYBXZ0J7C78EMK3MM&hash=item3e1eea858d:g:83oAAOSwYxxmPkBK&itmprp=enc%3AAQAJAAAAwCuOR5lWuLBoQ7bI%2FNIT4lYEZfdQrS5wWM8SSAvvtvfXVUTwY%2BIJcVwBkyMYEk7cMZyIoIvGEfeY8AidcP76mB97kndeWkI%2BrKs8c52k9TEzOLJf4vLcaH1eU1lFHVg%2FVe2hhLFR4a%2FWTY17bCFtd2d2%2Bxp9omyjVkPNkKW7tw6UdwT4zLRY0zGPxgVZzyjvegXIo5mCnnE6wn%2BRctnod%2Fr0a%2Bf%2FAzzJN%2FsOxiP7Ix8joYkp8BhlCnNpzfzTJcnoDw%3D%3D%7Ctkp%3ABk9SR4jWpd7tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Ultra 2 [GPS + Cellular 49mm] Smartwatch with Rugged Titanium Case & Blue Alpine Loop Large. Fitness Tracker, Precision GPS, Action Button, Extra-Long Battery Life, Carbon Neutral
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $749.00 
          </p>
          <Link to={'https://www.amazon.com/Apple-Cellular-Smartwatch-Precision-Extra-Long/dp/B0CSVG1668/ref=sr_1_1_sspa?crid=NA07QSDEETDE&dib=eyJ2IjoiMSJ9.AYRgbJhDpVQ9a5ZhIvu-Cx95NCiL0wTejm4EQIt1uD7mpd8866UdkQFqsx6JWbqcf1s0uLvD6rOE2_f9FUQ6_mlEfIsrKNUkhY4ZgVNYTR-IlYD0tqx1FEDXcpKj_evysCyge-otwCm9YlvUHLWnRunJkN_VUOw5vC3NcYZx2fF-eBd-wpVZHxAvAACulw4Xmhb9J9YC-wVtKVQUr9GAoKv16AzyeipLduCRir8MH6M.PzSCBfHCUaXuGE5zLb0jsqdpeATChRySetG7LVLwVWk&dib_tag=se&keywords=apple+watch+ultra+2&qid=1715527332&sprefix=apple+watch+ultr%2Caps%2C1912&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Global Version Xiaomi Redmi Watch 3 Active Smartwatch BT Phone Call Blood Oxygen Monitor 1.83'' LCD Screen 12 Days Battery Life
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $33.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Global-Version-Xiaomi-Redmi-Watch-3_1600907337920.html?spm=a2700.galleryofferlist.normal_offer.d_title.54a4679d6pEEpa'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Ultra 2 2nd Generation GPS & Cellular 49mm - Titanium - Excellent
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $594.99
          </p>
          <Link to={'https://www.ebay.com/itm/186153928007?itmmeta=01HXPX8PRKAEV6ABZFZVQNNP6S&hash=item2b57a38547:g:0QcAAOSwd-5lXkMi&itmprp=enc%3AAQAJAAAAwM1qI1y0Qc04vd2lgB4gzG%2BqVuQ2977wBhwKd%2FZXcdEwztxayEHQfO6HCPvD7Rkwa1JH8oE15FhJuq%2Fe3LRBAynSRR14%2BdVGBC4uFtlpSx1uoiwGIwdZ17xPEHB%2BglyYSk2iEkJAFWhwiZ70ecsIr%2BnsWuuf49AL83x8vNUtgNdeTvkIeFhVF6t90osYcyPoGtiFlxe4SGylrxug801qq3sFn%2F4AHjZXcI2YhmKYsBBqpplz23nkpJuY4t4AFg3XIA%3D%3D%7Ctkp%3ABk9SR8Dsot3tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch ULTRA 2 "The most rugged and capable. With Call Function Touchscreen Fitness & Outdoor Battery Runtime: Upto 36 hrs"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,079.88 
          </p>
          <Link to={'https://www.flipkart.com/apple-watch-ultra-2-gps-cellular-49mm-titanium-case-blue-alpine-loop-large/p/itma523f0599d952?pid=SMWGTC2AZCNYGHGE&lid=LSTSMWGTC2AZCNYGHGEL3YZTB&marketplace=FLIPKART&q=apple+watch+ultra&store=ajy%2Fbuh&srno=s_1_4&otracker=AS_QueryStore_OrganicAutoSuggest_2_12_na_na_na&otracker1=AS_QueryStore_OrganicAutoSuggest_2_12_na_na_na&fm=search-autosuggest&iid=bbcd43f1-2433-4c51-a26d-6634e05d641a.SMWGTC2AZCNYGHGE.SEARCH&ppt=sp&ppn=sp&ssid=ny2j1opxwg0000001715433637182&qH=829f4fb3b4866f34'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Customization Waterproof Hot Selling Ultra Series 7 i7 x7 i8 Pro Max Smart Watch Apple For Offered By Manufacturer
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $25.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Customization-Waterproof-Hot-Selling-Ultra-Series_1600865317449.html?spm=a2700.galleryofferlist.normal_offer.d_title.5f3d187fDEpmsH'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Samsung Galaxy Watch5 Pro SM-R920 45mm Titanium Case GPS + WiFi - Very Good
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $109.99
          </p>
          <Link to={'https://www.ebay.com/itm/315186192382?epid=28055663054&itmmeta=01HXPYEBQHEKQJNJQD2AKWTS7H&hash=item49628f9ffe:g:ewwAAOSwoehjywOB&itmprp=enc%3AAQAJAAAAwOm6tpRjgzaMwevdKuohZauDhckXj6kxQOeKFJTQaXHGbF7esdfyOZ0mSIXF5j0DELQLK%2FpIxWlHiu%2F3VoRiBvzQ7AhC%2F8YMDmffXkyUyNXLtPbfPrbQ3xtti1QQo0TPpxlScoHN3ThY%2FtbttBl21J2TDG4wuQaX1B%2BnKkd9oDBc%2FO3GcFvotR2PaLaZfKSqun5j46u6NX1utwykQaVxMuOkb1HAeoQmFVS7hWzhSU0h0fHmXnfKytADhugdQS%2BCJQ%3D%3D%7Ctkp%3ABk9SR_y7ud7tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 8 [GPS, 45mm] - Midnight Aluminum Case with Midnight Sport Band, M/L (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $254.43 
          </p>
          <Link to={'https://www.amazon.com/Apple-Watch-GPS-45mm-Midnight/dp/B0BS4D5C8D/ref=sr_1_4?crid=1XI6FB0O7CG1U&dib=eyJ2IjoiMSJ9.iM2-qTeJyk3j6w-LSfepRrMAyoFBQwQm7xkvtyMuICvFeipslciRwlqzYDTn--VC10PF2MXPshGOxX8nnZJHUJdK0c524hDNFtgsFX_4mk5agFm8CnDc9u0EHYQ0A0hGEI-CLoPM3_kwnkv_hZ9OkY9QudrbM89SaJBl6MbMuQPEx97c_zCFcmXBnUT5LUx6DF-7yQNrDVgoyzG4vpa3XnHVdM8n4K-NyFKzb7EtQfqBWho4Bb2Df1WmIW8wCUWnHIJK8Hnfo9DcXQblvg-TC9wM5zx16XGu61crIGhzsDg.OkQDy4a4W7N2fpMdHLc9VU8QdgPnbSnva7Ud9PmlZeY&dib_tag=se&keywords=apple+series+8&qid=1715525933&s=electronics&sprefix=apple+watch+series+8%2Celectronics%2C330&sr=1-4'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Global Version Xiaomi Redmi Watch 3 Active Smartwatch BT Phone Call Blood Oxygen Monitor 1.83'' LCD Screen 12 Days Battery Life
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $27.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Global-Version-Xiaomi-Redmi-Watch-3_1600070882799.html?spm=a2700.galleryofferlist.normal_offer.d_title.54a4679d6pEEpa'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 9 41mm GPS + WiFi + Cellular Unlocked Aluminum Case Excellent</h2>
          <p className=" text-gray-500 mt-1 p_price">
          $279.99
          </p>
          <Link to={'https://www.ebay.com/itm/196268031720?itmmeta=01HXPXJNZN3X4NR70ZSE5PQ040&hash=item2db27c7ee8:g:1nQAAOSwN8Rl1fSk&itmprp=enc%3AAQAJAAAA4Ee78Gl2pfy1Vw0O3aqhR6JOZAeR%2FMGJco9Dsm927CCjpiQghOFmUOAG703Vz72QD2BFcFy3p4nLTSU6eho1tpFYh%2FaQ9gQ5MJlrIYp%2FR9dA%2BskXdzMlwQq1vIbMSp%2FUgR9jPNAyVCNpwxx43yXqnI33jkMZhr5stMLi0wvdLR1exa%2F4s%2Bt7js3BgVsuYr%2FFKJWFcv%2Fxtr2ot5aOopoCALvtcg%2B9II3R7uF%2FjpYTaRpIQjQtFrELiZUz7IHgNgvcblJxPPF%2FUqJKE4kc9bZIX3frxRwi9wp2jLHpPKYXwIEE%7Ctkp%3ABFBMgODK3e1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Galaxy Watch 5 44mm Bluetooth Smartwatch w/Body, Health, Fitness and Sleep Tracker, Improved Battery, Sapphire Crystal Glass, Enhanced GPS Tracking, US Version, Gray (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $126.34 
          </p>
          <Link to={'https://www.amazon.com/SAMSUNG-Galaxy-44mm-Smartwatch-GPS/dp/B0BWNZMCR5/ref=sr_1_4?dib=eyJ2IjoiMSJ9.aFsvx3JpzsbcYKn1-CSXsxodMl-wvb0OQRlYHrVThv0Ce8rz7xJMCf7rkXza5NI5nO08ZvNj7BRnIA35jkjUorlxUNSm6QBLCJGYRpxODUFA_U4_GaZnnxbsoMBDfXPTohsZKNLUXmvNnQzTatP3C2dHflmTK12V7-Z8KzTCYORHYXwhhI4gMFd5kYny2n6ck1oVlUcQdL4XglZyVjAARGy5f8WOaidIbuR0MotHvoAZitZWRHsgcR4PWKzz5NmVOM2Ck6ebO05l5LaXOct36El9YpnUlHwR5P1t35CpB4Y.allRuffX1ecnnEBOoJxAB4d__wyzu961ZkSG_OtudTA&dib_tag=se&keywords=samsung+watch+5&qid=1715526797&s=electronics&sr=1-4'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Hello watch Galaxy Watch 6 S6 SmartWatch 1.39 Inch Men NFC Smart watches wholesale Wireless Charging Bluetooth Call 2024
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $24.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Hello-watch-Galaxy-Watch-6-S6_1601000956170.html?spm=a2700.galleryofferlist.normal_offer.d_title.552d64c5zF6yFo  '} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 7 45mm Graphite Stainless Steel Case with Abyss Blue Sport
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $139.99
          </p>
          <Link to={'https://www.ebay.com/itm/355708523817?epid=22050856676&itmmeta=01HXPXZV0QJPWWF4WK3CJRN3JB&hash=item52d1e15529:g:1-MAAOSwoA9mQLU6&itmprp=enc%3AAQAJAAAA8GIP5lOiFnYhvdzzS6ZSXocxQ%2Bw6NKs2Gv11ljBus%2BG%2F29kPygy2CP60PLJGIbr7krTXmmI3LmWnzo7YnDG4BmGBFqzqy9z%2F8AlqEFW9PD0GQdniluNrvSKnX6dAaE5H41M4PEp4k28NrFMA7E4gv57MMXkLc6yOF59QiPjzhhhhypwNlRskbwrX79LXDAvDMjo1XL0%2FDIiPm1UY43uWNrymvv6LQEz0aUnJokjFv9U6OAdegDtwmeEYrJndiRPm27yz3WuVEDV9oGfPg74He6eB9ScAGeVUSg4TtzKjwG2A1VqKtp9A5UMcqepk%2BKsWoQ%3D%3D%7Ctkp%3ABk9SR8Kw_93tYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 7 GPS + Cellular, 45mm Midnight Aluminum Case with Midnight Sport Band - Regular (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $198.00 
          </p>
          <Link to={'https://www.amazon.com/Apple-Watch-Cellular-Midnight-Aluminum/dp/B09PQ7C8Z3/ref=sr_1_1?crid=2T6B9C6TLNTHU&dib=eyJ2IjoiMSJ9.kOBfKvQwsnc5WjhzdE9l0LKCMRVSSrHQkqvFNFTovPlWHLdZCk_CSus2e6kUlJ-ZVK0xf60Kl4oNsPhcFlSq2MVqETl3OH3kAVeQ_ec7fCV3QxhTWkFOEjCmoC6cw_A7837hGdCjUGUAjGXBBqNiC2gnpTGDHX-LL9slkb3nNAlETwCx0Grbr2FfHq0NZ1YuPnVEzN13zGFSEck6T4HmHkU5bs-XNv9H8rlr0CeQNOU.1mmQk0QxW8F9SVhYiX1srw8wF1jC8GKqynyQR_UtBTg&dib_tag=se&keywords=apple%2Bseries%2B7&qid=1715527589&sprefix=apple%2Bseries%2B%2Caps%2C486&sr=8-1&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>





        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Original for Apple Watch Series 8 7 6 5 4 3 2 1 lcd display Smart Watch s1 s2 s3 s4 s5 s6 s7 s8 se Lcd Display Screen
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $22.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Original-for-Apple-Watch-Series-8_1600874134304.html?spm=a2700.galleryofferlist.normal_offer.d_title.5f3d187fDEpmsH'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          SAMSUNG Samsung Galaxy Watch 5 44mm (GPS) SM-R910N Aluminum Case
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $124.99 
          </p>
          <Link to={'https://www.amazon.com/SAMSUNG-Galaxy-GPS-SM-R910N-Aluminum/dp/B0CGH5KC9P/ref=sr_1_7?dib=eyJ2IjoiMSJ9.aFsvx3JpzsbcYKn1-CSXsxodMl-wvb0OQRlYHrVThv0Ce8rz7xJMCf7rkXza5NI5nO08ZvNj7BRnIA35jkjUorlxUNSm6QBLCJGYRpxODUFA_U4_GaZnnxbsoMBDfXPTohsZKNLUXmvNnQzTatP3C2dHflmTK12V7-Z8KzTCYORHYXwhhI4gMFd5kYny2n6ck1oVlUcQdL4XglZyVjAARGy5f8WOaidIbuR0MotHvoAZitZWRHsgcR4PWKzz5NmVOM2Ck6ebO05l5LaXOct36El9YpnUlHwR5P1t35CpB4Y.allRuffX1ecnnEBOoJxAB4d__wyzu961ZkSG_OtudTA&dib_tag=se&keywords=samsung+watch+5&qid=1715526797&s=electronics&sr=1-7'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          New trend watch 5 smart watch round BT call wireless charger waterproof sport fitness watches for Samsung watch5 smartwatch
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $17.10
          </p>
          <Link to={'https://www.alibaba.com/product-detail/New-trend-watch-5-smart-watch_1600813245457.html?spm=a2700.galleryofferlist.normal_offer.d_title.6e99ba3bSN8bi7'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Watch Series 6 (GPS + Cellular, 44mm) - Space Gray Aluminum Case with Black Sport Band (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $176.00 
          </p>
          <Link to={'https://www.amazon.com/Apple-Watch-GPS-Cellular-44mm-Aluminum/dp/B08L5HKWFX/ref=sr_1_2?crid=2V90P65FI4YY2&dib=eyJ2IjoiMSJ9.Y7sINLet04SEZRNw3379jiit9gRhQxWwls2Y3pggrgaErdiHQ5rO12t_aoj66psWSO0lLUqllURoZCby_MgGCqNVikBXZROyVeXh__BEqdvH9Ke6mjhFR-135bYUl4pyU12j9VrYIR--gXzpwiQWd2JWiirDJfzyqrR_uTMfpg_HjTmF2QdeCpPHGl0rdaJaHYJfl0ETG-7L5kNb3wO3vynYXoAeEa8D8LA9GG-sNGh6D67UdOONrVh1FoVSNEOO-onUtRujqow5Idp5Dp3UdtXfgRXMX_BMRu6SI8Vswg0.h3ByTXPJDCOc8SK29hw-8DYnpKCnPXge7OsRnMWuk7M&dib_tag=se&keywords=apple+series+6&qid=1715526179&s=electronics&sprefix=apple+series+%2Celectronics%2C357&sr=1-2'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        </div>

        <Footer/>
    </div>
  );

  //  <SubCategoryComponent productHeader={"Smart Watch"} categoryId={4} />;
};

export default SmartWatch;
