import Header from "../../../components/Header";
import Footer from "../../../DashboardCustomer/Components/Vendorcomponent/Footer";
import SubCategoryComponent from "./SubCategoryComponent";
import { Link } from "react-router-dom";
import '../../../assets/css/style.css';

const Laptops = () => {
  return(
    <div>
      <Header/>
    {/* <SubCategoryComponent productHeader={"Mobile Phones"} categoryId={3} /> */}

      <div className="w-100 bg-sky-100 pb-8">
        <h2 className="c_title text-gray-600 body-font py-4 mx-4">Laptops</h2>
       <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-75 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          15-inch MacBooks Air M2 8-core CPU 10-core GPU 24GB Ram 512GB SSD
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,000.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/15-inch-MacBooks-Air-M2-8_1600963544845.html?spm=a2700.galleryofferlist.normal_offer.d_title.6c2370c42gKvOh'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Omen 16 "16.1 inch Full HD, 165 Hz, 7 ms response time, IPS, micro-edge, anti-glare, Low Blue Light, Brightness 300 nits, Color Gamut 100% sRGB Light Laptop without Optical Disk Drive Preloaded with MS Office"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,487.98 
          </p>
          <Link to={'https://www.flipkart.com/hp-omen-arm-ryzen-7-octa-core-16-gb-512-gb-ssd-windows-11-home-8-graphics-nvidia-geforce-rtx-4060-16-xf0059ax-gaming-laptop/p/itmbe1c21e28741a?pid=COMGXVU3KGFBYEG3&lid=LSTCOMGXVU3KGFBYEG38CIYJE&marketplace=FLIPKART&q=HP+OMEN16&store=search.flipkart.com&srno=s_1_10&otracker=search&otracker1=search&fm=Search&iid=1114b541-e123-46a7-bef1-a05905421acf.COMGXVU3KGFBYEG3.SEARCH&ppt=sp&ppn=sp&ssid=ki5r5hyzww0000001715429598351&qH=c6b892f68819d56c'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple MacBook Air Mly33ll/a 2022 - MS3-30w - M2 8GB 13.6" 256GB SSD - Very Good
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $749.00
          </p>
          <Link to={'https://www.ebay.com/itm/145715527105?itmmeta=01HXPRP1MVPWWNP2ACA1BD5BYG&hash=item21ed527dc1:g:sSwAAOSw1kpmFZ0p&itmprp=enc%3AAQAJAAAA4J9MuZa%2FX7h59xeXz5Da3SyjvnTZd0pvFnVGOARIYwK7PmpIh0SUlgsddRpdCp2rKKpOZMgLwTMzKYSXZiE1iqIA0OeM%2F6y%2Fokd7Np0TFVorUvi1XQt57jKdf1YuYvLPnSiXdLTTlFp66kjJIuJKeNUpqOoiCwNgrECUAvG1u1S3bztIRKSJpM8Ml%2B8MkLwzUyQSDYj9pIaSYpHD0C3WRO6%2B7%2BebONVJ0t2sjlgNjAHzh3rPsez1dDEaMBs7W77tClkwU6awPGj7NXFZJ7w08Wk3zUvNBuNv8M2XS51GuwUM%7Ctkp%3ABFBM1JrY2O1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP OMEN 16 Gaming Laptop Computer, 16" 165Hz 100% sRGB Full HD Gaming Laptop, AMD Ryzen 9-7940HS(Up to 5.2GHz), 16GB DDR5 RAM, 512GB SSD, NVIDIA GeForce RTX 4070, Wi-Fi 6E, Windows 11 Home, With Stand
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,479.99 
          </p>
          <Link to={'https://www.amazon.com/HP-OMEN-Computer-9-7940HS-GeForce/dp/B0CTY55Y93/ref=sr_1_14?crid=DEFO7X3MKT0A&dib=eyJ2IjoiMSJ9.24Y0VNuzlMnxJLqoQ0yjAVZF416Hv-SVgl4NtFHGcs2ZSMv4Dqd82iHoZLZlp4KOwAJ5hwSyyzkbtGQf9FghZ0g0y0btSKHD4ZsiaMQuCF3ILwGapKUi5YwlC6OnWPgQHdJdENgcZT8vjEPjTrckFcotFFVLkXK6mijh3ASxiu2E20zlV3gjCMjXvbb4nddUD6Ttd0Z7Vrj8Qs1cY3yzW8IYVCqwfNwMbqUH304osnd0DwZ-hpT3bnwFgzM0GDsE3OVxTf-ocnsRsOpV1kDUho_e6Ts1BAVf1tCwqtvHpyQ.tEb1gxCl5l02p_oAAbUVE82VsClB4jISAOVKbcBFsIk&dib_tag=se&keywords=hp%2Bomen%2B16&qid=1715448707&s=electronics&sprefix=hp%2Bomen%2B16%2Celectronics%2C392&sr=1-14&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Omen 16 " 17.3 Inch QHD,165 Hz, 3 ms response time, IPS, micro-edge, anti-glare, Low Blue Light, Brightness: 300 nits, 170 ppi, Color Gamut: 100% sRGB"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $4,186.26 
          </p>
          <Link to={'https://www.flipkart.com/hp-omen-intel-core-i9-12th-gen-12900hx-32-gb-2-tb-ssd-windows-11-home-16-gb-graphics-nvidia-geforce-rtx-3080ti-17-ck1023tx-gaming-laptop/p/itmc5d16bceaa68f?pid=COMGHGYY6XBGSJVA&lid=LSTCOMGHGYY6XBGSJVAN1QMOW&marketplace=FLIPKART&q=HP+OMEN16&store=search.flipkart.com&srno=s_1_9&otracker=search&otracker1=search&fm=Search&iid=1114b541-e123-46a7-bef1-a05905421acf.COMGHGYY6XBGSJVA.SEARCH&ppt=sp&ppn=sp&ssid=ki5r5hyzww0000001715429598351&qH=c6b892f68819d56c'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Hot Selling Macbook Air M2 Education Version 13.6 Inch Screen Portable Laptop Macbook Netbook For Apple
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $999.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Hot-Selling-Macbook-Air-M2-Education_1601042145111.html?spm=a2700.galleryofferlist.normal_offer.d_title.6c2370c42gKvOh'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Macbook Air MLY33LL/A 2022 13.6 8CPU/8GPU  M2 Chip 8GB/256GB SSD Excellent
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $789.00
          </p>
          <Link to={'https://www.ebay.com/itm/166206685749?itmmeta=01HXPRP1MV7CWX9XCVG72F8625&hash=item26b2b0c235:g:uV8AAOSwhSFmHZuu&itmprp=enc%3AAQAJAAAA4Ff3PZtwFNXkE3ywU5c5C7c7TcWBKe%2FOxHaSEWin5gDSLPQoG2rqrdeniEc%2F9Q1bHeUnc7pvCKgqSCaI2esW945MH3U5p1Q5zZSQpcOLzyU36RITOBEIFtnweTcoOtXHnpG0WKNt%2BuZt6zfRHqdJzqbHfMPs4fo471fvBEz9oofdqqY%2BmfokMtdYXFrE2ym3BnHyiKJ%2BxBRrYZbMwwHDHsQsQbYj58C%2BBTqIBWjqUZmDwKRP5iSPtteqUpWCglDne2wvX08N%2BQHqqqRXJQFQoV3v9vfyOETXhzYMHswa%2FF67%7Ctkp%3ABk9SR9Sa2NjtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          2022 MacBooks Air M2/16GB RAM/8-Core+10-Core/1.25TB SSD/Multiport Hub/Office
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $698.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/2022-MacBooks-Air-M2-16GB-RAM_1601011730468.html?spm=a2700.galleryofferlist.normal_offer.d_title.6c2370c42gKvOh'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          DELL XPS 13 "Thin and Light Laptop 13.3 inch Full HD LED Backlit InfinityEdge Display"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,240.24 
          </p>
          <Link to={'https://www.flipkart.com/dell-xps-13-intel-core-i7-8th-gen-8550u-16-gb-512-gb-ssd-windows-10-home-9370-thin-light-laptop/p/itm84f7344355f99?pid=COMF7AQAY7FVCX5Z&lid=LSTCOMF7AQAY7FVCX5ZXCACBN&marketplace=FLIPKART&q=DELL+XPS+13&store=6bo%2Fb5g&srno=s_1_21&otracker=search&otracker1=search&fm=Search&iid=44f97f10-0e0c-417a-9a67-b7b04f916a4b.COMF7AQAY7FVCX5Z.SEARCH&ppt=sp&ppn=sp&ssid=rr9q3aa2ps0000001715429964753&qH=9ae262a3c6a55af2'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Envy 16" 2.8K OLED Touch Laptop Intel Core i7-13700H 16GB 512GB SSD 4GB A370M
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $899.49
          </p>
          <Link to={'https://www.ebay.com/itm/276390911780?itmmeta=01HXPSGRACR34B5N2SEGAGBX9B&hash=item405a2e9f24:g:ey4AAOSwEOtj~9PJ&itmprp=enc%3AAQAJAAAA4C85PotVQZJ8nIu4oJxO6RQkXiHyB1huOkYDpA0JrX%2BZO2FnPG9rXGcLBX%2BJgF%2BsKpOVx3BfGAkpYm9QvF3dZteLCxxlY5V6K4y83fBsNrYdWJYW7n2Lsb7uJcGksM2jJow2hCwygqfMkMtybxiEt3lVsDjubXWzIE5Zy5nj%2FeWBZVX%2BHCqQ0APB%2FWoZBDiYBQikLkGLXRX71AbJha6AcR2sSwkBOUKG7AP%2B7%2FzzP2hpsYZqSYM2UREpHJL%2Fe5eIy8ngdsKQXNc5ELqyZ3qZOLD5zs2jYfK1pMbr236JiKGX%7Ctkp%3ABFBMtoXD2e1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP OMEN 16 (2024) Gaming Laptop (16.1" FHD 165Hz, Intel 13th Gen Core i7-13700HX, 64G DDR5 RAM, 2TB PCle SSD, Geforce RTX 4060 8GB), Backlit KB, Thunderbolt 4, WiFi 6E, IST Cable, Win11 Home, Black
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,599.99 
          </p>
          <Link to={'https://www.amazon.com/HP-OMEN-16-i7-13700HX-Thunderbolt/dp/B0CRD6HGFF/ref=sr_1_3?crid=DEFO7X3MKT0A&dib=eyJ2IjoiMSJ9.24Y0VNuzlMnxJLqoQ0yjAVZF416Hv-SVgl4NtFHGcs2ZSMv4Dqd82iHoZLZlp4KOwAJ5hwSyyzkbtGQf9FghZ0g0y0btSKHD4ZsiaMQuCF3ILwGapKUi5YwlC6OnWPgQHdJdENgcZT8vjEPjTrckFcotFFVLkXK6mijh3ASxiu2E20zlV3gjCMjXvbb4nddUD6Ttd0Z7Vrj8Qs1cY3yzW8IYVCqwfNwMbqUH304osnd0DwZ-hpT3bnwFgzM0GDsE3OVxTf-ocnsRsOpV1kDUho_e6Ts1BAVf1tCwqtvHpyQ.tEb1gxCl5l02p_oAAbUVE82VsClB4jISAOVKbcBFsIk&dib_tag=se&keywords=hp%2Bomen%2B16&qid=1715448707&s=electronics&sprefix=hp%2Bomen%2B16%2Celectronics%2C392&sr=1-3&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Omen 16 Gaming Laptop | 16.1" FHD 165Hz 100% sRGB | AMD Zen4 8-core Ryzen 9 7940HS (i9-12900H) | 32GB DDR5 2TB SSD | GeForce RTX 4070 8GB Graphic | RGB Backlit USB-C B&O Win11 Black + HDMI Cable
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,549.00 
          </p>
          <Link to={'https://www.amazon.com/HP-i9-12900H-GeForce-Graphic-Backlit/dp/B0CTZQPK4X/ref=sr_1_1_sspa?crid=DEFO7X3MKT0A&dib=eyJ2IjoiMSJ9.24Y0VNuzlMnxJLqoQ0yjAVZF416Hv-SVgl4NtFHGcs2ZSMv4Dqd82iHoZLZlp4KOwAJ5hwSyyzkbtGQf9FghZ0g0y0btSKHD4ZsiaMQuCF3ILwGapKUi5YwlC6OnWPgQHdJdENgcZT8vjEPjTrckFcotFFVLkXK6mijh3ASxiu2E20zlV3gjCMjXvbb4nddUD6Ttd0Z7Vrj8Qs1cY3yzW8IYVCqwfNwMbqUH304osnd0DwZ-hpT3bnwFgzM0GDsE3OVxTf-ocnsRsOpV1kDUho_e6Ts1BAVf1tCwqtvHpyQ.tEb1gxCl5l02p_oAAbUVE82VsClB4jISAOVKbcBFsIk&dib_tag=se&keywords=hp%2Bomen%2B16&qid=1715448707&s=electronics&sprefix=hp%2Bomen%2B16%2Celectronics%2C392&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          New Arrivals For-Hp Envy 16 16" Intel Core I7-12700h 64gb 1tb Ssd 4k Touch Screen Laptops Notebook
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,399.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/New-Arrivals-For-Hp-Envy-16_1600899721048.html?spm=a2700.galleryofferlist.normal_offer.d_title.71d84162lA2aIk'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          DELL XPS 13 "Thin and Light Laptop 13.4 inch 13.4"", UHD+ 3840x2400, 60Hz, Touch, Anti-Reflect, 500 nit, Infinity Edge Finger Print Sensor"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $2,364.20 
          </p>
          <Link to={'https://www.flipkart.com/dell-xps-13-intel-core-i7-12th-gen-16-gb-1-tb-ssd-windows-11-home-9320-thin-light-laptop/p/itmdcb4c46c16095?pid=COMGP8XFZGSKJ2FN&lid=LSTCOMGP8XFZGSKJ2FNAJCFFN&marketplace=FLIPKART&q=DELL+XPS+13&store=6bo%2Fb5g&srno=s_1_3&otracker=search&otracker1=search&fm=Search&iid=44f97f10-0e0c-417a-9a67-b7b04f916a4b.COMGP8XFZGSKJ2FN.SEARCH&ppt=sp&ppn=sp&ssid=rr9q3aa2ps0000001715429964753&qH=9ae262a3c6a55af2'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Envy 16 OLED i7 13700H RTX 4060 32GB DDR5 1TB SSD 3K 120Hz Touchscreen Laptop
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,499.65
          </p>
          <Link to={'https://www.ebay.com/itm/266790377394?itmmeta=01HXPSGRACZFW5Z7CCG7MWVAG8&hash=item3e1df217b2:g:gpcAAOSwKZdmMD-4&itmprp=enc%3AAQAJAAAA4N8FxBVF91the%2BIJkw9is7xDhcTS3vzmvjg%2B5jlWHQdyaS8Ugu5mTxUUFHBplZS574pVCme6RiJAqK6US4jnRvF3CHPSj4EVufdfF9CLP5aCspGkkqiqDYTBdTNQHfPcBAxq44SwRLxyUy5rzryrgcJ4Pt9%2FU0LLwJP81wHaIo%2Ftl4onaeEQflKlUfxShi1H5Sx3QdHZnsYKx%2BX4jQYUjxYErf6d4E0Qyx44QthlrUuInhHHrtJbYRR3Tn9bU78jyH9%2BDyGx61PHWaFWC8dWr82%2FrkpD2KRi1hTPeVcjIQrE%7Ctkp%3ABk9SR7aFw9ntYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          ASUS VivoBook 15 OLED K513 Thin & Light Laptop, 15.6 OLED Display, Intel i5-1135G7 CPU, NVIDIA GeForce MX350 GPU, 8GB RAM, 512GB PCIe SSD, Fingerprint Reader, Windows 10 Home, Indie Black, K513EQ-PB56
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $599.00 
          </p>
          <Link to={'https://www.amazon.com/ASUS-VivoBook-i5-1135G7-Fingerprint-K513EQ-PB56/dp/B09F2Z89XW/ref=sr_1_15?crid=376EMG52G95UP&dib=eyJ2IjoiMSJ9.LGdbiaWX4MhAzxI7ceCUfIKyAO7EYRtrh5h0LG-fmNJx4V_h8xc-392kjkzq37Y4Im6J0s1G9qt8sGdIMr32URqsXfUmM_3mmOnb_vrCSCNSOfhmmEHLyPi7vB9MZz-l4bkYKtZH3M9D-zgvv0tvepgyiLezftRa-BML6EYdWk9XL-3DgwjSEzJKXyEu_U-lXhUjzDTJ_g3BndRPwjZ7o3z3VrfkcmwtZFIEEw4A0DjLcmUb3EyzvE1dZrMYb8JAaACbWftngHN6kTvKFHk1i0T0-iBQ1Tcm77TKoO375pA.50bpkayOiK521IQJTzxClPbfjKN8J6v1K1lPqeHtwsQ&dib_tag=se&keywords=vivobook%2B15%2Boled&qid=1715448417&s=electronics&sprefix=vivobook%2B15%2Boled%2Celectronics%2C363&sr=1-15&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          New Dell XPS 13.4″ 1TB SSD Intel Ultra 7 1.4-4.8GHz 16GB DDR5 Arc Laptop £-OFF👇
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,412.10
          </p>
          <Link to={'https://www.ebay.com/itm/296420778047?itmmeta=01HXPSPAWSD42PP5EVHRC3S9QZ&hash=item45040e203f:g:T5AAAOSwQn1mFodU&itmprp=enc%3AAQAJAAAA4A9P5owOHJ1D2ILK7AHlEdHG4DIiIU1qMmxu6PgB2T%2BVq%2BgUfkSb7LVAHOi2c9GTQqTOlwUx4L0M8sRMeyGX7ljlVBKN%2F8VnuY8mO36HnqkqCwr4sK%2BZiROkdeRd%2FQOamI7AgbFiV2Yc%2FLgTChbBETpMn8TIDtAjV1mENiwlsfnWtbMOfcQVwirSAjG7tbBTbY2Gq2b2tUg%2FE%2BIKgcTzDizWJpBE9myPfja6GpGUXVwZaan5vmds9fcwCLcqkWcSPEEjny96ZEN9FR2%2BA6mPA7vrB6DQ%2BpgQVEkVcb2Zr7Aa%7Ctkp%3ABFBM0q7Z2e1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Lenovo Legion 5 Pro 16" Gaming Laptop (13th Gen Intel Core i7-13700HX, 32GB DDR5 RAM, 1TB SSD, NVIDIA GeForce RTX 4060, 165Hz Display,Microsoft® Windows 11 Home (64-bit)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,449.99 
            </p>
          <Link to={'https://www.amazon.com/Lenovo-Display-Geforce-Keyboard-https://www.amazon.com/Lenovo-i7-13700HX-GeForce-Display-Windows/dp/B0CC3THQBS/ref=sr_1_6?crid=2Z8A4PCH5MQZC&dib=eyJ2IjoiMSJ9.KzaZyZIh_xJWRFaR-IbModJ7NdEsCDzCMN2qvXUNDZ_u7WX2wEyR0EidWFAL-28CKvGoYQVovR368NOH8hmg0h0_2-JHfEMrVwi24AsrpRm4eK--btDKCBFT29lktMNforXL6af8jmrVyGunMYL7WJn1UOLENSDVaha8LyQWsLNWFb00JLtws4J-ttUmbN28dO9QSSGBQdqoABYdpuHGbDw12V4sm5dgrVlaiPbYu6_zcNOWhnq7SlxkT5Lvh5bXFARLjg2nIXziDdABcKM7ULZbPREmqXwcIdzM8weZbvU.nvHZJijtUbtKv0jJdeVFXcx1BZxY_1GwYITG6VFBrSw&dib_tag=se&keywords=lenovo+legion+5&qid=1715448151&s=electronics&sprefix=lenovo+legion+%2Celectronics%2C442&sr=1-6'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          ASUS Zenbook 14 "Thin and Light Laptop 14 Inch 2.8K (2880 x 1800) OLED 16:10 aspect ratio, Glossy display, 90Hz refresh rate, 0.2ms response time, 600nits HDR peak Brightness, 100% DCI-P3 color gamut, VESA CERTIFIED Display HDR True Black 600, 1.07 billion Color depth, PANTONE Validated, 70% less harmful blue light, TUV Rheinland-certified, 90�% Screen-to-body ratio Finger Print Sensor"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $846.90  
          </p>
          <Link to={'https://www.flipkart.com/asus-zenbook-14x-oled-amd-ryzen-5-hexa-core-5600hs-16-gb-512-gb-ssd-windows-11-home-um5401qa-km541ws-thin-light-laptop/p/itm05033c9b1d455?pid=COMGZKHQHERZG2RP&lid=LSTCOMGZKHQHERZG2RP9M95QC&marketplace=FLIPKART&q=asus+zenbook+14+oled&store=6bo%2Fb5g&srno=s_1_5&otracker=AS_QueryStore_OrganicAutoSuggest_1_12_na_na_ps&otracker1=AS_QueryStore_OrganicAutoSuggest_1_12_na_na_ps&fm=Search&iid=07bde564-46f9-4931-93af-cd20fa854229.COMGZKHQHERZG2RP.SEARCH&ppt=pp&ppn=pp&ssid=e7n2gmabb40000001715429273570&qH=ca3b48196e117854'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          New Arrivals For Hp Envy 16 16" Intel Core I9-12900h16gb Ddr5 512gb Ssd RTX3060 6gb16" 4k Oled Touch Scren/face Id Laptop 
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,399.00 
          </p>
          <Link to={'https://www.alibaba.com/product-detail/New-Arrivals-For-Hp-Envy-16_1600899660355.html?spm=a2700.galleryofferlist.normal_offer.d_title.71d84162lA2aIk'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Dell XPS 13 Plus 9320 13.4" 1TB SSD, Intel Core i7-1360P, 32GB RAM Laptop READ
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $999.99
          </p>
          <Link to={'https://www.ebay.com/itm/285855340696?epid=25062123245&itmmeta=01HXPSPAWS5NQ7AYR2JK3TFKEQ&hash=item428e4e5898:g:7pQAAOSwQuVmP~i-&itmprp=enc%3AAQAJAAAA4MXD0IdpQTYb%2Bnoi5FS%2BQF9d6BbhR93B%2BiKdFKlh57N90UC9cY98h9wpDsJErF2V%2Fht7niNibK%2BPTynbH8DlpRMhY%2FN5C9KgHxbYE65Zv3Rk3QIQOrujVekYPy01N6nlK9EMch64fB2QTrVaHtt0iTHGaD4nEFi8wzfp7riS8XGxYMRcYfBq8A2O5sCi%2B8Wwx7KlVroymJtHwGG%2FEBY8laZ9vJtub0yOg%2Fm1Y1I37OnKD5h79C3RdPvyBLdyjPOfMyywm0xd%2BPiWktFvma%2FrNPD35hEuLEn89ZMKg25%2FdZuP%7Ctkp%3ABk9SR9Ku2dntYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Lenovo Legion 5 Gaming Laptop, 15.6" WQHD 165Hz Display, 8-Core AMD Ryzen 7 7735HS, NVIDIA Geforce RTX 4060, 32GB DDR5 RAM, 1TB NVMe SSD, Backlit Keyboard, WiFi 6, HDMI, Win 11, w/CUE Accessories
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,179.99 
          </p>
          <Link to={'https://www.amazon.com/Lenovo-Display-Geforce-Keyboard-Accessories/dp/B0CXX7T52Q/ref=sr_1_3_mod_primary_new?crid=2Z8A4PCH5MQZC&dib=eyJ2IjoiMSJ9.KzaZyZIh_xJWRFaR-IbModJ7NdEsCDzCMN2qvXUNDZ_u7WX2wEyR0EidWFAL-28CKvGoYQVovR368NOH8hmg0h0_2-JHfEMrVwi24AsrpRm4eK--btDKCBFT29lktMNforXL6af8jmrVyGunMYL7WJn1UOLENSDVaha8LyQWsLNWFb00JLtws4J-ttUmbN28dO9QSSGBQdqoABYdpuHGbDw12V4sm5dgrVlaiPbYu6_zcNOWhnq7SlxkT5Lvh5bXFARLjg2nIXziDdABcKM7ULZbPREmqXwcIdzM8weZbvU.nvHZJijtUbtKv0jJdeVFXcx1BZxY_1GwYITG6VFBrSw&dib_tag=se&keywords=lenovo%2Blegion%2B5&qid=1715448151&s=electronics&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=lenovo%2Blegion%2B%2Celectronics%2C442&sr=1-3&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Lenovo ThinkPad X1 Carbon Gen 11 i7-1365U 32GB 512GB 14" WUXGA Touch W11P 3Y
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,667.34
          </p>
          <Link to={'https://www.ebay.com/itm/335378466241?itmmeta=01HXPSYGK43SPY19YP9MZVCX43&hash=item4e161d45c1:g:K1sAAOSwNkNmHnCe&itmprp=enc%3AAQAJAAAA4GxfZ6u%2FjSx95705mkFaaPdQebmccwyc3zkev%2BpypZbuk7hog7PT1nK5nR%2B0j5dlIgaUNP0InIqjlBsQh3dHH6CNi2dn%2FJL%2FFF5t9LzL%2BDiere4Xu7x3Jj0RJkh0Z8T9Qrf4oVvl%2FyAZGD8lta%2BA6SDYvcZHW7qVsMbczXkNlVG%2BZSzb4zdmHdCg3gynU6MZFLiSCaMkfP1gK4iW2BJgbIopqZF0XKjMP8AQ8faZafm0okP2YxHpqCJSyhOSDtIT3ENRUAgfjoJTUdfYynNMSx%2BOI5u8TABbif0uAkaYqEH2%7Ctkp%3ABFBM_In62e1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Late 2020 Apple MacBook Air with Apple M1 Chip (13.3 inch, 8GB RAM, 128GB SSD) Space Gray (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $599.99 
          </p>
          <Link to={'https://www.amazon.com/Apple-MacBook-128GB-Space-Renewed/dp/B09WQ36NF1/ref=sr_1_7?crid=647M65NZGWMN&dib=eyJ2IjoiMSJ9.xzyMb_19x7zFe0cbwAgj5nQZG9Q1h3RPmrFMcTTF0fMkzfl6n-Gak-iDdql76JKSm_-DnLvVd6-RyxRSlksl1niAyTki5jG7AoviKUZCOWnvlSOsVL_jNvCkliLQJjEP09cZTWj9ipQqTLYgBkqyDSZPmULS8A497tID8-wRo6mAtxmEaaHvLlinZhYUH-8I2bMUUdHWrjAY9FT9C3c-zEtDVNorYt3MEnkVbnz2fI819ViSdi_f8dyaHWvs1hEyRfGwnsTFSNDsBlzL5Db9p-JuTLI8iMYzzNPwAAuNYjc.gQuoGNlQ0JcH3FPQMcESo7ZgmJ0H6q08qoAW9GKHFi0&dib_tag=se&keywords=apple+macbook+air+m1&qid=1715447923&s=electronics&sprefix=apple+macbook+air+m%2Celectronics%2C374&sr=1-7'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          ASUS Zenbook 14 "Thin and Light Laptop 14 Inch 2.8K (2880 x 1800) OLED 16:10 aspect ratio, Glossy display, 90Hz refresh rate, 0.2ms response time, 600nits HDR peak Brightness, 100% DCI-P3 color gamut, VESA CERTIFIED Display HDR True Black 600, 1.07 billion Color depth, PANTONE Validated, 70% less harmful blue light, TUV Rheinland-certified, 90�% Screen-to-body ratio Finger Print Sensor"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $909.92 
          </p>
          <Link to={'https://www.flipkart.com/asus-zenbook-14-oled-2023-amd-ryzen-5-hexa-core-7530u-16-gb-512-gb-ssd-windows-11-home-um3402ya-km541ws-thin-light-laptop/p/itmde571f9affd92?pid=COMGMGXF2U4ZJXVS&lid=LSTCOMGMGXF2U4ZJXVST64SLK&marketplace=FLIPKART&q=asus+zenbook+14+oled&store=6bo%2Fb5g&srno=s_1_3&otracker=AS_QueryStore_OrganicAutoSuggest_1_12_na_na_ps&otracker1=AS_QueryStore_OrganicAutoSuggest_1_12_na_na_ps&fm=Search&iid=07bde564-46f9-4931-93af-cd20fa854229.COMGMGXF2U4ZJXVS.SEARCH&ppt=pp&ppn=pp&ssid=e7n2gmabb40000001715429273570&qH=ca3b48196e117854'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Original 2K Touch Screen 2-In-1 Used Laptop For Dell Xps 13 9365 Core I7 7th Gen 16gb Ram Laptops Pc Portable Business Notebook
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $299.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Original-2K-Touch-Screen-2-In_1601052511230.html?spm=a2700.galleryofferlist.normal_offer.d_title.77b846b1Ace707'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Lenovo ThinkPad x1 Carbon G11 Gen 11 i7-1355u 16GB RAM, 512GB SSD, 14" 2,8K OLED
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,438.35
          </p>
          <Link to={'https://www.ebay.com/itm/395391956845?itmmeta=01HXPSYGK4VWB9ZB8G3Q450QDS&hash=item5c0f32776d:g:MjoAAOSw8jNmO5c6&itmprp=enc%3AAQAJAAAA4BW2n2qYrDKriKDDyElyqfZTteZTTPYoDUI9UhqKXhggAbHhmbz6cy2G8uh2lK%2FzDnVCETtOkUKJa36G5R6J4uY1R0wl0TiLpq5VTZq7YDNmm%2FIJo%2F%2BdLfCK24NkXfFxSsjk6KiyfHNPpeM7zIhwHYCxQglVTv04TKzfs4%2F7%2FU9fEgqwtvW0Z5HmJyKouhrf%2F2y%2Bf3hthWAl18NV%2BqU04aisjY%2BEOqTUw53zimnkFLkXdPDefgYCeZV8kWklEovvBX6yrQYH1aB%2FaHNvkrK33sB%2FB%2B84Hcc2NkRIbCxUNR%2Fp%7Ctkp%3ABk9SR_yJ-tntYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Late 2020 Apple MacBook Air with Apple M1 Chip (13.3 inch, 16GB RAM, 256GB SSD) Space Gray (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $607.97 
          </p>
          <Link to={'https://www.amazon.com/Apple-MacBook-256GB-Space-Renewed/dp/B09RQ8T428/ref=sr_1_5?crid=647M65NZGWMN&dib=eyJ2IjoiMSJ9.xzyMb_19x7zFe0cbwAgj5nQZG9Q1h3RPmrFMcTTF0fMkzfl6n-Gak-iDdql76JKSm_-DnLvVd6-RyxRSlksl1niAyTki5jG7AoviKUZCOWnvlSOsVL_jNvCkliLQJjEP09cZTWj9ipQqTLYgBkqyDSZPmULS8A497tID8-wRo6mAtxmEaaHvLlinZhYUH-8I2bMUUdHWrjAY9FT9C3c-zEtDVNorYt3MEnkVbnz2fI819ViSdi_f8dyaHWvs1hEyRfGwnsTFSNDsBlzL5Db9p-JuTLI8iMYzzNPwAAuNYjc.gQuoGNlQ0JcH3FPQMcESo7ZgmJ0H6q08qoAW9GKHFi0&dib_tag=se&keywords=apple+macbook+air+m1&qid=1715447923&s=electronics&sprefix=apple+macbook+air+m%2Celectronics%2C374&sr=1-5'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Original Brand New For Lenovo ThinkPad X1 Carbon Gen 11 Laptop 2023 14" Core I5 I7 13th Generation 16gb 32gb 512gb 1tb Ssd 4g L
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,730.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Original-Brand-New-For-Lenovo-ThinkPad_1600869328199.html?spm=a2700.galleryofferlist.normal_offer.d_title.39137412N8ok9O'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          ASUS Zenbook 14.5" 2.8K 120Hz OLED Touch Laptop Intel i5-13500H/8GB/1TB New!!!
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $629.00
          </p>
          <Link to={'https://www.ebay.com/itm/166746650552?epid=21061890692&itmmeta=01HXPTA0AJ4HDNP81XNAK6X3G7&hash=item26d2dff7b8:g:LfIAAOSw5J5mNvOP&itmprp=enc%3AAQAJAAAA4LRKmEW7VoqARzTacqX1QnW9UzN9aAk63MyxT3mDwvmGMfz8uW75qHBXLMFyToIEFKcfpElTMjEmp8BAp2V3nFGOZznfnuBKc6A5To3L%2F1TCpGKRFNo0mLCL3IAYTyr7ApugBBz9Bn%2FHs%2FQe9tlPoM1epKss7IonloVyuujZpYiU3xxpdjcLqNpmQg2oAPvr5qlzNG749gBngoyBBek9s7NBc7zatjgBC1ll413dRpBgqu3fbxm9BaFSIHI7%2Bino9xPGPx1urhXW%2FCcfipM%2BIrO%2FnfdNm91mA5YJO9mmHd4s%7Ctkp%3ABFBMsoWo2u1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Pavilion Gaming 15 Laptop, NVIDIA GeForce GTX 1650, AMD Ryzen 5 4600H, 8GB DDR4 RAM, 512 GB PCIe NVMe SSD, 15.6" Full HD, Windows 10 Home, Backlit Keyboard (15-ec1010nr, 2020 Model)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $858.99 
          </p>
          <Link to={'https://www.amazon.com/HP-Pavilion-GeForce-Keyboard-15-ec1010nr/dp/B0897B9J48/ref=sr_1_4?crid=19PW312SOWYQO&dib=eyJ2IjoiMSJ9.dQGKaTxLC5LSLR_XBHTerDJnMzkd5eeUZBtSkVi88pWdMOPrUAyfq6k6RnJvaKM4jecuQJ5g3UPZ7KwYCWfJZ6NDhpfczx2fm-dQA4IHxwRbhzx6YBN4vQIrHO-Bil4p8Br96cw46ArFF0bPlkjJJr2JnEa_Ne76TnuRArY30wu-SXaHH5u6W2DsY9rSGpWsyw8NT4z66Kx5yOuajtM6nw51aRQnwg_fZN2q-Ca61C_nYr7We5EFgoApC4rd4P_yy7rkh2CFtbxcy_JpHmhyI14UsAIqQI0tbqAXOkmAiXY.5QjAIWPucdbuAoeIXDTGUZaAQQ-gtT6OqUCmp_ATg44&dib_tag=se&keywords=hp%2Bpavilion%2Bgaming%2B15&qid=1715447715&s=electronics&sprefix=hp%2Bpavillion%2Bgaming%2B16%2Celectronics%2C402&sr=1-4&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Pavilion Gaming 15 15.6 inch Full HD WLED Backlit Anti-glare Micro-edge IPS Display (144 Hz Refresh Rate, Brightness: 250 nits, 141 PPI, Color Gamut: 45% NTSC)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $820.85  
          </p>
          <Link to={'https://www.flipkart.com/hp-pavilion-gaming-amd-ryzen-7-octa-core-r7-4800h-16-gb-1-tb-hdd-256-gb-ssd-windows-10-home-4-graphics-nvidia-geforce-1650ti-144-hz-15-ec1512ax-laptop/p/itmc976b97de3459?pid=COMG329PDCH8B6RE&lid=LSTCOMG329PDCH8B6REWJYPZJ&marketplace=FLIPKART&q=hp+pavilion+GAMING+15&store=search.flipkart.com&srno=s_1_3&otracker=search&otracker1=search&fm=search-autosuggest&iid=7cf21df6-64aa-4f28-8a2a-fd297a5de01e.COMG329PDCH8B6RE.SEARCH&ppt=sp&ppn=sp&ssid=lh401p3wi80000001715429346634&qH=0cdf762fd8e31458'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          i7-1360P ThinkPad X1 Carbon Gen11 thinkpad laptop Intel Core 13th thinkpad with 1TB SSD
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,420.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/i7-1360P-ThinkPad-X1-Carbon-Gen11_1601091021439.html?spm=a2700.galleryofferlist.normal_offer.d_title.39137412N8ok9O'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          2024 HP Laptop Pavilion 15.6" Touch Intel i7-1355U 32GB RAM 1TB SSD Win 11 Pro
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $879.00
          </p>
          <Link to={'https://www.ebay.com/itm/126377189713?itmmeta=01HXPTDYY5AHV8QCMSZS0GEYX0&hash=item1d6caae151:g:4boAAOSwyfRl8yil&itmprp=enc%3AAQAJAAAA4IAD11XTPhKkRYe80NfCAEoi6%2FHSmZFoRQMXKJ%2BSCTLiycBO0S2cYG4mul9fDbJ9kVAFfTifN7Wspkq62osPhk3tRT8JZQNmWFTLwmqrShx%2BrjcODBslbPWlhID8588y9znl5WOVlZzo5P2%2B%2BNvHhFJzVZnSafdhbR6683gOQ9Q23bHe3w9nFhIVssdXJ3%2F4RtN4xNdtwejqzU8%2F6n7c54I6qVj7grKEuuX0LsrwT3mkxRpyXa0cL96ZbXvF36DkwArqVNpHUSDrXpM%2FjXBROvUhJo%2BEZ3%2BGo6rTfNvxaA7P%7Ctkp%3ABk9SR6Tvt9rtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Asus Zenbook 14X OLED Business Laptop | 14.5" 2.8K 120Hz Multi-Touch 550nits | 13th Gen Intel 14-core i7-13700H | 16GB DDR5 2TB SSD | Backlit Keyboard Thunderbolt Win11Pro + HDMI Cable
           </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,099.00 
          </p>
          <Link to={'https://www.amazon.com/Asus-Zenbook-14X-OLED-Multi-Touch/dp/B0CZ78GQ4Y/ref=sr_1_2_sspa?crid=1W1Q3YXYGFUPO&dib=eyJ2IjoiMSJ9.jpd7fLWFLPs1n5iFS1TS0AVtuI0yVRFqdscQMK0p0riNbeNADviiroRdaObA4m8Hi5ru2ci0kdibAcf2B_E25MprozuSrnXKBVtsmp-Hz0mzKUVskbnTJ6ufLEHeubFZeDMvOU7-0lkyAU8VQtFgOUC-6oqilssoKV8rnKzYZzYu_JQcKwoOCNiE34DRwlaEZxK2TkW1qYlivCIvg-1Jg5wA9J5YfJMzHeXTBvQpANolnSLgh2il7lXyoJlBv2MK6O0hTuXghKUYXfI4aA2a7ciQQ0pF-dofBxXmgoZJkb4.vTEUV1lydqr7Mz_idJ2PgLpa0Tt5FJE8zwxXof54sAI&dib_tag=se&keywords=asus%2Bzenbook%2B14%2Boled&qid=1715447479&s=electronics&sprefix=asus%2Bzenbook%2B14%2Bol%2Celectronics%2C429&sr=1-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          2023 ASUS Zenbook 14X OLED Q420VA EVO.I7512 Pro Extreme Core i7-13700H, 16GB LPDDR5 RAM, 1TB SSD Iris Xe
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $999.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/2023-ASUS-Zenbook-14X-OLED-Q420VA_1601032533620.html?spm=a2700.galleryofferlist.normal_offer.d_title.70a83acaC1ciNX'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple MacBook Air 13.3" (256GB SSD, Apple M1 8 CPU/7 GPU, 8GB Memory) Laptop -...
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $623.24
          </p>
          <Link to={'https://www.ebay.com/itm/166757254785?epid=15044906309&itmmeta=01HXPTHWGPBXPC8C8ZBKGR66ZP&hash=item26d381c681:g:CtYAAOSwaEllNhyX&itmprp=enc%3AAQAJAAAA4D1Tp%2BJR%2FXly9oEaaFwuZtGxbVV8waqZF2o5u4jFaXgMQP7zNilQvipOoK88A9RMPDHuEY7wSQmqGRk%2FBJum7kI0B6wBPfDLTCslqxPjoLXy5jbhfNuJCWQtWtZ93Onhkv58mYSC5U%2FcEyOdmbyxbwzUQI4nHHGwOOmeivKbnIp0JHuk4Wq1nvibUebNVORfFLawaUQP%2Fskc2VMB4eN75QjNzUjj2q9mq7PyQQa0dpVKkValxoHNTf%2B8XqYgGQVtB%2BKY7LSfLF0B3ntPKGEL5%2B3qCik31h7GSaNLTCZvSu6X%7Ctkp%3ABFBM2MjH2u1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Pavilion Gaming 15 "NVIDIA GeForce GTX 1650 15.6 inch Full HD WLED-Backlit IPS Anti-glare Micro-edge Display (Brightness: 250 nits, 141 PPI, Color Gamut: 45% NTSC)"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $731.88 
          </p>
          <Link to={'https://www.flipkart.com/hp-pavilion-amd-ryzen-5-hexa-core-r5-5600h-8-gb-512-gb-ssd-windows-10-4-graphics-nvidia-geforce-gtx-1650-144-hz-15-ec2004ax-gaming-laptop/p/itm65f38d574f453?pid=COMG5GZXPWMGTNWS&lid=LSTCOMG5GZXPWMGTNWSBGBZIF&marketplace=FLIPKART&q=hp+pavilion+GAMING+15&store=search.flipkart.com&srno=s_1_5&otracker=search&otracker1=search&fm=search-autosuggest&iid=7cf21df6-64aa-4f28-8a2a-fd297a5de01e.COMG5GZXPWMGTNWS.SEARCH&ppt=sp&ppn=sp&ssid=lh401p3wi80000001715429346634&qH=0cdf762fd8e31458'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          fo A-SUS Zenbook Pro 14 OLED 2.8K 120Hz Intel Core Ultra7 Ultra9 32GB RAM 1TB SSD, Windows 11 Home
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,050.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/fo-A-SUS-Zenbook-Pro-14_1601092282100.html?spm=a2700.galleryofferlist.normal_offer.d_title.70a83acaC1ciNX'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          MacBook Air 13 Space Gray 2020 3.2 GHz M1 7-Core GPU 8GB 256GB Very Good
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $519.99
          </p>
          <Link to={'https://www.ebay.com/itm/296345184442?itmmeta=01HXPTHWGP1C98JWNKFB2D5FCP&hash=item44ff8ca8ba:g:cZIAAOSwRa1mEGZ0&itmprp=enc%3AAQAJAAAA4MwUXsEYyWbpvAFUcEG6tQxywpey7w6L4RA3jQ3cDQL1gH0DRY6l2U%2ByV56FNEReHX7fkgomBJIQT%2FaLHH3fUCXzYj54zX8aeoXM%2FpJwFys%2FpPZbYKz3cchiBofPX608AEokD3tPRWQJH1cUxHYxjYZELpHaXw3H%2FwQ%2BTEfEuxiuai%2B%2Be%2BcJlYPWEeV40QP2AKHzZQh7gansROIE175806IKPo%2FnZausPkWZh1ntBbuykriQ5%2FM%2FLwBNp99RLwesYO3rrbI59uvdP%2FF1qbwwHFqmPNIebasiTgrv5JDIFaNi%7Ctkp%3ABFBM2MjH2u1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple 2024 MacBook Air 15-inch Laptop with M3 chip: 15.3-inch Liquid Retina Display, 8GB Unified Memory, 256GB SSD Storage, Backlit Keyboard, 1080p FaceTime HD Camera, Touch ID; Starlight
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,199.00 
          </p>
          <Link to={'https://www.amazon.com/Apple-2024-MacBook-15-inch-Laptop/dp/B0CX251F4X/ref=sxin_16_pa_sp_search_thematic_sspa?content-id=amzn1.sym.34536881-c32d-47dc-bc5e-12f647b929c5%3Aamzn1.sym.34536881-c32d-47dc-bc5e-12f647b929c5&cv_ct_cx=apple+macbook+air+m3&dib=eyJ2IjoiMSJ9.HcVowuMhVcYdNuu7CBBFUG3XAdHue0T6z4f6u_lgwsE0x1EG4DzFUUQFqg65D5yoDT8OiMPH4q8MKeubT1Q0tg.zsUAKs0h-z--kqmBZIguNoajGv4PJpmzweie1zJf40s&dib_tag=se&keywords=apple+macbook+air+m3&pd_rd_i=B0CX251F4X&pd_rd_r=0320c256-3f5f-4038-9c06-2051dc6d8bea&pd_rd_w=cv3JS&pd_rd_wg=7XkRf&pf_rd_p=34536881-c32d-47dc-bc5e-12f647b929c5&pf_rd_r=8976AVJ6CAXVAVKZNQKC&qid=1715447196&s=electronics&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-4-557fe57e-da87-4f50-ab9e-35e6f03c46e7-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9zZWFyY2hfdGhlbWF0aWM&psc=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>



        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          2024 Latest H P OMEN 16 Gaming Laptop I9-14900HX+RTX4080 16.1inch 240Hz Display Ultra high performance gaming laptop
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,060.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/2024-Latest-H-P-OMEN-16_1601080864138.html?spm=a2700.galleryofferlist.normal_offer.d_title.5d2b6449mwlpDa '} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Macbook Air Apple M1 "Stylish & Portable Thin and Light Laptop 13.3 inch Quad LED Backlit IPS Display (227 PPI, 400 nits Brightness, Wide Colour (P3), True Tone Technology) Light Laptop without Optical Disk Drive"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $846.90 
          </p>
          <Link to={'https://www.flipkart.com/apple-2020-macbook-air-m1-8-gb-256-gb-ssd-mac-os-big-sur-mgn63hn-a/p/itm3c872f9e67bc6?pid=COMFXEKMGNHZYFH9&lid=LSTCOMFXEKMGNHZYFH9P56X45&marketplace=FLIPKART&q=apple+macbook+air+m1&store=6bo%2Fb5g&spotlightTagId=BestsellerId_6bo%2Fb5g&srno=s_1_3&otracker=AS_QueryStore_OrganicAutoSuggest_2_13_na_na_ps&otracker1=AS_QueryStore_OrganicAutoSuggest_2_13_na_na_ps&fm=search-autosuggest&iid=75cf3e2a-1501-4cfc-8d53-787e61c56723.COMFXEKMGNHZYFH9.SEARCH&ppt=sp&ppn=sp&ssid=q2m9akx8lc0000001715429403138&qH=0020898769ebfd7b'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Omen 16.1" Full HD Gaming PC Intel Core i5-12500H 16GB 512GB SSD RTX3050Ti
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $689.49
          </p>
          <Link to={'https://www.ebay.com/itm/285839945971?itmmeta=01HXPVJ4JCQ455JCFDCZC24XKV&hash=item428d6370f3:g:TWsAAOSwGxZj87Uy&itmprp=enc%3AAQAJAAAA4OfjV4O5%2FnxeyngxDvU5DVZ7tnTLTaJsTvOIi%2FPucAEr0x%2BEKHy%2F8eDWjvrjVfp9k4A2CXRRJAQEClbBllGQgzpTUOOWSv4e8fDaTgbII%2BydV11rlycIwwm8IjXU0dcBENURew%2B%2FhjluwkrBjLh7tGYvzY8qqpuXkB25VLqPLkGaUyQsn1HvzIFHzc93oUj5m8%2Ff8mRogEtVkIVRABJTc2pBDpz6USQNtrQshb60kLU%2B6lAXiBVRU0cKUR1%2FQhKqQXzGjteRdN0HbjwdwUPzxg41GehaGqw0aHAmNMwvi9Xy%7Ctkp%3ABFBMrsnI2-1j'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple 2024 MacBook Air 15-inch Laptop with M3 chip: 15.3-inch Liquid Retina Display, 8GB Unified Memory, 256GB SSD Storage, Backlit Keyboard, 1080p FaceTime HD Camera, Touch ID; Midnight
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,199.00 
          </p>
          <Link to={'https://www.amazon.com/Apple-2024-MacBook-15-inch-Laptop/dp/B0CX23GFMJ/ref=sr_1_2?dib=eyJ2IjoiMSJ9.LoGecZLPy_Kr9fB34bCwcXiWmmBaOcXRtTBbSzpHwMENvBGZymL-ewpFRIoXBu47aReeDktPY_2RSO46mvtfmo0BQYhHg7hpxQswY9ZjTAUoDV4YJORXjxsx1TGOW_3orlj0LaD08Y_E1t4kDRSJr1brH4r6gqN8ly0LjGD3nPniKdDL_CFibo1n-mYnJtll4FxPUCUF57yWACAW5QxGO3C03BqdcB9aCdjFJjLNBuyFt513gR6-Wc2Owj6AMS2jwRni-xU8SohPB1JCiWNqaafhP5nP8k4bI7HsieulQiQ.hWyOxUDi_lglUm9PS4GPE-vr5tIu_6ESCXsYYETn0_s&dib_tag=se&keywords=apple+macbook+air+m3&qid=1715447196&s=electronics&sr=1-2'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          New H P OMEN 16 Gaming Laptop I9-13900HX RTX4070 16.1inch 240Hz Display Ultra high performance gaming laptop
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,190.00 
          </p>
          <Link to={'https://www.alibaba.com/product-detail/New-H-P-OMEN-16-Gaming_1601014538919.html?spm=a2700.galleryofferlist.normal_offer.d_title.5d2b6449mwlpDa'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ebay
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          HP Omen 16 16z-c000 Gaming Laptop PC 16.1" Ryzen 7 16GB 1TB SSD 8GB RX 6600M W11
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $799.25
          </p>
          <Link to={'https://www.ebay.com/itm/395347946037?itmmeta=01HXPVJ4JCEPEPFW22835SYVS0&hash=item5c0c92ea35:g:QXwAAOSwE71mIVEW&itmprp=enc%3AAQAJAAAAwBT0z1NCR%2BLKKvHCsBrB5n%2BSNXT0pZW9x2u38mpotTxFnHgZk51eJ5zSYRLJAQadm34G9Uj8aj7WpCaEZCd4gwQIYLBqgjqqfW5MQ3mROKoOZp4y2MyThGM%2F8XW400Hcrha1pI%2FPf9eDwzo0UoXvkitEVkRQkTKYimehPAto1ElOKuIsNicKuX5EljQKpgvUdN9jpdR1XEl73ZxgGioNbEUg%2Bx8eLxSmsww7OkzSfZZwg5LkRSjnGfvR7DChUDGNnw%3D%3D%7Ctkp%3ABk9SR67JyNvtYw'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>


        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Dell XPS 13 9315 Laptop (2022) | 13.4" FHD+ | Core i7 - 512GB SSD - 16GB RAM | 10 Cores @ 4.7 GHz - 12th Gen CPU Win 11 Pro (Renewed)
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $899.00 
          </p>
          <Link to={'https://www.amazon.com/Dell-XPS-9315-Laptop-13-4/dp/B0BHPXSSTM/ref=sr_1_2_sspa?crid=1GVNBYJ5TEGJF&dib=eyJ2IjoiMSJ9.fmxodPUsFnhmBYGUq663a49tb9u4to0t6NutzjCLH1-RNTBbi_GtZfo__-vlAGfWzgLvB2MPyhbKj6Z8rIteK5NW2T_2wIsWwa1sKZPN-lX7Yl5zKfK7BTZoA1mlHgKXN0eAz6EFQgf1EfQE09cuU6cLySWYNr4WUJNRuhSF8jrfJTGDfY0byLQbVN82ybM5i_8I1f8k1Qy-WpDVqLHgSbRc2iF4dl00bhtRHNl1B87U6BNM69qhzSvuCsSv_nRUcXAZ-3rOTnon-_OFHS1pr6B4vGeBqpmts_I2iEHfJCc.vnNb4VFecDwUf-jtziQRfmcJXNeYG4mnwn4UhUa2aDY&dib_tag=se&keywords=Dell+Xps+13&qid=1715446752&s=electronics&sprefix=dell+xps+13%2Celectronics%2C423&sr=1-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>





        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          2023 Latest H P OMEN 16 Gaming Laptop I9-13900HX RTX4070 16.1inch 240Hz Display Ultra high performance gaming laptop
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,205.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/2023-Latest-H-P-OMEN-16_1600889945621.html?spm=a2700.galleryofferlist.normal_offer.d_title.5d2b6449mwlpDa'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Dell XPS 13 9315 13.4" FHD+ (Intel 10-Core i7-1250U, 16GB LPDDR5 RAM, 512GB SSD) Thin & Light Business Laptop, Long Battery Life, Thunderbolt 4, IR Webcam, Backlit, Fingerprint, Wi-Fi 6E, Win 11 Home
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,099.99 
          </p>
          <Link to={'https://www.amazon.com/Dell-XPS-9315-Thunderbolt-Fingerprint/dp/B0C9SWDB7P/ref=sr_1_14?crid=1GVNBYJ5TEGJF&dib=eyJ2IjoiMSJ9.fmxodPUsFnhmBYGUq663a49tb9u4to0t6NutzjCLH1-RNTBbi_GtZfo__-vlAGfWzgLvB2MPyhbKj6Z8rIteK5NW2T_2wIsWwa1sKZPN-lX7Yl5zKfK7BTZoA1mlHgKXN0eAz6EFQgf1EfQE09cuU6cLySWYNr4WUJNRuhSF8jrfJTGDfY0byLQbVN82ybM5i_8I1f8k1Qy-WpDVqLHgSbRc2iF4dl00bhtRHNl1B87U6BNM69qhzSvuCsSv_nRUcXAZ-3rOTnon-_OFHS1pr6B4vGeBqpmts_I2iEHfJCc.vnNb4VFecDwUf-jtziQRfmcJXNeYG4mnwn4UhUa2aDY&dib_tag=se&keywords=Dell+Xps+13&qid=1715446752&s=electronics&sprefix=dell+xps+13%2Celectronics%2C423&sr=1-14'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Macbook Air Apple M2 "Thin and Light Laptop 13.6 Inch Liquid Retina Display, LED-backlit display with IPS technology, 500 nits brightness, Wide colour (P3), True Tone technology"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,240.24  
          </p>
          <Link to={'https://www.flipkart.com/apple-2022-macbook-air-m2-8-gb-256-gb-ssd-mac-os-monterey-mlxw3hn-a/p/itmc2732c112aeb1?pid=COMGFB2GSG8EQXCQ&lid=LSTCOMGFB2GSG8EQXCQJWHH2F&marketplace=FLIPKART&q=apple+macbook+air+m2&store=6bo%2Fb5g&srno=s_1_1&otracker=AS_QueryStore_OrganicAutoSuggest_1_9_na_na_na&otracker1=AS_QueryStore_OrganicAutoSuggest_1_9_na_na_na&fm=organic&iid=019feb01-7889-4dc8-9f21-3cfa7a2170bc.COMGFB2GSG8EQXCQ.SEARCH&ppt=pp&ppn=pp&ssid=9iw1gmgmqo0000001715428891686&qH=a8972ccb85f6fdc9'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>




        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Ali Baba
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Lenovoes Legion 5 2023 i9 13900HX/16GB/1TB/RTX4050 2K 16inch laptop for home and gaming
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,258.00
          </p>
          <Link to={'https://www.alibaba.com/product-detail/Lenovoes-Legion-5-2023-i9-13900HX_1601052563700.html?spm=a2700.galleryofferlist.normal_offer.d_title.7e4450f6xcYDTv'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Amazon
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple 2023 MacBook Air Laptop with M2 chip: 15.3-inch Liquid Retina Display, 8GB Unified Memory, 256GB SSD Storage, 1080p FaceTime HD Camera, Touch ID. Works with iPhone/iPad; Starlight
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,139.00  
          </p>
          <Link to={'https://www.amazon.com/Apple-2023-MacBook-Laptop-chip/dp/B0C7686169/ref=sr_1_2_sspa?crid=2UW43YSL9ZIC3&dib=eyJ2IjoiMSJ9.8uvkRCrwJnQ6x8dDmxgxJ-qep_gY3EC-Y3RLYr8pe2gpHA-VIqv7o1dHUImruAPGo0H4pORm2ugFXV3dVhfUSk0mVHas9AW84BF2XlHrgnZx382W9F7n15CLb_grGqjV_FLBrB59PoZKdYT9QM8swoj3m-VZd780ipA2kwuZVoAqDdzcZNIrWEZERNkJs-lMrFoew6U6VKn51lNvbQUePTem4yYopzfu7sJSuiOhGUcHXxZDqgrP8_1Gxid1fkQXDJ9i5ZOjtoVJWzCI8rMmmQ7EWAGMAbAb_czkiLSQhww.xMugSeKA474_crEwfKyZcYJv7VMIBgdFlmfXcuwh-Rc&dib_tag=se&keywords=apple+macbook+air+m2&qid=1715446000&s=electronics&sprefix=apple+macbook+a%2Celectronics%2C727&sr=1-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        <div className="c_box grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-90 bg-white mx-2">
          <h3 className="text-gray-600 text-xs tracking-widest title-font mb-2 font-bold pt-2 P_name">
            Flipkart
          </h3>
          <h2 className="text-gray-900 title-font text-base font-medium text-left text-wrap p_detail">
          Apple Macbook Air Apple M3 " Stylish & Portable Thin and Light Laptop 13 Inch Liquid Retina display, LED-backlit Display with IPS Technology, Native Resolution at 224 pixels per inch, 500 nits Brightness Light Laptop without Optical Disk Drive"
          </h2>
          <p className=" text-gray-500 mt-1 p_price">
          $1,423.93 
          </p>
          <Link to={'https://www.flipkart.com/apple-macbook-air-m3-8-gb-256-gb-ssd-macos-sonoma-mrxn3hn-a/p/itm45bd67b7758ad?pid=COMGYP5GK8FRYSPA&lid=LSTCOMGYP5GK8FRYSPAJKPLUO&marketplace=FLIPKART&q=apple+macbook+air+m1&store=6bo%2Fb5g&srno=s_1_16&otracker=AS_QueryStore_OrganicAutoSuggest_2_13_na_na_ps&otracker1=AS_QueryStore_OrganicAutoSuggest_2_13_na_na_ps&fm=Search&iid=75cf3e2a-1501-4cfc-8d53-787e61c56723.COMGYP5GK8FRYSPA.SEARCH&ppt=sp&ppn=sp&ssid=q2m9akx8lc0000001715429403138&qH=0020898769ebfd7b'} target="_blank" className="p_link">
            View Here
          </Link>
        </div>

        </div>

        <Footer/>
    </div>
  );
  //  <SubCategoryComponent productHeader={"Laptops"} categoryId={2} />;
};

export default Laptops;
